import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NurseryComponent } from './nursery/nursery.component';
import { ImmatureFieldsComponent } from './immature-fields/immature-fields.component';

const routes: Routes = [
  { path: 'nursery', component: NurseryComponent },
  { path: 'immature-fields', component: ImmatureFieldsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImmatureStockRoutingModule { }
