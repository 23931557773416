import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    constructor(private http: HttpClient) { }


    /**
     * Get All Project list
     */
    getProjectList(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/projects/" + apiType, data, {params: params});
    }

    addToFavouriteProjects(project)
    {
        return this.http.post(environment.apiUrl + "/projects/addToFavourite", project);
    }

    removeFromFavouriteProjects(project)
    {
        return this.http.post(environment.apiUrl + "/projects/removeFromFavourite", project);
    }

    /**
     * Create New Project
     * @param data
     */
    createProject(data)
    {

        return this.http.post(environment.apiUrl + "/projects/", data);
    }

    /**
     * Update Project
     * @param data
     */
    updateProject(data, id)
    {

        return this.http.put(environment.apiUrl + "/projects/" + id + '/', data);
    }


    /**
     * Search Project by Project number and Designation
     * @param data
     */
    searchProject(data)
    {
        return this.http.post<ProjectData>(environment.apiUrl + "/projects/search/", data);
    }

    getProjectById(projectNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber);
    }

    addProjectSystems(reason, data, projectNumber)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/systems/", data, httpOptions);
    }

    /**
     * Add single project system
     * @param data
     * @param projectNumber
     */
    addSingleProjectSystem(data, projectNumber)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/system/", data);
    }

    getProjectSystems(projectNo, apiType, data, params)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNo + "/systems/" + apiType, data, {params: params});
    }

    getProjectPrices(projectNo, apiType, data, params)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNo + "/prices/" + apiType, data, {params: params});
    }

    getProjectAdmins(projectNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + '/admins');
    }

    getEmptyProjects()
    {
        return this.http.get(environment.apiUrl + "/projects/empty/");
    }

    copyProject(projectNumber, copyToProjectNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/copyto/" + copyToProjectNumber);
    }


}

export interface ProjectData {
    project_number: string,
    designation: string,
    parent_project: string,
    power_unit: string,
    cars_count: string,
    vehicle_quantity: string,
    project_status: number,
    created_DATETIME: string,
    updated_DATETIME: string,
    created_BY: string,
    updated_BY: string,
}
