import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReferenceDataRoutingModule } from './reference-data-routing.module';
import { TreeSpicesComponent } from './tree-spices/tree-spices.component';
import { TreeTableModule } from 'src/app/shared/components/tree-table/tree-table.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TreeSpeciesAddComponent } from './tree-spices/components/tree-species-add/tree-species-add.component';
import { TreeSpeciesEditComponent } from './tree-spices/components/tree-species-edit/tree-species-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
  declarations: [
    TreeSpicesComponent,
    TreeSpeciesAddComponent,
    TreeSpeciesEditComponent
  ],
  imports: [
    CommonModule,
    ReferenceDataRoutingModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSelectModule,
    NgxPaginationModule,
    ModalModule,
    TreeTableModule
  ],
  bootstrap:[TreeSpeciesAddComponent,TreeSpeciesEditComponent],
})
export class ReferenceDataModule { }
