import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImmatureStockRoutingModule } from './immature-stock-routing.module';
import { NurseryComponent } from './nursery/nursery.component';
import { ImmatureFieldsComponent } from './immature-fields/immature-fields.component';


@NgModule({
  declarations: [
    NurseryComponent,
    ImmatureFieldsComponent
  ],
  imports: [
    CommonModule,
    ImmatureStockRoutingModule
  ]
})
export class ImmatureStockModule { }
