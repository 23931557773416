import {Component, EventEmitter, OnInit, Type} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UntypedFormGroup, UntypedFormControl, Validators, FormArray} from '@angular/forms';
import { ProjectSystemSparePartsService } from 'src/app/services/project-system-spare-parts.service';
import { ToastrService } from 'ngx-toastr';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { currencyList, validateForm } from 'src/app/shared/helper';
import { StadlerArticleService } from 'src/app/services/stadler-article.service';
import { QuantityUnitServiceService } from 'src/app/services/quantity-unit-service.service';
import { ProjectSystemsService } from 'src/app/services/project-systems.service';
import {LanguageSupportComponent} from "../../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../../services/language-service.service";

@Component({
  selector: 'app-add-project-system-spare-parts',
  templateUrl: './add-project-system-spare-parts.component.html',
  styleUrls: ['./add-project-system-spare-parts.component.scss']
})
export class AddProjectSystemSparePartsComponent extends LanguageSupportComponent implements OnInit {

  projectNumber:any;
  projectSystemId:any;
  parent:any;
  param:any;
  event: EventEmitter<any> = new EventEmitter();
  laddaLoading = false;
  materialQuantityDecimalLength = 2;
  existingChildren:any = [];
  newChildren:any[] = [];
  quantityControlList = new FormArray([]);
  replaceableControlList = new FormArray([]);
  stadlerArticleIdControlList = new FormArray([]);
  projectFreezeFunction:string = "";
  btnLoading:Boolean = false;

  spareUnitList:any;
  stadlerArticleData:any[]=[];
  projectSystemSparePartAddForm = new UntypedFormGroup({
    spareStadlerArticleId : new UntypedFormControl(''),
    quantity : new UntypedFormControl('', [Validators.max(999999.99)]),
    spareUnit : new UntypedFormControl({value:'', disabled: true}),
    isReplaceable : new UntypedFormControl({value:0, disabled: false}),
  });

  constructor(
      public bsModalRef: BsModalRef,
      private projectSystemSpareService : ProjectSystemSparePartsService,
      private notificationService : ToastrService,
      private supplierService : SuppliersService,
      private stadlerArticleService : StadlerArticleService,
      private quantityUnitService : QuantityUnitServiceService,
      private projectSystemService : ProjectSystemsService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService
  ) {
    super(languageService, modalService);
  }


  ngOnInit(): void {
    this.projectNumber = this.param.projectNumber;
    this.projectSystemId = this.param.systemNumber;
    this.parent = this.param.rowData;

    this.loadSpareUnits();
    this.getStadlerArticleIdData();
    this.loadExistingChildren();
    super.saveInitialValues(JSON.stringify(this.projectSystemSparePartAddForm.getRawValue()));
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.projectSystemSparePartAddForm.getRawValue()));
  }

  /**
   * Load Existing Children
   */
  loadExistingChildren(){
    this.projectSystemSpareService.getProjectSystemSparePartsByParent(this.projectNumber,this.projectSystemId, this.parent.spareNumber).subscribe((res:any[])=>{
      this.existingChildren = res.length>0 ?  res : [];
    });
  }

  /**
   * Load spare unit list
   */
  loadSpareUnits(){
    this.quantityUnitService.getAllQuantityUnits().subscribe(res=>{
      this.spareUnitList = res;
    });
  }

  /**
   * Get Stadler article id data
   */
  getStadlerArticleIdData(){
    if(!this.parent.spareNumber) return;

    this.stadlerArticleData = [];
    this.stadlerArticleService.getStadlerArticleIdByPart(this.parent.spareNumber).subscribe(res=>{
      Object.keys(res).forEach(key => {
        if(this.selectedLanguage==1){
          res[key].stadlerArticleText = res[key].designationDe + " - " + res[key].stadlerArticleId;
        }else{
          res[key].stadlerArticleText = res[key].designationEn + " - " + res[key].stadlerArticleId;
        }
        res[key].stadlerArticleText += (res[key].supplier_article_id == null || res[key].supplier_article_id.trim() == "" || res[key].supplier_article_id.trim() == "-") ? "" : (" - " + res[key].supplier_article_id);
        if(res[key].sparePartRelation == null || res[key].sparePartRelation == 'CHILD' || res[key].sparePartRelation == 'GRAND_CHILD') this.stadlerArticleData.push(res[key]);
      });
    })
  }

  /**
   * Validate quantity
   */
  validateQuantity(){
    let unit = this.projectSystemSparePartAddForm.getRawValue()['spareUnit'];
    this.materialQuantityDecimalLength = unit == 1 ? 0 : 1;
    if(unit == 1)
    {
      let value = this.projectSystemSparePartAddForm.controls["quantity"].value;
      let newQuantityValue = Math.round(value) <=  0 ? null : Math.round(value);
      this.projectSystemSparePartAddForm.patchValue({quantity : newQuantityValue});
    }
  }

  /**
   * Get Stadler article data by id
   */
  stadlerArticleIdChanged(){
    let articleId = this.projectSystemSparePartAddForm.value.spareStadlerArticleId;
    if(articleId==null || articleId=="") return;

    this.stadlerArticleService.getArticleById(articleId).subscribe(res=>{
      if(res!=null) {
        this.projectSystemSparePartAddForm.patchValue({
          spareUnit : res['quantityUnit'] ? res['quantityUnit'] : ""
        });
      }
      this.validateQuantity();
    });
  }

  /**
   * Add Spare Part to Existing List
   */
  addToNewChildList(){
    let part = this.projectSystemSparePartAddForm.getRawValue();
    part['parentId'] = this.parent['spareNumber'];
    part['projectNumber'] = this.projectNumber;
    part['projectSystemId'] = this.projectSystemId;

    this.stadlerArticleIdControlList.push(new UntypedFormControl(part['spareStadlerArticleId'], Validators.required));
    this.quantityControlList.push(new UntypedFormControl(part['quantity'], [Validators.required, Validators.max(999999.99)]));
    this.replaceableControlList.push(new UntypedFormControl(part['isReplaceable'], []));

    this.newChildren.push(part);
    this.projectSystemSparePartAddForm.reset();
  }

  /**
   * Submit Porject spare part create form
   */
  createProjectSystemSpareParts(event){
    this.btnLoading = true;
    this.laddaLoading = true;

    //for each this.newChildren convert isReplaceable to integer from boolean
    this.newChildren.forEach((child) => {
      child.isReplaceable = child.isReplaceable ? 1 : 0;
    });

    this.projectSystemSpareService.createProjectSystemSparePartByParent(event,this.newChildren).subscribe(res=>{
      if(res){
        this.notificationService.success('Project System Part Part has been Created', 'Success');
        this.bsModalRef.hide();
        this.event.emit();
      }else{
        this.notificationService.error('Project System Part has not been Created', 'Error');
      }
      this.laddaLoading = false;
      this.btnLoading = false;
    },(error => {
      if(error.status == 403) {
        this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
      }else{
        this.notificationService.error('Project System Part has not been Created', 'Error');
      }
      
      this.laddaLoading = false;
      this.btnLoading = false;
    }));
  }

  getSpareUnitStr(unitId){
    if(!unitId || !this.spareUnitList || this.spareUnitList.length <=0) return;

    for(let unit of this.spareUnitList){
      if(unit.quantityUnitId == unitId) return unit.designationDe;
    }
  }

  callAfterLanguageChanged() {
    this.loadSpareUnits();
    this.getStadlerArticleIdData();
    this.loadExistingChildren();
    
  }
}
