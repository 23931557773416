import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TreeSpicesComponent } from './tree-spices/tree-spices.component';

const routes: Routes = [
  { path: 'tree-spices', component: TreeSpicesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReferenceDataRoutingModule { }
