import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatureStockRoutingModule } from './mature-stock-routing.module';
import { TreeDetailsComponent } from './tree-details/tree-details.component';


@NgModule({
  declarations: [
    TreeDetailsComponent
  ],
  imports: [
    CommonModule,
    MatureStockRoutingModule
  ]
})
export class MatureStockModule { }
