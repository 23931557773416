import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TreeSpeciesService {

  constructor(private http: HttpClient) { }

    getAllTreeSpecies(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/tree-species/" + apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/tree-species/", data);
    }

    update(speciesCode,data)
    {
        return this.http.put(environment.apiUrl + "/tree-species/" + speciesCode, data);
    }

    delete(speciesCode)
    {
        if (!speciesCode) return;
        return this.http.delete(environment.apiUrl + "/tree-species/" + speciesCode);
    }

}
