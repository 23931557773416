import {ChangeDetectorRef, Injectable, OnInit} from '@angular/core';
import {AuthServiceService} from '../../../services/auth-service.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ViewUrlComponent} from './components/view-url/view-url.component';
import {LogServiceService} from '../../../services/log-service.service';
import {ActivatedRoute} from "@angular/router";
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export abstract class CommonLogComponent implements OnInit {

  public loginUser: any;
  public loading = true;
  public tableData: any;
  public moreDetails: any;
  public showResultTable:boolean = false;
  public currentAction:any;
  public dataTableHeight = 0;
  public users;
  public entities;
  public actions;

  public selectedFromDate;
  public selectedToDate;
  public searchString;
  public selectedUsers;
  public selectedEntities;
  public selectedActions;

  public logId;
  public logType;

  public filterItems = {
    dateFrom: null,
    dateTo: null,
    user: [],
    entity: [],
    action: [],
    textSearch: '',
    projectNumber:null,
    systemVariationNumberAndCode:null,
    taskId:null,
    pageNo: null,
    recCount: null
  };

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";

  loadingExportBtn: Boolean = false;
  loadingSearchBtn: Boolean = false;
  disableButton: Boolean = false;

  resultTableAddDeleteColumnsStrObj:any;
  resultTableEditColumnsStrObj:any;

  // Table Settings

  mainTableSettings = {
    type:'table',
    id:'id',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    columns:[
      {
        title:'Log ID',
        attribute:'id',
        type:'text',
        width:100,
      },
      {
        title:'Export Version',
        attribute:'exportVersion',
        type:'text',
        width:120,
      },
      {
        title:'Doku Version',
        attribute:'dokuVersion',
        type:'text',
        width:120,
      },
      {
        title:'Date',
        attribute:'date',
        type:'text',
        width:120,
      },
      {
        title:'Time',
        attribute:'time',
        type:'text',
        width:130
      },
      {
        title:'Entity',
        attribute:'entity',
        type:'text',
        width:180,
      },
      {
        title:'User',
        attribute:'username',
        type:'text',
        width:180,
      },
      {
        title:'Action',
        attribute:'action',
        type:'text',
        width:100,
      },
      {
        title:'TaskID',
        attribute:'taskId',
        type:'text',
        width:130,
      },
      {
        title:'Stadler Article ID',
        attribute:'stadlerArticleId',
        type:'text',
        width:130,
      },
      {
        title:'Justf Category',
        attribute:'justificationCategory',
        type:'text',
        width:150,
      },
      {
        title:'Comment',
        attribute:'comment',
        type:'text',
        width:null,
      },
      {
        renderComponent: ViewUrlComponent,
        type:'custom',
        onComponentInitFunction: (instance) => {
          instance.eventEmitter.subscribe((rowData) => {
            this.showMoreDetailsTable(rowData);
          });
        },
        width:50
      }
    ]
  };

  private resultTableAddDeleteColumns = [
    {
      title:'Field',
      attribute:'field',
      type:'text',
      width:200,
    },
    {
      title:'Value',
      attribute:'value',
      type:'text',
      width:200,
    }
  ];

  private resultTableEditColumns = [
    {
      title:'Field',
      attribute:'field',
      type:'text',
      width:200,
    },
    {
      title:'Old Value',
      attribute:'oldValue',
      type:'text',
      width:200,
    },
    {
      title:'New Value',
      attribute:'newValue',
      type:'text',
      width:200,
    }
  ];


  public resultTableSettings = {
    type:'table',
    columnManagerEnable:false,
    pagination:{
      enabled:false,
      pageSize:this.pageSize
    },
    enableZoom:false,
  };


  public addDeleteResultTableSettings = JSON.parse(JSON.stringify(this.resultTableSettings));
  public editResultTableSettings = JSON.parse(JSON.stringify(this.resultTableSettings));

  protected constructor(
      public authService: AuthServiceService,
      public bsModalRef: BsModalRef,
      public activatedRoute : ActivatedRoute,
      public logService: LogServiceService,
      public cdr: ChangeDetectorRef
  ) {

    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });

    this.addDeleteResultTableSettings['columns'] = this.resultTableAddDeleteColumns;
    this.editResultTableSettings['columns'] = this.resultTableEditColumns;

    this.resultTableAddDeleteColumnsStrObj = JSON.stringify(this.resultTableAddDeleteColumns);
    this.resultTableEditColumnsStrObj = JSON.stringify(this.resultTableEditColumns);


    this.editResultTableSettings['rowClassFunction'] = (row) => { return row.hasValueDiff ? 'value-diff-row' : 'value-no-diff-row'; };
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getUsers();
    this.getActions();
    this.getEntities();
  }

  protected setProjectNumber(projectNumber)
  {
    this.filterItems.projectNumber = projectNumber;
  }

  protected setEntities(entities: any[])
  {
    this.selectedEntities = entities;
  }
  

  protected setSystemVariationNumberAndCode(systemVariationNumberAndCode)
  {
    this.filterItems.systemVariationNumberAndCode = systemVariationNumberAndCode;
  }

  protected setTaskId(taskId)
  {
    this.filterItems.taskId = taskId;
  }

  protected setLogId(logId)
  {
    this.logId = logId;
  }

  protected setLogType(logType)
  {
    this.logType = logType;
  }

  private getCurrentUser()
  {
    let loginUser = JSON.parse(localStorage.getItem('user'));

    let userId = loginUser['userId'];
    this.authService.getLoginUserData(userId).subscribe(res => {
      this.loginUser = res;
      this.filterItems.user.push(userId);
      this.getLogsByPage();
    });
  }

  private getUsers()
  {
    this.logService.getUsers().subscribe(res => {
      this.users = res;
    });
  }

  private getActions()
  {
    this.logService.getActions().subscribe(res => {
      this.actions = res;
    });
  }

  private getEntities()
  {
    this.logService.getProjectEntities().subscribe(res => {
      this.entities = res;
    });
  }

  public onClickSearch()
  {
    this.filterItems.textSearch = this.searchString;
    this.getLogsByPage();
  }

  public doSearch()
  {
    this.onClickSearchNow();
  }

  onClickSearchNow()
  {
    this.getLogsByPage();
  }

  public onSelectFromDate() {

    this.filterItems.dateFrom = this.selectedFromDate['year'] + "-";
    this.filterItems.dateFrom += (this.selectedFromDate['month']).toString().padStart(2, 0) + "-";
    this.filterItems.dateFrom += (this.selectedFromDate['day']).toString().padStart(2, 0);
    // this.getLogsByPage();
  }

  public onSelectToDate()
  {
    this.filterItems.dateTo = this.selectedToDate['year'] + "-";
    this.filterItems.dateTo += (this.selectedToDate['month']).toString().padStart(2, 0) + "-";
    this.filterItems.dateTo += (this.selectedToDate['day']).toString().padStart(2, 0);
    // this.getLogsByPage();
  }

  public onSelectUser()
  {
    this.filterItems.user = this.selectedUsers;
    // this.getLogsByPage();
  }

  public onSelectEntity()
  {
    this.filterItems.entity = this.selectedEntities;
    // this.getLogsByPage();
  }

  public onSelectAction()
  {
    this.filterItems.action = this.selectedActions;
    // this.getLogsByPage();
  }

  public exportData()
  {
    
    //Generic Text Search
    this.filterItems.textSearch = this.searchString;

    //From Date
    if(this.selectedFromDate){
      this.filterItems.dateFrom = this.selectedFromDate['year'] + "-";
      this.filterItems.dateFrom += (this.selectedFromDate['month']).toString().padStart(2, 0) + "-";
      this.filterItems.dateFrom += (this.selectedFromDate['day']).toString().padStart(2, 0);
    }else{
      this.filterItems.dateFrom = null;
    }

    //To Date
    if(this.selectedToDate){
      this.filterItems.dateTo = this.selectedToDate['year'] + "-";
      this.filterItems.dateTo += (this.selectedToDate['month']).toString().padStart(2, 0) + "-";
      this.filterItems.dateTo += (this.selectedToDate['day']).toString().padStart(2, 0);
    }else{
      this.filterItems.dateTo = null;
    }

    //Users
    this.filterItems.user = this.selectedUsers;

    //Entities
    this.filterItems.entity = this.selectedEntities;

    //Actions
    this.filterItems.action = this.selectedActions;

    // this.loadingExportBtn = true;
    // this.disableButton = true;

    this.logService.exportData(this.filterItems).subscribe((res)=>{
      this.loadingExportBtn = false;
      this.disableButton = false;
      this.cdr.detectChanges();

      const blob = new Blob([res], { type: 'application/octet-stream' });
      let strFileName = "logs" + ".xlsx";
      const file = new File([blob], strFileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      
      FileSaver.saveAs(file);
      
    }, ()=>{
      // this.loadingExportBtn = false;
      // this.disableButton = false;
      // this.cdr.detectChanges();
    }, ()=>{
      // this.loadingExportBtn = false;
      // this.disableButton = false;
      // this.cdr.detectChanges();
    });
  }

  private getLogsByPage() {
    this.loading = true;
    

    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    //Generic Text Search
    this.filterItems.textSearch = this.searchString;

    //From Date
    if(this.selectedFromDate){
      this.filterItems.dateFrom = this.selectedFromDate['year'] + "-";
      this.filterItems.dateFrom += (this.selectedFromDate['month']).toString().padStart(2, 0) + "-";
      this.filterItems.dateFrom += (this.selectedFromDate['day']).toString().padStart(2, 0);
    }else{
      this.filterItems.dateFrom = null;
    }


    //To Date
    if(this.selectedToDate){
      this.filterItems.dateTo = this.selectedToDate['year'] + "-";
      this.filterItems.dateTo += (this.selectedToDate['month']).toString().padStart(2, 0) + "-";
      this.filterItems.dateTo += (this.selectedToDate['day']).toString().padStart(2, 0);
    }else{
      this.filterItems.dateTo = null;
    }


    //Users
    this.filterItems.user = this.selectedUsers;

    //Entities
    this.filterItems.entity = this.selectedEntities;

    //Actions
    this.filterItems.action = this.selectedActions;

    // this.loadingSearchBtn = true;
    // this.disableButton = true;

    this.logService.getLogs(this.filterItems, params).subscribe(res => {
      this.loadingSearchBtn = false;
      this.disableButton = false;
      this.cdr.detectChanges();

      this.pageCount = res['totalPages'];
      let result = res['content'];
      Object.entries(result).forEach(([key, value]) => {
        let eurDate:string = (new Date(value['date'])).toLocaleString("en-US", {timeZone: "Europe/Berlin"});
        let dateTimeArray = eurDate && eurDate != "" && eurDate.includes(", ") ? eurDate.split(", ") : [];
        if(dateTimeArray.length > 1)
        {
          let date = dateTimeArray[0];
          let time = dateTimeArray[1];
          value['date'] = date;
          value['time'] = time;
        }
        let justificationCategory = value['justificationCategory'] ? value['justificationCategory']['categoryText'] : '';
        value['justificationCategory'] = justificationCategory;
      });
      this.tableData = result;
      
    },()=>{
      // this.loadingSearchBtn = false;
      // this.disableButton = false;
    },()=>{
      this.loading = false;
      // this.loadingSearchBtn = false;
      // this.disableButton = false;
      // this.cdr.detectChanges();
    });

  }

  public showMoreDetailsTable(rowData)
  {
    this.resultTableAddDeleteColumns = JSON.parse(this.resultTableAddDeleteColumnsStrObj);
    this.resultTableEditColumns = JSON.parse(this.resultTableEditColumnsStrObj);

    let obj = JSON.parse(rowData['remarks']);
    let action = rowData['action'];
    let freezeFunctionCommentsList = rowData['freezeFunctionCommentsList'];

    if(freezeFunctionCommentsList.length > 0)
    {
      let columns = action == 'Edit' ? this.resultTableEditColumns : this.resultTableAddDeleteColumns;
      columns.push({
        title:'Comment',
        attribute:'comment',
        type:'text',
        width:200,
      },
      {
        title:'Justification Category',
        attribute:'justificationCategory',
        type:'text',
        width:250,
      })
    }

    this.addDeleteResultTableSettings['columns'] = this.resultTableAddDeleteColumns;
    this.editResultTableSettings['columns'] = this.resultTableEditColumns;

    this.moreDetails = [];
    this.currentAction = action;
    if (action == 'Add' || action == 'Copy' || action == 'Delete') {
      let object = (action == 'Add' || action == 'Copy') ?  obj.NEW : obj.OLD;
      for (let key in object) {
        this.moreDetails.push({
          field: key,
          value: this.replaceDelimiter(object, key),
          comment: this.getFreezeFunctionComment(key, freezeFunctionCommentsList),
          justificationCategory: this.getFreezeFunctionJustificationCategory(key, freezeFunctionCommentsList)
        });
      }
    }
    else if (action == 'Edit') {
      let newObject = obj.NEW;
      let oldObject = obj.OLD;
      for (let key in newObject) {
        this.moreDetails.push({
          field: key,
          oldValue: this.replaceDelimiter(oldObject, key),
          newValue : this.replaceDelimiter(newObject, key),
          comment: this.getFreezeFunctionComment(key, freezeFunctionCommentsList),
          justificationCategory: this.getFreezeFunctionJustificationCategory(key, freezeFunctionCommentsList),
          hasValueDiff : (Array.isArray(newObject[key])) ? this.checkArrayComparison(newObject[key], oldObject[key]) : (newObject[key] != oldObject[key])
        });
      }
    }
    this.showResultTable = true;
    this.dataTableHeight = document.getElementById('data-table').getBoundingClientRect().height - 77 ;
  }

  private getFreezeFunctionComment(attribute, freezeFunctionCommentsList)
  {
    for(let item of freezeFunctionCommentsList)
    {
      if(item['attribute'] == attribute) return item['comment']
    }
  }

  private getFreezeFunctionJustificationCategory(attribute, freezeFunctionCommentsList)
  {
    for(let item of freezeFunctionCommentsList)
    {
      if(item['attribute'] == attribute && item['justificationCategory']) return item['justificationCategory']['categoryText'];
    }
  }

  private checkArrayComparison(arr1, arr2){
    try{
      if(arr1.length != arr2.length) return true;
      for(let i = 0; i < arr1.length; i++){
        if(arr1[i] != arr2[i]) return true;
      }
      return false;
    }catch(e){
      return true;
    }
  }

  private replaceDelimiter(obj:{}, key:string){
    if(!obj || !key || obj[key] == null) return "";

    if(key.toUpperCase() == 'UPDATED_DATETIME' || key.toUpperCase() == 'CREATED_DATETIME' || key.toUpperCase() == 'DELETED_DATETIME'){
      let date = obj[key].date;
      let time = obj[key].time;

      if(date == null || date.year == null || date.month == null || date.day == null) return "";
      if(time == null || time.hour == null || time.minute == null || time.second == null) return "";

      let dateTime = date.year + "-" + date.month + "-" + date.day;
      dateTime += " ";
      dateTime += time.hour + ":" + time.minute + ":" + time.second;
      return dateTime;
    }
    else{
      let str = obj[key];
      let delim = / # /gi;
      let newStr = str.toString().replace(delim, "\n");
      return newStr;
    }
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.onClickSearchNow();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.onClickSearchNow();
  }
}


