<div class="modal-header">
  <h5 class="modal-title pull-left">Add Parts</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class="pr-4 pl-4" [formGroup]="projectSystemSparePartAddForm">
  <div class="modal-body">
    <h5>{{parent.spareDesignation}} - {{parent.spareStadlerArticleId}}</h5>
    <br>
    <table class="table">
      <thead>
      <tr>
        <th style="width:40%">Part Stadler Article ID & Designation</th>
        <th style="width:18%">Quantity</th>
        <th style="width:18%">Parts Unit</th>
        <th style="width:50px">Replaceable?</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let child of existingChildren">
        <td>{{child.spareStadlerArticleId}} - {{child.spareDesignation}}</td>
        <td>{{child.quantity}}</td>
        <td>{{getSpareUnitStr(child.spareUnit)}}</td>
        <td class="center-cell">
          <input style="height: 15px" type="checkbox" [disabled]="true" [checked]="child.isReplaceable" class="form-control form-control-xs" placeholder="Replaceable?" />
        </td>
      </tr>
      <tr *ngFor="let child of newChildren; let i = index">
        <td>
          <ngx-select [formControl] = "stadlerArticleIdControlList.controls[i]"  optionValueField="stadlerArticleId" (select)="stadlerArticleIdChanged()" optionTextField="stadlerArticleText" [items]="stadlerArticleData" appMandatoryField></ngx-select>
        </td>
        <td>
          <input [formControl] = "quantityControlList.controls[i]" [appThousandSeparator]="quantityControlList.controls[i].value" type="text" class="form-control form-control-sm" placeholder="Quantity" [appDecimal]="materialQuantityDecimalLength" appMandatoryField>
          <div *ngIf="quantityControlList.controls[i].value != '' && quantityControlList.controls[i].errors?.valueZero" class="ng-validator">
            Should be Greater Than 0
          </div>
          <div *ngIf="quantityControlList.controls[i].errors?.required" class="ng-validator">
            This field is required
          </div>
          <div *ngIf="quantityControlList.controls[i].errors?.max">
            Should be Less Than 999999.99
          </div>
        </td>
        <td>
          <ngx-select [(ngModel)]="child.spareUnit" [ngModelOptions]="{standalone: true}" optionValueField="quantityUnitId" optionTextField="designationDe" [items]="spareUnitList" [disabled]="true"></ngx-select>
        </td>
        <td class="center-cell">
          <input [formControl] = "replaceableControlList.controls[i]" style="height: 15px" type="checkbox" class="form-control form-control-xs" placeholder="Replaceable?" />
        </td>
      </tr>
      <tr>
        <td>
          <div>
            <ngx-select [required]="projectSystemSparePartAddForm.controls['quantity'].value > 0 && !projectSystemSparePartAddForm.controls['spareStadlerArticleId'].value"  optionValueField="stadlerArticleId" (select)="stadlerArticleIdChanged()" optionTextField="stadlerArticleText" [items]="stadlerArticleData" [placeholder]="'Select Stadler Article Id'" formControlName="spareStadlerArticleId" appMandatoryField appInfoPromptBox [page]="'projectSystemParts'" [component]="'add'" [field]="'spareStadlerArticleId'"></ngx-select>
            <div *ngIf="projectSystemSparePartAddForm.controls['spareStadlerArticleId'].invalid && (projectSystemSparePartAddForm.controls['spareStadlerArticleId'].dirty || projectSystemSparePartAddForm.controls['spareStadlerArticleId'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartAddForm.controls['spareStadlerArticleId'].errors.required">
                This field is required.
              </div>
            </div>
          </div>
        </td>
        <td>
          <div>
            <input [required]="projectSystemSparePartAddForm.controls['spareStadlerArticleId'].value && !projectSystemSparePartAddForm.controls['quantity'].value"  type="text" class="form-control form-control-sm" formControlName="quantity" [appThousandSeparator]="projectSystemSparePartAddForm.controls['quantity'].value" placeholder="Quantity" [appDecimal]="materialQuantityDecimalLength" appMandatoryField appInfoPromptBox [page]="'projectSystemParts'" [component]="'add'" [field]="'quantity'">
            <div *ngIf="projectSystemSparePartAddForm.controls['quantity'].invalid && (projectSystemSparePartAddForm.controls['quantity'].dirty || projectSystemSparePartAddForm.controls['quantity'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartAddForm.controls['quantity'].value != '' && projectSystemSparePartAddForm.controls['quantity'].errors.valueZero">
                Should be Greater Than 0
              </div>
              <div *ngIf="projectSystemSparePartAddForm.controls['quantity'].errors.required">
                This field is required.
              </div>
              <div *ngIf="projectSystemSparePartAddForm.controls['quantity'].errors.max">
                Should be Less Than 999999.99
              </div>
            </div>
          </div>
        </td>
        <td>
          <div>
            <ngx-select optionValueField="quantityUnitId" optionTextField="designationDe" [items]="spareUnitList" [placeholder]="'Select Parts Unit'" formControlName="spareUnit" appInfoPromptBox [page]="'projectSystemParts'" [component]="'add'" [field]="'spareUnit'"></ngx-select>
          </div>
        </td>
        <td class="center-cell">
          <input style="height: 15px" type="checkbox" class="form-control form-control-sm" placeholder="Replaceable?" formControlName="isReplaceable" appInfoPromptBox [page]="'projectSystemParts'" [component]="'add'" [field]="'isreplaceable'"/>
        </td>
        <button [disabled]="!projectSystemSparePartAddForm.controls['spareStadlerArticleId'].value || projectSystemSparePartAddForm.invalid" type="button" class="addbtn btn btn-primary" (click)="addToNewChildList()">Add</button>
      </tr>
      </tbody>
    </table>

  </div>
  <div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [saveDisable]="newChildren.length==0 || projectSystemSparePartAddForm.invalid || quantityControlList.invalid" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="createProjectSystemSpareParts($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>
</form>
