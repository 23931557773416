import {Component} from '@angular/core';
import {slideInAnimation} from './animations';
import {Router, RouterOutlet} from '@angular/router';
import {faCoffee} from '@fortawesome/free-solid-svg-icons';
import {AuthServiceService} from './services/auth-service.service';
import {Location} from '@angular/common';
import {BreadcrumbServiceService} from './services/breadcrumb-service.service';
import { VersionService } from './services/version.service';
import { BUILD_NUMBER, VERSION_NUMBER } from './config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { VersionModalComponent } from './shared/components/version-modal/version-modal.component';
import { initialState } from 'ngx-bootstrap/timepicker/reducer/timepicker.reducer';
import { AuthenticationFailedModalComponent } from './shared/components/authentication-failed-modal/authentication-failed-modal.component';
import { SpinnerService } from './services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})

export class AppComponent {

  title = 'ecoforest360';
  faCoffee = faCoffee;
  auth:Boolean = false;
  user:any;
  
  buildNumber: string = BUILD_NUMBER;
  versionNumber: string = VERSION_NUMBER;

  newVersionNumber: string = '';
  backendUiBuildNumber: string = '';
  mismatchedBuildBumber:Boolean = false;
  sessionTimedOut:Boolean = false;

  uiVersion:string = '';
  apiVersion:string = '';
  currentTimestamp:number = Date.now();
  
  navbarOpen = false;

  constructor(
    private authService: AuthServiceService,
    private router : Router,
    private location: Location,
    public breadcrumbService: BreadcrumbServiceService,
    public versionService: VersionService,
    public modalService: BsModalService,
    public spinnerService: SpinnerService
  ) {

    
    this.authService.appComponent = this;

    this.router.events.subscribe(event => {
      if (this.location.path() == '/login') {
        this.auth = false;
      } else {
        this.auth = true;
        this.user = JSON.parse(localStorage.getItem('user'));
        
      }
    });
  }

  

  ngOnInit() {

    try {
      this.versionService.versionOutput$.subscribe(output => {
        console.log('versionOutput$',output);

        if(output["status"]==403){
          let initialState = { }
          let modalRef = this.modalService.show(AuthenticationFailedModalComponent ,{class: 'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
        }

        this.backendUiBuildNumber = output['data']['uiBuildNumber'];
        this.apiVersion = output['data']['apiVersion'];
        this.uiVersion = output['data']['uiVersion'];
        this.mismatchedBuildBumber=output['isMismatched'];
  
        let initialState = {
          param : {
            backendUiBuildNumber:this.backendUiBuildNumber,
            apiVersion:this.apiVersion,
            uiVersion:this.uiVersion,
            uiBuildNumber:this.buildNumber,
            mismatchedBuild:this.mismatchedBuildBumber
          }
        }
      
        if(this.mismatchedBuildBumber){
  
          let modalRef = this.modalService.show(VersionModalComponent ,{class: 'modal-lg',initialState,keyboard:false,ignoreBackdropClick: true});
        }
       
      });
    } catch (error) {
      alert('Error in version service');
    }
    
    
  }

  

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  updateUser(){
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  logoutUser(){
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  getSystemApiVersion(){
    this.versionService.getApiVersion(this.currentTimestamp).subscribe(res=>{
      this.uiVersion = res['uiVersion'];
      this.apiVersion = res['apiVersion'];
      this.backendUiBuildNumber = res['uiBuildNumber'];

      if(res['uiBuildNumber']> parseInt(this.buildNumber)){
        this.mismatchedBuildBumber = true;
      }
    });
    
  }

  

}
