<div class="container-fluid wrapper">
  <div class="row title-row">
    <div class="pageTitle col-sm-12">
      <span class="pageSubTitle"> Edit Parent Company </span>
    </div>
    <div>
      <ngx-select
        style="
          padding-left: 25px;
          width: 200px;
          display: inline-block;
          float: right !important;
        "
        optionValueField="id"
        optionTextField="str"
        [items]="languages"
        [(ngModel)]="selectedLanguage"
        (select)="languageChanged()"
      ></ngx-select>
    </div>
  </div>
  <form  [formGroup]="systemEditForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-sm-9 col-md-9 offset-3">
        <div class="form-group row">
          <label
            for="systemDesignationDe"
            class="col-sm-3 col-form-label col-form-label-sm"
            >System Designation DE</label
          >
          <div class="col-sm-5">
            <input
              type="text"
              class="form-control"
              formControlName="systemDesignationDe"
              id="systemDesignationDe"
              placeholder="Designation DE"
              appInfoPromptBox
              [page]="'standardSystems'"
              [component]="'edit'"
              [field]="'systemDesignationDe'"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-baseline actionBtnBar"
    >
      <div
        class="alert-warning no-changes-found-warning"
        *ngIf="initialValues == currentFormValues()"
      >
        <span>No changes found...</span>
      </div>
      <button
        appButtonLoader
        [isLoading]="isLoading"
        id="btnSubmitProject"
        [disabled]="
          systemEditForm.invalid == true || initialValues == currentFormValues()
        "
        type="submit"
        class="btn btn-primary col-sm-2 actionBtn"
      >
        Save
      </button>
      <button
        id="btnReset"
        type="button"
        routerLink="/systems"
        class="btn btn-secondary col-sm-2 actionBtn"
      >
        Close
      </button>
    </div>
  </form>
</div>
