import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../../../services/common.service";
import {StructureComparisonService} from "../../../../services/structure-comparison.service";
import {ToastrService} from "ngx-toastr";
import {ViewSystemUrlComponent} from "../view-system-url/view-system-url.component";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ActivatedRoute} from "@angular/router";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-master-list-usage',
  templateUrl: './master-list-usage.component.html',
  styleUrls: ['./master-list-usage.component.scss']
})
export class MasterListUsageComponent implements OnInit {

  loading = true;
  itemType:any;
  itemId:any;
  data:any;
  title:string;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";

  settings = {
    type:'table',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    columns:[
      {
        title:'Project',
        attribute:'project',
        type:'text',
        width:100,
      },
      {
        title:'System',
        attribute:'system',
        type:'text',
        width:150
      },
      {
        renderComponent: ViewSystemUrlComponent,
        type:'custom',
        width:150
      }
    ]
  };

  constructor(public commonService:CommonService,
              private notificationService: ToastrService,
              private activatedRoute : ActivatedRoute,
              public bsModalRef: BsModalRef,
              public structureComparisonService: StructureComparisonService) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.setTitle();
  }

  setTitle(){
    if(this.itemType == "tools"){
      this.title = "Special Tools Usage";
    }
    else if(this.itemType == "operatingMaterials"){
      this.title = "Operating Materials Usage";
    }
    else if(this.itemType == "parts"){
      this.title = "System Parts Usage";
    }
  }

  loadData(){
    if(!this.itemId || !this.itemType) return;

    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.commonService.getUsageByItem(this.itemType, this.itemId).subscribe((res)=>{
      if(res){
        this.pageCount = res['totalPages'];
        let result = res['content'];

        Object.entries(result).forEach(([key, value]) => {
          if(value['projectNumber'] && value['projectDesignation']){
            value['project'] = value['projectNumber'] + " " + value['projectDesignation'];
          }
          if(value['systemCode'] && value['systemVariation'] && value['systemVariationNumber']){
            value['system'] = value['systemCode'] + "-" + value['systemVariationNumber'] + " - " + value['systemVariation'];
            value['systemVariationNumberAndCode'] = value['systemCode'] + "-" + value['systemVariationNumber'];
          }
        });
        this.data = result;
      }
      else {
        this.notificationService.error('Something went wrong while loading data... Please Retry...');
      }
      this.loading = false;
    },error => {
      this.notificationService.error('Something went wrong while loading data... Please Retry...');
      this.loading = false;
    })
  }
  changePage(currentPage){
    this.currentPage = currentPage;
    this.loadData();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.loadData();
  }

  doComparison(){
    // alert("Comparing Now!");
    let strData = '["a","b","c"]';
    let data = JSON.parse(strData);
    this.structureComparisonService.compareStructure(data, this.itemId).subscribe(res=>{
      const blob = new Blob([res], { type: 'application/octet-stream' });
      const file = new File([blob], 'ProjectParts.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      FileSaver.saveAs(file);
    });

  }

}
