import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProjectsModule} from './components/projects/projects.module';
import {SystemsModule} from './components/systems/systems.module';
import {TasksModule} from './components/tasks/tasks.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ComingSoonComponent} from './components/coming-soon/coming-soon.component';
import {LoginModule} from './components/login/login.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LaddaModule} from 'angular2-ladda';
import {ToastrModule} from 'ngx-toastr';
import {NgxSelectModule} from 'ngx-select-ex';
import {UsersInterceptor} from './intreceptors/users.interceptor';
import {OperatingMaterialsModule} from './components/operating-materials/operating-materials.module';
import {OperatingProfileModule} from './components/operating-profile/operating-profile.module';
import {SparePartsModule} from './components/spare-parts/spare-parts.module';
import {SharedModule} from './shared/shared.module';
import {MyProfileComponent} from './components/my-profile/my-profile.component';
import {SystemLogsModule} from './components/more/system-logs/system-logs.module';
import {AddLangInterceptor} from './intreceptors/add-lang.interceptor';
import {UserModule} from './components/more/user/user.module';
import {SuppliersModule} from './components/suppliers/suppliers.module';
import {TreeTableModule} from './shared/components/tree-table/tree-table.module';
import {AuthorizationModule} from "./components/more/authorization/authorization.module";
import {OverallSystemsModule} from "./components/more/overall-systems/overall-systems.module";
import {OverallTasksModule} from "./components/more/overall-tasks/overall-tasks.module";
import { RouteChangeDirective } from './directives/route-change.directive';
import { TaskDbidChangeModule } from './components/more/task-dbid-change/task-dbid-change.module';
import { SpinnerInterceptor } from './intreceptors/spinner.interceptor';
import { SpinnerService } from './services/spinner.service';
import { CorporateModule } from './components/corporate/corporate.module';
import { MatureStockModule } from './components/mature-stock/mature-stock.module';
import { ImmatureStockModule } from './components/immature-stock/immature-stock.module';
import { ReferenceDataModule } from './components/reference-data/reference-data.module';
import { OtherModule } from './components/other/other.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PageNotFoundComponent,
        ComingSoonComponent,
        MyProfileComponent,
        RouteChangeDirective,
        
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        UserModule,
        AuthorizationModule,
        MatureStockModule,
        ImmatureStockModule,
        ReferenceDataModule,
        OtherModule,
        CorporateModule,
        OverallSystemsModule,
        OverallTasksModule,
        TaskDbidChangeModule,
        ProjectsModule,
        SparePartsModule,
        SuppliersModule,
        SystemLogsModule,
        SystemsModule,
        OperatingMaterialsModule,
        OperatingProfileModule,
        TasksModule,
        FontAwesomeModule,
        LoginModule,
        AppRoutingModule,
        HttpClientModule,
        LaddaModule,
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        TreeTableModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right'
        }),
        NgxSelectModule,
        LaddaModule.forRoot({
            style: "zoom-in",
            spinnerSize: 30,
            spinnerLines: 12
        }),
    ],
    exports: [SharedModule,RouteChangeDirective],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UsersInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddLangInterceptor,
            multi: true
        },
        SpinnerService,
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
