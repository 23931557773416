import {Component, OnInit, ViewChild} from '@angular/core';
import { faPlus, faSync, faSearch,faEdit, faBackward,faAngleRight, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { ProjectService } from 'src/app/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectSystemSpareUrlComponent } from '../components/project-system-spare-url/project-system-spare-url.component';
import { RouterService } from 'src/app/services/router.service';
import { ProjectSystemEditUrlComponent } from './components/project-system-edit-url/project-system-edit-url.component';
import { ProjectSystemsService } from 'src/app/services/project-systems.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CloneProjectSystemsComponent } from './components/clone-project-systems/clone-project-systems.component';
import { SystemsVariationComponent } from './components/systems-variation/systems-variation.component';
import {ConfirmationBoxComponent} from "../../../shared/components/confirmation-box/confirmation-box.component";
import { CopyButtonUrlComponent } from './components/copy-button-url/copy-button-url.component';
import {BreadcrumbServiceService} from '../../../services/breadcrumb-service.service';
import {AddProjectSystemsComponent} from "./components/add-project-systems/add-project-systems.component";
import {AuthServiceService} from "../../../services/auth-service.service";
import {TreeTableComponent} from "../../../shared/components/tree-table/pages/tree-table/tree-table.component";
import {LanguageServiceService} from "../../../services/language-service.service";
import {ProjectLogUrlComponent} from "../components/project-log-url/project-log-url.component";
import {ProjectSystemsLogUrlComponent} from "./components/project-systems-log-url/project-systems-log-url.component";
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-project-systems',
  templateUrl: './project-systems.component.html',
  styleUrls: ['./project-systems.component.scss']
})
export class ProjectSystemsComponent implements OnInit {
  faAngleRight = faAngleRight;
  faPlus = faPlus;
  faFileExport = faFileExport;
  faSync = faSync;
  showReset:Boolean=false;
  faSearch = faSearch;
  laddaLoading:Boolean=false;
  infoStatus = false;
  faEdit = faEdit;
  finalProjectSystem:any = [];
  previousUrl:any;
  projectSystemData:any=[];
  faBackward = faBackward;
  loading: Boolean = true;
  projectData:any;
  projectId:any;
  systemVariationNumberAndCode:any;
  tableData:any = [];
  searchValue:any = "";
  propertyMapLoaded = false;
  projectSystemDeletionStarted:Boolean = false;
  currentSpinnerText:String = "Loading Project Systems...";
  projectFreezeFunction = "";
  currentUser:any = [];
  projectNumber:any;
  

  @ViewChild(TreeTableComponent) treeComponent: TreeTableComponent;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'projectSystemId',
    columnManagerEnable:true,
    variableColumnMinLength:75,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:false,
      left:1,
      right:6
    },
    filterOptionFunction:(params, filterData)=>{return this.projectService.getProjectSystems(this.projectId,"distinct-values", filterData, params)},
    enableZoom:true,
    rowClassFunction: (row) => {return (row && row.isActive == 1) ? 'inactive-system' : ''},
    columns:[
      {
        title:'Code',
        attribute:'systemVariationNumberAndCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        title:'System Variation',
        attribute:'systemVariation',
        renderComponent: SystemsVariationComponent,
        onComponentInitFunction : (instance) => {
          instance.projectFreezeFunction = this.projectFreezeFunction;
        },
        type:'custom-text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:300
      },
      {
        title:'Stadler Article ID',
        attribute:'stadlerArticleId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        title:'Mounting Location',
        attribute:'mountingLocationsStr',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'System Qty',
        attribute:'systemQuantity',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:110
      },
      {
        title:'RAM Engineer',
        attribute:'ramEngineersStr',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:125
      },
      {
        title:'Supplier',
        attribute:'systemSupplierName',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Comment',
        attribute:'systemComment',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:null
      },
      {
        renderComponent: ProjectSystemSpareUrlComponent,
        type:'custom',
        width:30
      },
      // {
      //   renderComponent: CloneProjectSystemsComponent,
      //   type:'custom',
      //   onComponentInitFunction: (instance) => {
      //     instance.finalProjectSystem = this.finalProjectSystem;
      //     instance.eventEmitter.subscribe((value)=>{
      //       if(value == "refresh") this.loadProjectSystems();
      //     });
      //   },
      //   width:50
      // },
      {
        renderComponent: CopyButtonUrlComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.projectFreezeFunction = this.projectFreezeFunction;
          instance.eventEmitter.subscribe((value)=>{
            if(value == "refresh") this.loadProjectSystems();
          });
        },
        width:30
      },
      {
        renderComponent: ProjectSystemsLogUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent: ProjectSystemEditUrlComponent,
        type:'custom',
        onComponentInitFunction : (instance) => {
          instance.projectFreezeFunction = this.projectFreezeFunction;
          instance.eventEmitter.subscribe((value)=>{
            if(value == "refresh") this.loadProjectSystems();
          });
        },
        width:30
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm text-light btn-url task-btn"><img title="Tasks" class="delete-icon" src="/assets/icon/tasks.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.loadProjectSystemTaskView(row)},
        width:30
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteProjectSystems(row)},
        hideFunction: (row)=>{return row.systemCode == "AA00" ||  row.isActive==null || row.isActive==0},
        // hideFunction: (row)=>{return row.systemCode == "AA00" },
        width:50,
      }
    ]
  };

  constructor(
      private projectService : ProjectService,
      private activatedroute : ActivatedRoute,
      private routerService : RouterService,
      private projectSystemService: ProjectSystemsService,
      private notificationService: ToastrService,
      private modalService: BsModalService,
      private notification: ToastrService,
      private router : Router,
      private activatedRoute : ActivatedRoute,
      public breadcrumbService: BreadcrumbServiceService,
      public authService:AuthServiceService,
      private languageService:LanguageServiceService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
      if(params['systemVariationNumberAndCode']) this.systemVariationNumberAndCode = params['systemVariationNumberAndCode'];
    });

    this.projectId = this.activatedroute.snapshot.paramMap.get("id");

    this.initializeGridWithFilters(this.systemVariationNumberAndCode);
  }

  ngOnInit(): void {
    if(this.breadcrumbService.isStackEmpty()){
      this.breadcrumbService.pushPage(1, "Systems", "/projects/"+this.projectId+"/systems");
    }
  }

  initializeGridWithFilters(systemVariationNumberAndCode)
  {
      this.authService.getLoginUserData().subscribe((res)=>{
        this.currentUser = res;
        this.getProjectDataById(this.projectId, systemVariationNumberAndCode);
      });
  }

  ngAfterViewInit(){
    this.setFiltersAfterPropertyMapLoaded();
  }

  getProjectDataById(projectNumber, mySystemVariationNumberAndCode){
    this.projectService.getProjectById(projectNumber).subscribe(res=>{
      this.projectData = res;
      this.projectFreezeFunction = res['freezeFunction'];
      this.infoStatus = true;
      let lan  = {
        id: res['workLangId'],
        str: res['workLangId'] == 1 ? "German" : "English"
      }
      this.languageService.setWorkingLanguage(lan);
      if(mySystemVariationNumberAndCode){
        this.loadProjectSystemsWithSystemCode(mySystemVariationNumberAndCode);
      }else{
        if(this.currentUser['primaryRole'] != 8){
          this.loadProjectSystems();
        }else{
          this.loadProjectSystemsWithRamEngineerFilter();
        }
      }

      // this.loadProjectSystems();
    });
  }

  setFiltersAfterPropertyMapLoaded(){
    if(this.propertyMapLoaded) {
      if(this.systemVariationNumberAndCode) this.setSystemVariationNumberAndCodeFilter();
      // else this.setRamEngineerFilter();
      return;
    }
    setTimeout(()=>{
      this.setFiltersAfterPropertyMapLoaded();
    },0)
  }

  setSystemVariationNumberAndCodeFilter(){
    if(!this.treeComponent || !this.treeComponent.treeTable) return;

    let treeTable = this.treeComponent.treeTable;

    treeTable.settings.isNative = true;
    treeTable.filter = {
      enabled:true,
      isNumericField:false,
    };
    treeTable.filterBySearchString('ram_project_systems', 'system_variation_number_and_code', this.systemVariationNumberAndCode, 'systemVariationNumberAndCode');
  }



  setRamEngineerFilter(){
    if(!this.treeComponent || !this.treeComponent.treeTable) return;
    if(this.currentUser['primaryRole'] != 8) return;

      let ramEngineerName = this.currentUser['firstName'] + ' ' + this.currentUser['lastName'];
      let treeTable = this.treeComponent.treeTable;

      treeTable.settings.isNative = true;
      treeTable.filter = {
        enabled:true,
        isNumericField:false,
      };
      treeTable.filterBySearchString('ram_project_systems', 'ramengineers_str', ramEngineerName, 'ramEngineersStr');

  }
  /**
   * Delete Project systems
   * @param data
   */
  deleteProjectSystems(rowData)
  {
    this.projectSystemDeletionStarted = true;
    this.currentSpinnerText = "Deleting Project System...";
    const initialState = {
      title: 'Are you sure that you want to Delete the System from the project including related Profile information and other data?',
      okBtnName: 'OK',
      closeBtnName: 'Cancel',
      isCommentVisible: false
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {

        const initialState = {
          title: 'Are you sure that you want to Delete the System from the project including related Profile information and other data?',
          okBtnName: 'OK',
          closeBtnName: 'Cancel',
          titleBackground:'bg-danger'
        };
        let modalRef2 = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
        modalRef2.content.event.subscribe(res =>
        {
          if(res?.result?.action == 'ok')
          {
            this.projectSystemService.deleteProjectSystem(res?.result, rowData.projectSystemId,rowData.projectNumber).subscribe(res => {}, error =>
            {
              if(error.status == 500) {
                this.notificationService.error('The Project System you tried to delete is in use, hence cannot be deleted','Error');
              }else if(error.status == 403) {
                this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
              }else if(error.status == 200 || error.status == 201 || error.status == 202 ) {
                this.notificationService.success('The system has been deleted','Success');
              }else {
                this.notificationService.error('The system has not been deleted','Error');
              }
              this.projectSystemDeletionStarted = false;
              this.currentSpinnerText = "Loading Project Systems...";
              this.loadProjectSystems();
            },()=>{
              this.projectSystemDeletionStarted = false;
              this.currentSpinnerText = "Loading Project Systems...";
            });
          }
          else {
            this.projectSystemDeletionStarted = false;
            this.currentSpinnerText = "Loading Project Systems...";
          };

        });

      }
      else {
        this.projectSystemDeletionStarted = false;
        this.currentSpinnerText = "Loading Project Systems...";
      }; // do nothing
    });
  }

  // Project Systems - Initial Load
  loadProjectSystemsWithRamEngineerFilter(){
    // alert("Ram Engineer Filter : " + this.currentUser["firstName"]);
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    let ramEngineerName = this.currentUser["firstName"] + ' ' + this.currentUser["lastName"];

    this.filterData = [{"column":"ramengineers_str","operation":"LIKE","values":[ramEngineerName]}];

    this.projectService.getProjectSystems(this.projectId, "filtered-data", this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];

        this.tableData = result;
        this.finalProjectSystem = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) {
          this.propertyMapLoaded = true;
          return;
        }

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
        this.propertyMapLoaded = true;
      }
      else {
        this.propertyMapLoaded = true;
      }

      this.settings['isNative'] = res && res['isNative'];

    },(error => {
      
      this.propertyMapLoaded = true;
    }));
    this.loading = false;
  }

  loadProjectSystemsWithSystemCode(mySystemVariationNumberAndCode){
    // alert("Ram Engineer Filter : " + this.currentUser["firstName"]);
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    this.filterData = [{"column":"system_variation_number_and_code","operation":"IN","values":[mySystemVariationNumberAndCode]}];

    this.projectService.getProjectSystems(this.projectId, "filtered-data", this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];

        this.tableData = result;
        this.finalProjectSystem = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) {
          this.propertyMapLoaded = true;
          return;
        }

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
        this.propertyMapLoaded = true;
      }
      else {
        this.propertyMapLoaded = true;
      }

      this.settings['isNative'] = res && res['isNative'];
    },(error => {
      
      this.propertyMapLoaded = true;
    }));
    this.loading = false;
  }

  /**
   * Load Project System By Project Number
   */
  loadProjectSystems(){
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.projectService.getProjectSystems(this.projectId, "filtered-data", this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];

        this.tableData = result;
        this.finalProjectSystem = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) {
          this.propertyMapLoaded = true;
          return;
        }

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
        this.propertyMapLoaded = true;
      }
      else {
        this.propertyMapLoaded = true;
      }

      this.settings['isNative'] = res && res['isNative'];
    },(error => {
      
      this.propertyMapLoaded = true;
    }));
    this.loading = false;
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.tableData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.loadProjectSystems();
  }

  loadProjectSystemTaskView(row){
    this.breadcrumbService.navigate(2, "Task", "projects/"+row.projectNumber+"/systems/"+row.projectSystemId+"/tasks");
  }

  /**
   * Load project system add modal
   */
  loadProjectSystemAddModal(){
    const initialState = {
      param : {
        projectNUmber:this.projectId
      },
      projectFreezeFunction : this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(AddProjectSystemsComponent, {class:'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      let data = res['data'] && res['data']['projectSystems'] ? res['data']['projectSystems'] : [];
      let reason = res['data'] && res['data']['res'] ? res['data']['res'] : {};

      this.projectSystemData = [];
      for(var x=0; x < data.length; x++){
        this.projectSystemData.push({

          systemNumber : data[x].systemNumber,
          ramEngineersStr : data[x].ramEngineersStr
        });
      }

      this.projectService.addProjectSystems(reason,this.projectSystemData,this.projectId).subscribe(res=>{
        if(res){
          this.notification.success('Project System has been created', 'Success');
        }else{
          this.notification.error('Project System has not been created', 'Error');
        }
      }, (error => {
        this.notification.error(error.error['statusMessage'], 'Error');
      }),()=>{
        
        this.loadProjectSystems();
      });
    });
    this.laddaLoading = false;
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    // this.breadcrumbService.replacePage("Systems", '/projects/'+this.projectId+'/systems?page='+ this.currentPage +'&pageSize=' + this.pageSize);
    this.loadProjectSystems();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.loadProjectSystems();
  }

  public exportProjectSystems(){
    this.loading = true;

    let data = Object();
    
      this.projectSystemService.exportProjectSystems(this.projectId).subscribe(res=>{
        const blob = new Blob([res], { type: 'application/octet-stream' });
        const file = new File([blob], this.projectId+'_Systems.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        FileSaver.saveAs(file);
        this.loading = false;
      }, (error => {
        if(error.status == 403) {
          this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
        }else{
          this.notificationService.error('Project System Parts has not been exported', 'Error');
        }
        this.loading = false;
      }));
      
}
}
