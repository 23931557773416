import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ProjectData} from './project.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LogServiceService {

    constructor(private http: HttpClient) { }

    public getLogs(data, params)
    {
        return this.http.post<ProjectData>(environment.apiUrl + "/logs/filter", data, {params: params});
    }

    public exportData(data)
    {
        return this.http.post(environment.apiUrl + "/logs/export", data, {responseType: 'blob'});
    }

    public getUsers()
    {
        return this.http.get(environment.apiUrl + "/users/");
    }

    public getProjectEntities()
    {
        return this.http.get(environment.apiUrl + "/logs/entities");
    }

    public getActions()
    {
        return this.http.get(environment.apiUrl + "/logs/actions/");
    }
}
