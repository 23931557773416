import { Component } from '@angular/core';

@Component({
  selector: 'app-immature-fields',
  templateUrl: './immature-fields.component.html',
  styleUrls: ['./immature-fields.component.scss']
})
export class ImmatureFieldsComponent {

}
