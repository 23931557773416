import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    getAllUsers(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/users/" + apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/users/", data);
    }

    update(data, id)
    {
        return this.http.put(environment.apiUrl + "/users/" + id, data);
    }

    resetPassword(data, id)
    {
        return this.http.post(environment.apiUrl + "/users/" + id + "/resetpassword", data);
    }

    resetOwnPassword(data, id)
    {
        return this.http.post(environment.apiUrl + "/users/" + id + "/resetownpassword", data);
    }

    getAuthorization()
    {
        return this.http.get(environment.apiUrl + "/authorizations/");
    }

    getAuthCategories()
    {
        return this.http.get(environment.apiUrl + "/authorizations/categories");
    }

    getAuthorizationFiltered(filterText)
    {
        return this.http.get(environment.apiUrl + "/authorizations/filtered/" + filterText);
    }

    updateUserAuthorization(data, roleId)
    {
        return this.http.post(environment.apiUrl + "/authorizationroles/role/" + roleId, data);
    }

    getUserAuthbyId(userId)
    {
        return this.http.get(environment.apiUrl + "/authorizationroles/user/" + userId);
    }

    getUserAuthorization(roleId)
    {

        return this.http.get(environment.apiUrl + "/authorizationroles/role/" + roleId);
    }

    getUserAuthorizationFiltered(roleId, filterText)
    {

        return this.http.get(environment.apiUrl + "/authorizationroles/filtered/role/" + roleId + "/" + filterText);
    }

    getRamengineers()
    {
        return this.http.get(environment.apiUrl + "/stakeholders/ramengineers");
    }

}
