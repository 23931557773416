import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CorporateRoutingModule } from './corporate-routing.module';
import { ParentCompanyComponent } from './parent-company/parent-company.component';
import { CompanyComponent } from './company/company.component';
import { EstateComponent } from './estate/estate.component';
import { DivisionComponent } from './division/division.component';
import { FieldComponent } from './field/field.component';
import { NgxSelectComponent, NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    ParentCompanyComponent,
    CompanyComponent,
    EstateComponent,
    DivisionComponent,
    FieldComponent
  ],
  imports: [
    CommonModule,
    NgxSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CorporateRoutingModule
  ]
})
export class CorporateModule { }
