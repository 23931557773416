import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { ParentCompanyComponent } from './parent-company/parent-company.component';
import { DivisionComponent } from './division/division.component';
import { EstateComponent } from './estate/estate.component';
import { FieldComponent } from './field/field.component';

const routes: Routes = [
  { path: 'company', component: CompanyComponent },
  { path: 'division', component: DivisionComponent },
  { path: 'estate', component: EstateComponent },
  { path: 'field', component: FieldComponent },
  { path: 'parent-company', component: ParentCompanyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorporateRoutingModule { }
