import {Component, OnInit, Input} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ProjectSystemSparePartsService} from 'src/app/services/project-system-spare-parts.service';
import {ProjectSystemTaskSpareService} from 'src/app/services/project-system-task-spare.service';
import {ToastrService} from 'ngx-toastr';
import {QuantityUnitServiceService} from '../../../../services/quantity-unit-service.service';
import {ActualQuantityComponent} from "./components/actual-quantity/actual-quantity.component";
import {Observable, Observer} from "rxjs";
import {LanguageSupportComponent} from "../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../services/language-service.service";
import {ProjectService} from "../../../../services/project.service";

@Component({
  selector: 'app-project-system-task-spare-parts',
  templateUrl: './project-system-task-spare-parts.component.html',
  styleUrls: ['./project-system-task-spare-parts.component.scss']
})
export class ProjectSystemTaskSparePartsComponent extends LanguageSupportComponent implements OnInit {

  param: any;
  projectNumber: any;
  systemNumber: any;
  taskDbId: any;
  taskElement: any;
  isFormInvalid = false;
  projectSystemSparePartList: any = [];
  systemTaskSparePartsMap:any = new Map();
  partsListToAdd:any = [];
  partsListToDelete:any = [];
  partsListToEdit:any = [];
  projectFreezeFunction = "";

  btnLoading:Boolean = false;

  fetchChildren = (row)=>{
    const observable = new Observable((observer: Observer<any>)=>{
      let parentId = row['spareNumber'];
      let projectSystemSparePartList: any = [];
      this.projectSystemSparePartService.getProjectSystemSparePartsByParent(this.projectNumber,this.systemNumber, parentId).subscribe(res=> {
        projectSystemSparePartList = res;
        let systemTaskSparePartsMap = new Map();
        this.projectSystemTaskSpareService.getSystemTaskSpareParts(this.projectNumber,this.systemNumber, this.taskDbId).subscribe(res => {
          Object.entries(res).forEach(([key, value]) => {
            systemTaskSparePartsMap.set(value['spareNumber'], value);
          });

          for(let part of projectSystemSparePartList){
            part['isPartAdded'] = systemTaskSparePartsMap.has(part['spareNumber']);
            if(part['isPartAdded']){
              let taskPart = systemTaskSparePartsMap.get(part['spareNumber']);
              if(taskPart) part['actualQuantity'] = taskPart['actualQuantity'];
            }
          }

          observer.next(projectSystemSparePartList);
        });
      });
    });
    return observable;
  };

  settings = {
    type:'tree',
    id:'spareNumber',
    childrenFetchFunc: this.fetchChildren,
    tableClass:'',
    tableHeaderClass:'',
    tableRowClass:'',
    columnManagerEnable:true,
    enableZoom:true,
    columns:[
      {
        title:'Stadler Article ID',
        attribute:'spareStadlerArticleId',
        type:'text',
        width:250,
      },
      {
        title:'Part Designation',
        attribute:'spareDesignation',
        type:'text',
        width:250
      },
      {
        title:'Additional Designation',
        attribute:'additionalDesignation',
        type:'text',
        width:250
      },
      {
        title:'Quantity',
        attribute:'quantity',
        type:'text',
        width:150,
      },
      {
        title:'Quantity on System Level',
        attribute:'quantityOnSystemLevel',
        type:'text',
        width:200,
      },
      // {
      //   title:'Quantity on Vehicle Level',
      //   attribute:'quantityOnVehicleLevel',
      //   type:'text',
      //   width:200,
      // },
      {
        title:'Actual Quantity',
        renderComponent: ActualQuantityComponent,
        onComponentInitFunction:(instance)=>{
          instance.inputValueChange.subscribe((part)=>{
            this.isFormInvalid = false;
            let isExistingOne = this.systemTaskSparePartsMap.has(part['spareNumber']);
            if(isExistingOne){
              let found = false;
              for(let partToDelete of this.partsListToDelete){
                if(partToDelete['spareNumber'] == part['spareNumber']) {
                  found = true; break;
                }
              }
              if(!found) {
                let index = this.partsListToEdit.indexOf(part);
                if(index<0) this.partsListToEdit.push(part);
              }
            }
            this.validatePartList();
          });
        },
        type:'custom-text',
        width:200,
      },
      {
        title:'Unit',
        attribute:'spareUnitDesignation',
        type:'text',
        width:150,
      },
      {
        type:'checkbox',
        isDisabledFunction: (row)=>{
          // console.log(row);
          return this.taskElement == row["spareNumber"];
        },
        actionFunction: (event, row)=>{this.onSelectItem(event, row)},
        checkIsCheckedFunction: (row)=>{
          return this.checkPartIsAlreadyAdded(row)
        },
        width:100,
      },
    ]
  };

  constructor(
      public bsModalRef: BsModalRef,
      private projectSystemSparePartService: ProjectSystemSparePartsService,
      private projectSystemTaskSpareService: ProjectSystemTaskSpareService,
      private quantityUnitService: QuantityUnitServiceService,
      private projectService : ProjectService,
      private notificationService: ToastrService,
      public languageServiceService:LanguageServiceService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.projectNumber = this.param['projectNumber'];
    this.systemNumber = this.param['systemNumber'];
    this.taskDbId = this.param['taskDbId'];
    this.taskElement = this.param['taskElement'];

    this.getProjectByProjectNumber();
    this.validatePartList();
  }

  getProjectByProjectNumber() {
    this.projectService.getProjectById(this.projectNumber).subscribe(res => {
      this.projectFreezeFunction = res['freezeFunction'];

      let lan  = {
        id: res['workLangId'],
        str: res['workLangId'] == 1 ? "German" : "English"
      }
      this.languageServiceService.setWorkingLanguage(lan);

      this.getProjectSystemSpareParts(this.projectNumber, this.systemNumber, this.taskDbId);
      super.saveInitialValues(JSON.stringify(this.partsListToEdit) + JSON.stringify(this.partsListToAdd) + JSON.stringify(this.partsListToDelete));
    });
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.partsListToEdit) + JSON.stringify(this.partsListToAdd) + JSON.stringify(this.partsListToDelete));
  }

  getProjectSystemSpareParts(projectNumber, systemNumber, taskDbId) {
    let parentId = -1;
    this.projectSystemSparePartService.getProjectSystemSparePartsByParent(projectNumber, systemNumber, parentId).subscribe(res=>{
      this.projectSystemSparePartList = res;
      this.getProjectSystemTaskSpareParts(projectNumber, systemNumber, taskDbId);
    });
  }

  getProjectSystemsTask(taskDbId:String){

  }

  getProjectSystemTaskSpareParts(projectNumber, systemNumber, taskDbId) {
    this.systemTaskSparePartsMap.clear();
    this.projectSystemTaskSpareService.getSystemTaskSpareParts(projectNumber, systemNumber, taskDbId).subscribe(res => {
      Object.entries(res).forEach(([key, value]) => {
        this.systemTaskSparePartsMap.set(value['spareNumber'], value);
      });

      for(let part of this.projectSystemSparePartList){
        part['isPartAdded'] = this.systemTaskSparePartsMap.has(part['spareNumber']);
        if(part['isPartAdded']){
          let taskPart = this.systemTaskSparePartsMap.get(part['spareNumber']);
          if(taskPart) part['actualQuantity'] = taskPart['actualQuantity'];
        }
      }
    });
  }

  checkPartIsAlreadyAdded(part){
    return part['isPartAdded'];
  }

  onSelectItem(event, part) {
    part['isPartAdded'] = event.target.checked;
    let isExistingOne = this.systemTaskSparePartsMap.has(part['spareNumber']);
    if (event.target.checked) {
      if (!isExistingOne) this.partsListToAdd.push(part);
      else {
        for(let i=0; i<this.partsListToDelete.length; i++){
          let partToDelete = this.partsListToDelete[i];
          if(partToDelete['spareNumber'] == part['spareNumber']) this.partsListToDelete.splice(i, 1);
        }
      }
      part['invalidActualQuantity'] = part['actualQuantity'] == null || part['actualQuantity'] > part['quantity'];
      this.validatePartList();
    }
    else {
      if (isExistingOne) {
        this.partsListToDelete.push(part);

        for(let i=0; i<this.partsListToEdit.length; i++){
          let partToEdit = this.partsListToEdit[i];
          if(partToEdit['spareNumber'] == part['spareNumber']) {
            this.partsListToEdit.splice(i, 1);
          }
        }
      }
      else {
        for(let i=0; i<this.partsListToAdd.length; i++){
          let partToAdd = this.partsListToAdd[i];
          if(partToAdd['spareNumber'] == part['spareNumber']) this.partsListToAdd.splice(i, 1);
        }
      }
      part['actualQuantity'] = null;
      part['invalidActualQuantity'] = true;
      this.validatePartList();
    }
  }

  saveChanges(event){
    this.btnLoading = true;
    let body = {
      partsListToEdit : this.partsListToEdit,
      partsListToDelete : this.partsListToDelete,
      partsListToAdd : this.partsListToAdd,
    }

    if(this.partsListToEdit.length > 0 || this.partsListToDelete.length > 0 || this.partsListToAdd.length > 0){
      this.projectSystemTaskSpareService.manageSystemTaskSpareParts(event, body, this.projectNumber, this.systemNumber, this.taskDbId).subscribe((res)=>{
        if(res){
          this.btnLoading = false;
          this.notificationService.success("Parts Updated Successfully", "Success");
        }
        else this.notificationService.error("Parts not Updated Successfully", "Error");
      },(error => {
        this.btnLoading = false;
        this.notificationService.error("Parts not Updated Successfully", "Error");
      }));
    }

    this.bsModalRef.hide();
  }

  validatePartList(){
    this.isFormInvalid = false;
    for(let part of this.partsListToAdd){
      if(part['invalidActualQuantity'] && part['isPartAdded']) this.isFormInvalid = true;
    }
    for(let part of this.partsListToEdit){
      if(part['invalidActualQuantity'] && part['isPartAdded']) this.isFormInvalid = true;
    }
  }

  callAfterLanguageChanged() {
    this.getProjectSystemSpareParts(this.projectNumber, this.systemNumber, this.taskDbId);
  }
}
