import {Component, OnInit, ViewChild} from '@angular/core';
import {SparePartsService} from 'src/app/services/spare-parts.service';
import {faFile, faFileExport, faFileImport, faPlus, faSearch, faSync} from '@fortawesome/free-solid-svg-icons';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AddSparePartsComponent} from '../components/add-spare-parts/add-spare-parts.component';
import {EditSparePartsComponent} from '../components/edit-spare-parts/edit-spare-parts.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationBoxComponent} from '../../../shared/components/confirmation-box/confirmation-box.component';
import {SparePartLogComponent} from '../spare-part-log/spare-part-log.component';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MasterListUsageUrlComponent} from "../../../shared/components/master-list-usage/master-list-usage-url/master-list-usage-url.component";
import {ActivatedRoute} from "@angular/router";
import * as FileSaver from 'file-saver';
import {PartsImportDataSourcePromptBoxComponent} from "../../../shared/components/parts-import-data-source-prompt-box/parts-import-data-source-prompt-box.component";
import { SystemAddComponent } from '../../systems/system-add/system-add.component';
import { CommonErrorModalComponent } from 'src/app/shared/components/common-error-modal/common-error-modal.component';


@Component({
  selector: 'app-spare-part-list',
  templateUrl: './spare-part-list.component.html',
  styleUrls: ['./spare-part-list.component.scss']
})
export class SparePartListComponent implements OnInit {

  @ViewChild('fileInput') fileInput;
  @ViewChild('fileInputArticleChange') fileInputArticleChange;
  selectedFile: File = null;
  selectedFileArticleChange: File = null;
  sparePartsData: any = [];
  faSync = faSync;
  showReset = false;
  faSearch = faSearch;
  laddaLoading: Boolean = false;
  searchForm = new UntypedFormGroup({
    supplierArticleId: new UntypedFormControl(''),
    spareDesignationDe: new UntypedFormControl(''),
    spareStadlerArticleId: new UntypedFormControl(''),
  });

  faPlus = faPlus;
  faFile = faFile;
  faFileExport = faFileExport;
  faFileImport = faFileImport;
  loading: Boolean = true;

  currentPage: number = 1;
  pageCount: number = 0;
  pageSize: number = 20;
  sortColumn: string = "";
  sortOrder: string = "";
  filterData = [];
  btnExportLoading: Boolean = false;

  spinnerVisible = false;
  spinnerText = "";

  settings = {
    type: 'table',
    id:'spareNumber',
    columnManagerEnable: true,
    pagination: {
      enabled: true,
      pageSize: this.pageSize
    },
    freezeColumns: {
      enabled: true,
      left: 2,
      right: 3
    },
    enableZoom: true,
    filterOptionFunction: (params, filterData) => {
      return this.sparePartService.getAllSpareParts("distinct-values", filterData, params)
    },
    columns: [
      {
        title: 'ID',
        attribute: 'spareNumber',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: true,
        },
        width: 50,
      },
      {
        title: 'Standard System Code',
        attribute: 'standardSystemCode',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: true,
        },
        width: 150,
      },
      {
        title: 'Stadler Article ID',
        attribute: 'spareStadlerArticleId',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: true,
        },
        width: 150
      },
      {
        title: 'System Designation DE',
        attribute: 'spareDesignationDe',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 250,
      },
      {
        title: 'System Designation EN',
        attribute: 'spareDesignationEn',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 250,
      },
      {
        title: '3dx Article ID',
        attribute: 'threedxArticleId',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 150,
      },
      {
        title: 'SAP Article ID',
        attribute: 'sapArticleId',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 150,
      },
      {
        title: 'Supplier Article ID',
        attribute: 'supplierArticleId',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 250,
      },
      {
        title: 'Supplier',
        attribute: 'supplierName',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 250,
      },
      {
        title: 'Weight',
        attribute: 'spareWeight',
        type: 'text',
        format: "'###",
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 100,
      },
      {
        title: 'Parts Units',
        attribute: 'spareUnitDesignation',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 150,
      },
      {
        title: 'Dummy',
        attribute: 'dummy',
        type: 'text',
        filter: {
          enabled: true,
          isNumericField: false,
        },
        width: 150,
      },
      {
        title: '',
        attribute: 'comment',
        type: 'text',
        filter: {
          enabled: false,
          isNumericField: false,
        },
        width: 50,
      },
      {
        renderComponent: MasterListUsageUrlComponent,
        type: 'custom',
        onComponentInitFunction: (instance) => {
          instance.itemType = "parts";
        },
        width: 30
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type: 'html',
        actionFunction: (row) => {
          this.loadEditModal(row)
        },
        width: 30
      },
      {
        renderComponent: '<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type: 'html',
        actionFunction: (row) => {
          this.deleteSpareParts(row)
        },
        width: 50
      }
    ]
  };

  constructor(
      private sparePartService: SparePartsService,
      private modalService: BsModalService,
      private activatedRoute: ActivatedRoute,
      private notificationService: ToastrService
  ) {
    activatedRoute?.queryParams?.subscribe(params => {
      if (params['page']) this.currentPage = +params['page'];
      if (params['column']) this.sortColumn = params['column'];
      if (params['sortOrder']) this.sortOrder = params['sortOrder'];
    });

  }

  ngOnInit(): void {
    this.getAllSaprePArts();
  }

  /**
   * Get all spareparts
   */
  getAllSaprePArts() {
    this.loading = true;
    let params = {};
    if (this.currentPage) params['page'] = this.currentPage - 1;
    if (this.pageSize) params['pageSize'] = this.pageSize;
    if (this.sortColumn) params['column'] = this.sortColumn;
    if (this.sortOrder) params['sortOrder'] = this.sortOrder;

    this.sparePartService.getAllSpareParts('filtered-data', this.filterData, params).subscribe(res => {
      if (res && res['page']) {
        this.pageCount = res['page']['totalPages'];
        this.sparePartsData = res['page']['content'];
        this.loading = false;
      }
      if (res && res['tablePropertyMap']) {
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for (let column of columns) {
          if (column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if (!needFillColumnAndTable) return;

        let tablePropertyMap: Map<string, {}> = res['tablePropertyMap'];
        for (let i = 0; i < columns.length; i++) {
          let data = tablePropertyMap[columns[i].attribute];
          if (!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = res && res['isNative'];
    });
  }

  filterOrSortChange(event) {
    if (!event) {
      this.currentPage = 1;
      this.sparePartsData = [];
      return;
    }
    if (!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAllSaprePArts();
  }

  /**
   * Delete spare parts
   * @param event
   */
  deleteSpareParts(rowData) {
    const initialState = {
      title: 'Are you sure?',
      okBtnName: 'Yes',
      closeBtnName: 'No'
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {
      class: 'modal-md',
      initialState,
      ignoreBackdropClick: true
    });
    modalRef.content.event.subscribe(res => {
      if (res?.result?.action == 'ok') {
        let spareNumber = rowData.spareNumber;
        this.sparePartService.delete(res?.result, spareNumber).subscribe(res => {
          console.log(res);
          this.notificationService.success(res["statusMessage"],'suceess');
        }, error => {
          console.log(error);
          if (error.status == 412) {
            this.notificationService.error('The Part you tried to delete is in use, hence cannot be deleted','Error');
          } 
          //else if (error.error.status == 200 ) {
          //   this.notificationService.success('The Part has been deleted', 'success');
          // } 
          else {
            this.notificationService.error('The deletion of the part is not possible at this time!', 'Error');
          }
          this.getAllSaprePArts();
        });
      } else ; // do nothing
    });
  }


  /**
   * Load Edit Modal
   * @param rowData
   */
  loadEditModal(rowData) {
    var data = rowData;
    const initialState = {
      param: data
    };
    let modalRef = this.modalService.show(EditSparePartsComponent, {
      class: 'modal-lg',
      initialState,
      keyboard: false,
      ignoreBackdropClick: true
    });
    modalRef.content.event.subscribe(res => {
      this.getAllSaprePArts();
    });
  }

  /**
   * Load Parts add modal
   */
  loadAddModal() {
    const initialState = {};
    let modalRef = this.modalService.show(AddSparePartsComponent, {
      class: 'modal-lg',
      initialState,
      keyboard: false,
      ignoreBackdropClick: true
    });
    modalRef.content.event.subscribe(res => {
      this.getAllSaprePArts();
    });
  }

  public showLogs() {
    const initialState = {};
    this.modalService.show(SparePartLogComponent, {
      class: 'modal-xl',
      initialState,
      keyboard: false,
      ignoreBackdropClick: true
    });
  }

  public changePage(currentPage) {
    this.currentPage = currentPage;
    this.getAllSaprePArts();
  }

  public changePageSize(pageSize) {
    this.pageSize = pageSize;
    this.getAllSaprePArts();
  }

  public exportSpareParts() {
    this.btnExportLoading = true;
    this.sparePartService.exportSpareParts().subscribe((res: any) => {
      this.btnExportLoading = false;
      const blob = new Blob([res], {type: 'application/octet-stream'});
      const file = new File([blob], 'spare-parts.xlsx', {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      FileSaver.saveAs(file);
      this.btnExportLoading = false;
    }, error => {
      this.btnExportLoading = false;
    }, () => {
      this.btnExportLoading = false;
    });
  }

  public onChangeFileInput() {
    if (!this.fileInput || !this.fileInput.nativeElement || !this.fileInput.nativeElement.files) return;

    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.selectedFile = files[0];

    if (!this.selectedFile) return;

    this.spinnerText = "Uploading and Validating Data";
    this.spinnerVisible = true;

    let formData = new FormData();
    formData.append('file', this.selectedFile);
    this.sparePartService.uploadSpareParts(formData).subscribe((res: any) => {
      if (!res) {
        this.spinnerVisible = false;
        return;
      } else {
        let data: any[] = res.data;
        let startRow : any = res.startRowNumber;
        let endRow : any = res.endRowNumber;

        // console.log(data);
        const initialState = {
          title: 'Do you want to continue ?',
          text: 'Start row: ' + startRow + ', End row: ' + endRow,
          okBtnName: 'OK',
          closeBtnName: 'Cancel',
          isCommentVisible: false
        };
        this.spinnerVisible = false;
        let modalRef = this.modalService.show(ConfirmationBoxComponent, {
          class: 'modal-md',
          initialState,
          ignoreBackdropClick: true
        });
        modalRef.content.event.subscribe(res => {
          if (res?.result?.action == 'cancel') {
            this.spinnerVisible = false;
            return;
          }
          else {
            this.spinnerVisible = true;
            let needToAskFinalDesignation_DESource = false;
            let needToAskFinalDesignation_ENSource = false;
            let needToAskFinalSupplierArticleIDSource = false;
            let needToAskFinalSupplierIDSource = false;
            let needToAskFinalSystemCodeSource = false;
            let needToAskFinalSystemNumberSource = false;
            let needToAskFinalWeightSource = false;

            for (let obj of data) {
              if (obj && obj['stadlerArticleIdExists']) {
                let masterDesignation_DE = obj['masterDesignation_DE']; let designation_DE = obj['designation_DE'];
                masterDesignation_DE = masterDesignation_DE?masterDesignation_DE.replace('null',''):'';
                designation_DE = designation_DE?designation_DE.replace('null',''):'';

                let masterDesignation_EN = obj['masterDesignation_EN']; let designation_EN = obj['designation_EN'];
                masterDesignation_EN = masterDesignation_EN?masterDesignation_EN.replace('null',''):'';
                designation_EN = designation_EN?designation_EN.replace('null',''):'';

                let masterSupplierArticleID = obj['masterSupplierArticleID']; let supplier_article_ID = obj['supplier_article_ID'];
                masterSupplierArticleID = masterSupplierArticleID?masterSupplierArticleID.replace('null',''):'';
                supplier_article_ID = supplier_article_ID?supplier_article_ID.replace('null',''):'';

                let masterSupplierID = obj['masterSupplierID']; let supplier_ID = obj['supplier_ID'];
                masterSupplierID = masterSupplierID?(""+masterSupplierID).replace('null',''):'';
                supplier_ID = supplier_ID?(""+supplier_ID).replace('null',''):'';

                let masterSystemCode = obj['masterSystemCode']; let systemCode = obj['systemCode'];
                masterSystemCode = masterSystemCode?masterSystemCode.replace('null',''):'';
                systemCode = systemCode?systemCode.replace('null',''):'';

                let masterSystemNumber = obj['masterSystemNumber']; let systemNumber = obj['systemNumber'];
                masterSystemNumber = masterSystemNumber?(""+masterSystemNumber).replace('null',''):'';
                systemNumber = systemNumber?(""+systemNumber).replace('null',''):'';

                let masterWeight = obj['masterWeight']; let weight = obj['weight'];
                masterWeight = masterWeight?(""+masterWeight).replace('null',''):'';
                weight = weight?(""+weight).replace('null',''):'';

                // if(masterDesignation_DE != designation_DE ) needToAskFinalDesignation_DESource = true;
                // if(masterDesignation_EN != designation_EN )  needToAskFinalDesignation_ENSource = true;
                // if(masterSupplierArticleID != supplier_article_ID )  needToAskFinalSupplierArticleIDSource = true;
                // if(masterSupplierID != supplier_ID )  needToAskFinalSupplierIDSource = true;
                // if(masterSystemCode != systemCode )  needToAskFinalSystemCodeSource = true;
                // if(masterSystemNumber != systemNumber )  needToAskFinalSystemNumberSource = true;
                // if(masterWeight != weight ) needToAskFinalWeightSource = true;

                if(masterDesignation_DE != designation_DE && masterDesignation_DE != '' && masterDesignation_DE != null &&  designation_DE != '' && designation_DE != null) needToAskFinalDesignation_DESource = true;
                if(masterDesignation_EN != designation_EN && masterDesignation_EN != '' && masterDesignation_EN != null &&  designation_EN != '' && designation_EN != null)  needToAskFinalDesignation_ENSource = true;
                if(masterSupplierArticleID != supplier_article_ID && masterSupplierArticleID != '' && masterSupplierArticleID != null &&  supplier_article_ID != '' && supplier_article_ID != null)  needToAskFinalSupplierArticleIDSource = true;
                if(masterSupplierID != supplier_ID && masterSupplierID != '' && masterSupplierID != null &&  supplier_ID != '' && supplier_ID != null)  needToAskFinalSupplierIDSource = true;
                if(masterSystemCode != systemCode && masterSystemCode != '' && masterSystemCode != null &&  systemCode != '' && systemCode != null)  needToAskFinalSystemCodeSource = true;
                if(masterSystemNumber != systemNumber && masterSystemNumber != '' && masterSystemNumber != null &&  systemNumber != '' && systemNumber != null)  needToAskFinalSystemNumberSource = true;
                if(masterWeight != weight && masterWeight != '' && masterWeight != null && weight != '' && weight != null) needToAskFinalWeightSource = true;
                // alert('aaa');
                // if (obj['masterDesignation_DE'] != obj['designation_DE']) needToAskFinalDesignation_DESource = true;
                // if (obj['masterDesignation_EN'] != obj['designation_EN']) needToAskFinalDesignation_ENSource = true;
                // if (obj['masterSupplierArticleID'] != obj['supplier_article_ID']) needToAskFinalSupplierArticleIDSource = true;
                // if (obj['masterSupplierID'] != obj['supplier_ID']) needToAskFinalSupplierIDSource = true;
                // if (obj['masterSystemCode'] != obj['systemCode']) needToAskFinalSystemCodeSource = true;
                // if (obj['masterSystemNumber'] != obj['systemNumber']) needToAskFinalSystemNumberSource = true;
                // if (obj['masterWeight'] != obj['weight']) needToAskFinalWeightSource = true;
              }
            }

            if (!needToAskFinalDesignation_DESource && !needToAskFinalDesignation_ENSource && !needToAskFinalSupplierArticleIDSource && !needToAskFinalSupplierIDSource && !needToAskFinalSystemCodeSource && !needToAskFinalSystemNumberSource && !needToAskFinalWeightSource) {
              this.spinnerText = "Importing Data";
              this.sparePartService.importSpareParts(data).subscribe((res) => {
                this.notificationService.success("Successfully Uploaded..", "Success");
                this.spinnerVisible = false;
                this.getAllSaprePArts();
              }, ((error) => {
                const initialState = {
                  title: 'Upload Unsuccessful..',
                  okBtnName: 'OK',
                  closeBtnName: null,
                  titleBackground: 'bg-danger',
                  isCommentVisible: false
                };
                this.modalService.show(ConfirmationBoxComponent, {
                  class: 'modal-md',
                  initialState,
                  ignoreBackdropClick: true
                });
                this.spinnerVisible = false;
              }))
              return;
            }

            const initialState = {
              data: data,
              finalDesignation_DEEnable: needToAskFinalDesignation_DESource,
              finalDesignation_ENEnable: needToAskFinalDesignation_ENSource,
              finalSupplierArticleIDEnable: needToAskFinalSupplierArticleIDSource,
              finalSupplierIDEnable: needToAskFinalSupplierIDSource,
              finalSystemCodeEnable: needToAskFinalSystemCodeSource,
              finalSystemNumberEnable: needToAskFinalSystemNumberSource,
              finalWeightEnable: needToAskFinalWeightSource
            };

            let modalRef = this.modalService.show(PartsImportDataSourcePromptBoxComponent, {
              class: 'modal-xl',
              initialState,
              ignoreBackdropClick: true
            });
            modalRef.content.event.subscribe(res => {
              if (res && res?.action == 'ok' && res?.result) {
                let finalData = res?.result;

                this.spinnerText = "Importing Data";
                this.sparePartService.importSpareParts(finalData).subscribe((res) => {
                  this.notificationService.success("Successfully Uploaded..", "Success");
                  this.spinnerVisible = false;
                  this.getAllSaprePArts();
                }, ((error) => {
                  const initialState = {
                    title: 'Upload Unsuccessful..',
                    okBtnName: 'OK',
                    closeBtnName: null,
                    titleBackground: 'bg-danger',
                    isCommentVisible: false
                  };
                  this.modalService.show(ConfirmationBoxComponent, {
                    class: 'modal-md',
                    initialState,
                    ignoreBackdropClick: true
                  });
                  this.spinnerVisible = false;
                }))
              } else {
                this.spinnerVisible = false;
                return;
              }
            });
          }
        })
      }
    }, ((error) => {
      if (error.error?.exceptionOccurred) {
        const initialState = {
          title: error.error.message,
          okBtnName: 'OK',
          closeBtnName: null,
          titleBackground: 'bg-danger',
          isCommentVisible: false
        };
        this.modalService.show(ConfirmationBoxComponent, {class: 'modal-md', initialState, ignoreBackdropClick: true});
      } else {
        const initialState = {
          title: 'Upload Failed',
          okBtnName: 'OK',
          closeBtnName: null,
          titleBackground: 'bg-danger',
          isCommentVisible: false
        };
        this.modalService.show(ConfirmationBoxComponent, {class: 'modal-md', initialState, ignoreBackdropClick: true});
      }
      this.spinnerVisible = false;
      return;
    }));
  }

  public openFileSelectDialog() {
    if (!this.fileInput || !this.fileInput.nativeElement) return;

    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  public openFileSelectDialogArticleChange() {
    if (!this.fileInputArticleChange || !this.fileInputArticleChange.nativeElement) return;

    this.fileInputArticleChange.nativeElement.value = null;
    this.fileInputArticleChange.nativeElement.click();
  }

  public onChangeFileInputArticleChange(){
    console.log("Stadler Article Change Process Started...");
    if (!this.fileInputArticleChange || !this.fileInputArticleChange.nativeElement || !this.fileInputArticleChange.nativeElement.files) return;

    const files: { [key: string]: File } = this.fileInputArticleChange.nativeElement.files;
    this.selectedFileArticleChange = files[0];

    if (!this.selectedFileArticleChange) return;

    this.spinnerText = "Uploading and Validating Data";
    this.spinnerVisible = true;

    let formDataArticleChange = new FormData();
    formDataArticleChange.append('file', this.selectedFileArticleChange);

    this.sparePartService.uploadSparePartsArticleChange(formDataArticleChange).subscribe((res: any) => {
      if (!res) {
        this.spinnerVisible = false;
        return;
      } else {
        let data: any[] = res.data;
        let startRow : any = res.startRowNumber;
        let endRow : any = res.endRowNumber;

        // console.log(data);
        const initialState = {
          title: 'Do you want to continue with the Stadler Article ID change process?',
          text: 'Start row: ' + startRow + ', End row: ' + endRow,
          okBtnName: 'OK',
          closeBtnName: 'Cancel',
          isCommentVisible: false
        };
        this.spinnerVisible = false;
        let modalRef = this.modalService.show(ConfirmationBoxComponent, {
          class: 'modal-md',
          initialState,
          ignoreBackdropClick: true
        });
        modalRef.content.event.subscribe(res => {
          if (res?.result?.action == 'cancel') {
            this.spinnerVisible = false;
            return;
          }
          else {
            //Processing the Stadler Article ID changes 
            this.spinnerVisible = true;

            this.spinnerText = "Importing Data";
            this.sparePartService.importSparePartsArticleChange(data).subscribe((res) => {
              console.log(res);
              if(res && res["exceptionOccurred"] == true){
                this.notificationService.error(res["message"], "Error Occurred");
                const initialState = {
                  title: 'Error occurred',
                  text: '<p>'+ res["message"] + '</p>',
                  buttonText: 'Cancel',
                  tableContent: res["data"]
                };
                let modalRef = this.modalService.show(CommonErrorModalComponent, {
                  class: 'modal-lg',
                  initialState,
                  ignoreBackdropClick: true
                });
                modalRef.content.event.subscribe(res => {
                  if (res?.result?.action == 'cancel') {
                    this.spinnerVisible = false;
                    return;
                  }
                  else {}
                });
              }else{
                this.spinnerVisible = false;
                this.notificationService.success("Successfully Updated the Stadler Article IDs.", "Success");
              }
              
              
            }, ((error) => {}), () => {
              this.spinnerVisible = false;
              this.getAllSaprePArts();
            });

            this.spinnerVisible = false;
          }
        }, ((error) => {
          this.spinnerVisible = false;
        }), () => {
          this.spinnerVisible = false;
        });
      }
    }, ((error) => {
      this.notificationService.error("Error occurred...", "Uploading Error");
      this.spinnerVisible = false;
    }), () => {
      this.spinnerVisible = false;
    });
    
    
  }


}
