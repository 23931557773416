import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TreeSpeciesService } from 'src/app/services/treespecies.service';


@Component({
  selector: 'app-tree-species-add',
  templateUrl: './tree-species-add.component.html',
  styleUrls: ['./tree-species-add.component.scss']
})
export class TreeSpeciesAddComponent {

  roleData:any[] = [];
  userGroupData:any[] = [];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  treeSpeciesAddFrom = new UntypedFormGroup({
    speciesCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    description: new UntypedFormControl('',[Validators.required, Validators.maxLength(200)]),
    botanicalName: new UntypedFormControl('',[Validators.maxLength(200)]),
    price: new UntypedFormControl('',[ Validators.max(999999)]),
    distance: new UntypedFormControl('',[ Validators.max(999999)]),
  });
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private treeSpeciesService: TreeSpeciesService
  ) { 
    

  }

  ngOnInit(): void {
    this.treeSpeciesAddFrom.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }


  createNewTreeSpecies(){
    let formData = this.treeSpeciesAddFrom.getRawValue();
    this.loadingBtn = true;
    this.treeSpeciesService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Tree Species has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Tree Species has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Tree Species already exist', 'Error');
      }
      else {
        this.notification.error('Tree Species has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }


}
