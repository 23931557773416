<div class="modal-header">
  <h5 class="modal-title pull-left">Edit Project Systems</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="projectSystemEditForm" class="p-4">
  <div class="modal-body" >
      <div class="col-md-12">
        <div class="row form-group">
          <label class="col-sm-4 col-form-label col-form-label-sm"> Status</label>
          <div class="col-sm-8">
            <ngx-select [disabled]="ifBaseSystem" optionValueField="id" optionTextField="value" [items]="isprojectSystemActivatedList" formControlName="isActive" (select)="systemActiveStateChanged($event)" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'status'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Project Number</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" readonly formControlName="projectNumber" placeholder="Project Number" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'projectNumber'">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">System Name</label>
          <div class="col-sm-8">
            <input type="text" formControlName="systemDesignation" readonly class="form-control " placeholder="System Designation" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'systemDesignation'">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label col-form-label-sm">SBS Code</label>
          <div class="col-sm-8">
            <ngx-select [items]="sbsCodeData" placeholder="Select SBS Code" optionValueField="sbsCode" optionTextField="codeAndDesignation" formControlName="sbsCode" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'sbsCode'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Stadler Article ID</label>
          <div class="col-sm-8">
            <ngx-select class="stadler-article-select" formControlName="stadlerArticleId" (select)="getStadlerArticleById()" optionValueField="stadlerArticleId" optionTextField="designation" [items]="stadlerArticleData" [placeholder]="'Stadler article Id'" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'stadlerArticleId'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">System Variation</label>
          <div class="col-sm-8">
            <input type="text" formControlName="systemVariation" class="form-control " placeholder="System Variation" appMandatoryField appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'systemVariation'">
            <div *ngIf="projectSystemEditForm.controls['systemVariation'].invalid && (projectSystemEditForm.controls['systemVariation'].dirty || projectSystemEditForm.controls['systemVariation'].touched)" class="ng-validator">
              <div *ngIf="projectSystemEditForm.controls['systemVariation'].errors.required">
                This field is required.
              </div>
              <div *ngIf="projectSystemEditForm.controls['systemVariation'].errors.maxlength">
                Should be Less Than 100 Characters.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">System Variation Number</label>
          <div class="col-sm-8">
            <input type="text" formControlName="systemVariationNumber" readonly class="form-control " placeholder="System Designation" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'systemVariationNumber'">
          </div>
        </div>
        <div class="form-group row" [hidden]="true">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Specific Designation</label>
          <div class="col-sm-8">
            <input type="text" formControlName="specificDesignation"  class="form-control " placeholder="Specific Designation" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'specificDesignation'">
          </div>
        </div>

        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Mounting Location</label>
          <div class="col-sm-8">
           <ngx-select [items]="mountingLocations" autoClearSearch = "true" [multiple]="true" formControlName="mountingLocationId" optionValueField="mountingLocationId" optionTextField="designationDe" [placeholder]="'Mounting Location'" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'mountingLocationId'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">System Quantity</label>
          <div class="col-sm-8">
            <input type="text" formControlName="systemQuantity" class="form-control " [appThousandSeparator]="projectSystemEditForm.controls['systemQuantity'].value" placeholder="System Quantity" [appDecimal]="0" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'systemQuantity'">
            <div *ngIf="projectSystemEditForm.controls['systemQuantity'].invalid && (projectSystemEditForm.controls['systemQuantity'].dirty || projectSystemEditForm.controls['systemQuantity'].touched)" class="ng-validator">
              <div *ngIf="projectSystemEditForm.controls['systemQuantity'].value != '' && projectSystemEditForm.controls['systemQuantity'].errors.valueZero">
                Should be Greater Than 0
              </div>
              <div *ngIf="projectSystemEditForm.controls['systemQuantity'].errors.max">
                Should be Less Than 999
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">System Supplier</label>
          <div class="col-sm-8">
           <ngx-select formControlName="systemSupplier" optionValueField="stakeholderId" optionTextField="nameUsed" [items]="suppliers" [placeholder]="'System Supplier'" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'systemSupplier'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">RAM Engineer</label>
          <div class="col-sm-8">
            <ngx-select [items]="ramEngineerList" [multiple]="true" formControlName="ramengineers" optionValueField="stakeholderId" optionTextField="fullName" [placeholder]="'RAM Engineer'" [autoClearSearch]="true" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'ramengineers'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Tag</label>
          <div class="col-sm-8">
            <ngx-select [items]="systemTagList" [multiple]="true" formControlName="systemTags" optionValueField="tagId" optionTextField="tagText" [placeholder]="'System Tags'" [autoClearSearch]="true" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'systemTags'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Comment</label>
          <div class="col-sm-8">
            <textarea formControlName="systemComment"  class="form-control " placeholder="Comment" appInfoPromptBox [page]="'projectSystem'" [component]="'edit'" [field]="'systemComment'"></textarea>
            <div *ngIf="projectSystemEditForm.controls['systemComment'].invalid && (projectSystemEditForm.controls['systemComment'].dirty || projectSystemEditForm.controls['systemComment'].touched)" class="ng-validator">
              <div *ngIf="projectSystemEditForm.controls['systemComment'].errors.maxlength">
                Should be Less Than 1000 Characters.
              </div>
            </div>
          </div>
        </div>
        
        <!-- <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">System Engineering Contact</label>
          <div class="col-sm-8">

            <ngx-select [items]="systemEngineerList" formControlName="systemEngineeringContact" optionValueField="stakeholderId" optionTextField="nameUsed" [placeholder]="'System Engineer'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Machanical Engineering Contact</label>
          <div class="col-sm-8">

            <ngx-select [items]="machanicalEngineerList" formControlName="mechanicalEngineeringContact" optionValueField="stakeholderId" optionTextField="nameUsed" [placeholder]="'Machanical Engineer'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Electrical Engineering Contact</label>
          <div class="col-sm-8">

            <ngx-select [items]="electricalEngineerList" formControlName="electricalEngineeringContact" optionValueField="stakeholderId" optionTextField="nameUsed" [placeholder]="'Electrical Engineer'"></ngx-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">RAM Engineering Contact</label>
          <div class="col-sm-8">

            <ngx-select [items]="ramEngineerList" formControlName="ramEngineeringContact" optionValueField="stakeholderId" optionTextField="nameUsed" [placeholder]="'RAM Engineer'"></ngx-select>
          </div>
        </div> -->
    </div>

   

  </div>

  <div class="modal-footer">
    <div *ngIf="stadlerArticleChangedWarning" class="stadlerArticleIdChangedWarning alert-warning">
      after saving, check whether project system parts configuration has to be adjusted
    </div>
    <app-action-comment [btnLoading]="laddLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [noChangesFound]="initialValues == currentFormValues()" [saveDisable]="(mountingLocationsDataLoading || suppliersDataLoading || projectSystemsDataLoading || systemEngineersDataLoading || mechanicalEngineersDataLoading || electricalEngineersDataLoading || sbsCodesDataLoading || ramEngineersDataLoading || systemTagsDataLoading || stadlerArticleDataLoading) || projectSystemEditForm.invalid == true" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="updateProjectSystem($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>

  <div *ngIf="(mountingLocationsDataLoading ||  suppliersDataLoading ||  projectSystemsDataLoading ||  systemEngineersDataLoading ||  mechanicalEngineersDataLoading ||  electricalEngineersDataLoading ||  sbsCodesDataLoading ||  ramEngineersDataLoading ||  systemTagsDataLoading ||  stadlerArticleDataLoading || projectSystemSparepartsLoading || childPartStadlerIdsLoading || stalderArticleItemsListLoading)" class="spinner-div d-flex justify-content-center align-items-center" >
    <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner" ></mat-progress-spinner>
    <!-- <br/> -->
    <!-- <div class="spinner-text">Loading Project System Data...</div> -->
  </div>

</form>

