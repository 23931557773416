<nav
  *ngIf="auth"
  class="navbar fixed-top navbar-expand-lg navbar-dark justify-content-between"
>
  <a class="navbar-brand" routerLink="/projects">
    <img class="rambase-logo" src="assets/img/ecoforest-logo-only.png" />
    EcoForest-360
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <!-- <li class="nav-item ">
      <a class="nav-link" routerLink="" routerLinkActive="active">Home <span class="sr-only">(current)</span></a>
    </li> -->
      <li class="nav-item dropdown" style="float: right">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Corporate
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/parent-company"
            routerLinkActive="active"
            >Parent Company</a
          >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/company"
            routerLinkActive="active"
            >Company</a
          >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/estate"
            routerLinkActive="active"
            >Estate</a
          >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/division"
            routerLinkActive="active"
            >Division</a
          >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/field"
            routerLinkActive="active"
            >Field</a
          >
        </div>
      </li>
      <li class="nav-item dropdown" style="float: right">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Mature Stock
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/tree-details"
            routerLinkActive="active"
            >Tree Details</a
          >
        </div>
      </li>
      <li class="nav-item dropdown" style="float: right">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Immature Stock
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/nursery"
            routerLinkActive="active"
            >Nursery</a
          >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/immature-fields"
            routerLinkActive="active"
            >Immature Fields</a
          >
        </div>
      </li>
      <li class="nav-item dropdown" style="float: right">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Reference Data
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/tree-spices"
            routerLinkActive="active"
            >Tree Spices</a
          >
        </div>
      </li>
      <li class="nav-item dropdown" style="float: right">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Other
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/users"
            routerLinkActive="active"
            >Users</a
          >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/authorization"
            routerLinkActive="active"
            >Authorization</a
          >
          <a
            class="nav-link text-dark"
            (click)="breadcrumbService.resetStack()"
            routerLink="/system-logs"
            routerLinkActive="active"
            >System Logs</a
          >
        </div>
      </li>
      <li class="nav-item dropdown" style="float: right">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ user.firstName }} {{ user.lastName }}
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="dropdown-item"
            (click)="breadcrumbService.resetStack()"
            routerLink="profile"
            ><i class="fa fa-user"></i> Profile</a
          >
          <div class="dropdown-divider"></div>
          <button
            type="button"
            (click)="logoutUser()"
            class="dropdown-item btn-link"
          >
            <i class="fa fa-sign-out"></i> Logout
          </button>
        </div>
      </li>
    </ul>
  </div>
</nav>

<div
  appRouteChange
  class="container mainContainer"
  [@routeAnimation]="getAnimationData(routerOutlet)"
>
  <router-outlet #routerOutlet="outlet"></router-outlet>
</div>

<footer>
  <div class="footer-text-left pl-2">
    Version : {{ versionNumber }} Build {{ buildNumber }}
  </div>
  <!-- <img class=" stadler-train " src="assets/img/train-lineart-white.svg " /> -->
  <div class="footer-text">
    EcoForest-360 by Approbus
    <!-- <img class="footer-rambase-logo" src="assets/img/stadler_logo_weiss_rgb.png"/> -->
  </div>
</footer>
<div *ngIf="spinnerService.showSpinner$ | async" class="global-spinner">
  <!-- Use CSS to position the spinner icon -->
  <div class="bounce1"></div>
  <div class="bounce2"></div>
  <div class="bounce3"></div>
</div>
