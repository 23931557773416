<div class="modal-header">
  <h5 class="modal-title pull-left">Edit Parts</h5>
  <div>
    <ngx-select style="padding-left: 25px; width: 200px; display: inline-block; float: right !important;" optionValueField="id" optionTextField="str" [items]="languages" [(ngModel)]="selectedLanguage" (select)="languageChanged()"></ngx-select>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class="pr-4 pl-4" [formGroup]="projectSystemSparePartEditForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-7">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm" style="padding-right: 0px">Parent Stadler Article ID </label>
          <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="parentStadlerArticleId" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'parentStadlerArticleId'">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm">Part Stadler Article ID</label>
          <div class="col-sm-9">
            <ngx-select optionValueField="stadlerArticleId" (select)="stadlerArticleIdChanged()" optionTextField="stadlerArticleText" [items]="stadlerArticleData" [placeholder]="'Select Stadler Article Id'" formControlName="spareStadlerArticleId" appMandatoryField appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'spareStadlerArticleId'"></ngx-select>
            <div *ngIf="projectSystemSparePartEditForm.controls['spareStadlerArticleId'].invalid && (projectSystemSparePartEditForm.controls['spareStadlerArticleId'].dirty || projectSystemSparePartEditForm.controls['spareStadlerArticleId'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartEditForm.controls['spareStadlerArticleId'].errors.required">
                This field is required.
              </div>
            </div>
            <div *ngIf="showStadlerArticalIdWarning" class="alert-warning" style="white-space: normal;">
              Consider the that the children will get a new parent and if the article is selected in a task it will be replaced there as well
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm">Quantity</label>
          <div class="col-sm-4">
            <input [required]="projectSystemSparePartEditForm.controls['spareUnit'].value && !projectSystemSparePartEditForm.controls['quantity'].value" type="text" [appThousandSeparator]="projectSystemSparePartEditForm.controls['quantity'].value" class="form-control" formControlName="quantity" placeholder="Quantity" [appDecimal]="materialQuantityDecimalLength" appMandatoryField  appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'quantity'">
            <div *ngIf="projectSystemSparePartEditForm.controls['quantity'].errors?.required" class="ng-validator">
              This field is required.
            </div>
            <div *ngIf="projectSystemSparePartEditForm.controls['quantity'].invalid && (projectSystemSparePartEditForm.controls['quantity'].dirty || projectSystemSparePartEditForm.controls['quantity'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartEditForm.controls['quantity'].value != '' && projectSystemSparePartEditForm.controls['quantity'].errors.valueZero">
                Should be Greater Than 0
              </div>
              <div *ngIf="projectSystemSparePartEditForm.controls['quantity'].errors.max">
                Should be Less Than 999999.99
              </div>
              <div *ngIf="projectSystemSparePartEditForm.controls['quantity'].errors.min">
                Cannot be Smaller Than Biggest Task Part Quantity
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <ngx-select optionValueField="quantityUnitId" optionTextField="designationDe" [items]="spareUnitList" [placeholder]="'Select Parts Unit'" formControlName="spareUnit" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'spareUnit'"></ngx-select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm">Price</label>
          <div class="col-sm-4">
            <input [required]="projectSystemSparePartEditForm.controls['currency'].value && !projectSystemSparePartEditForm.controls['unitCost'].value" type="text" [appThousandSeparator]="projectSystemSparePartEditForm.controls['unitCost'].value" class="form-control" formControlName="unitCost" placeholder="Value" [appDecimal]="2" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'unitCost'">
            <div *ngIf="projectSystemSparePartEditForm.controls['unitCost'].errors?.required" class="ng-validator">
              This field is required.
            </div>
            <div *ngIf="projectSystemSparePartEditForm.controls['unitCost'].invalid && (projectSystemSparePartEditForm.controls['unitCost'].dirty || projectSystemSparePartEditForm.controls['unitCost'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartEditForm.controls['unitCost'].value != '' && projectSystemSparePartEditForm.controls['unitCost'].errors.valueZero">
                Should be Greater Than 0
              </div>
              <div *ngIf="projectSystemSparePartEditForm.controls['unitCost'].errors.max">
                Should be Less Than 999999.99
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <ngx-select [required]="projectSystemSparePartEditForm.controls['unitCost'].value > 0 && !projectSystemSparePartEditForm.controls['currency'].value" [allowClear]="true" formControlName="currency" placeholder="Currency" optionValueField="code" optionTextField="code" [items]="currencyList" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'currency'"></ngx-select>
            <div *ngIf="projectSystemSparePartEditForm.controls['currency'].errors?.required" class="ng-validator">
              This field is required.
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm">Failure Rate</label>
          <div class="col-sm-4">
            <input [required]="projectSystemSparePartEditForm.controls['failureRateUnit'].value && !projectSystemSparePartEditForm.controls['failureRate'].value" [appThousandSeparator]="projectSystemSparePartEditForm.controls['failureRate'].value" type="text" class="form-control" formControlName="failureRate" placeholder="Value" [appDecimal]="5" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'failureRate'">
            <div *ngIf="projectSystemSparePartEditForm.controls['failureRate'].errors?.required" class="ng-validator">
              This field is required.
            </div>
            <div *ngIf="projectSystemSparePartEditForm.controls['failureRate'].invalid && (projectSystemSparePartEditForm.controls['failureRate'].dirty || projectSystemSparePartEditForm.controls['failureRate'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartEditForm.controls['failureRate'].value != '' && projectSystemSparePartEditForm.controls['failureRate'].errors.valueZero">
                Should be Greater Than 0
              </div>
              <div *ngIf="projectSystemSparePartEditForm.controls['failureRate'].errors.max">
                Should be Less Than 999,999.99999
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <ngx-select [required]="projectSystemSparePartEditForm.controls['failureRate'].value > 0 && !projectSystemSparePartEditForm.controls['failureRateUnit'].value" [allowClear]="true" formControlName="failureRateUnit" placeholder="Unit" optionValueField="failureRateUnitId" optionTextField="designationDe" [items]="failureRateUnitsData" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'failureRateUnit'"></ngx-select>
            <div *ngIf="projectSystemSparePartEditForm.controls['failureRateUnit'].errors?.required" class="ng-validator">
              This field is required.
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm">Life Span</label>
          <div class="col-sm-4">
            <input [required]="projectSystemSparePartEditForm.controls['lifeSpanUnit'].value && !projectSystemSparePartEditForm.controls['lifeSpan'].value" [appThousandSeparator]="projectSystemSparePartEditForm.controls['lifeSpan'].value" type="text" class="form-control" formControlName="lifeSpan" placeholder="Value" [appDecimal]="1" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'lifeSpan'">
            <div *ngIf="projectSystemSparePartEditForm.controls['lifeSpan'].errors?.required" class="ng-validator">
              This field is required.
            </div>
            <div *ngIf="projectSystemSparePartEditForm.controls['lifeSpan'].invalid && (projectSystemSparePartEditForm.controls['lifeSpan'].dirty || projectSystemSparePartEditForm.controls['lifeSpan'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartEditForm.controls['lifeSpan'].value != '' && projectSystemSparePartEditForm.controls['lifeSpan'].errors.valueZero">
                Should be Greater Than 0
              </div>
              <div *ngIf="projectSystemSparePartEditForm.controls['lifeSpan'].errors.max">
                Should be Less Than 99999999
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <ngx-select [required]="projectSystemSparePartEditForm.controls['lifeSpan'].value > 0 && !projectSystemSparePartEditForm.controls['lifeSpanUnit'].value" [allowClear]="true" formControlName="lifeSpanUnit" placeholder="Unit" optionValueField="lifeSpanUnitId" optionTextField="designationDe" [items]="lifeSpanUnitsData" appMandatoryField [conditional]="true" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'lifeSpanUnit'"></ngx-select>
            <div *ngIf="projectSystemSparePartEditForm.controls['lifeSpanUnit'].errors?.required" class="ng-validator">
              This field is required.
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm">Additional Designation</label>
          <div class="col-sm-9">
            <input type="text" class="form-control" formControlName="additionalDesignation" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'additionalDesignation'">
            <div *ngIf="projectSystemSparePartEditForm.controls['additionalDesignation'].invalid && (projectSystemSparePartEditForm.controls['additionalDesignation'].dirty || projectSystemSparePartEditForm.controls['additionalDesignation'].touched)" class="ng-validator">
              <div *ngIf="projectSystemSparePartEditForm.controls['additionalDesignation'].errors.maxlength">
                Maximum length is 100
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm">Is Replaceable?</label>
          <div class="col-sm-9">
            <input [disabled]="partAutogenCMTask!=null && partAutogenCMTask!='' && partAutogenCMTaskisActive" style="height:15px;width : 15px;" type="checkbox" class="form-control" formControlName="isReplaceable" appInfoPromptBox [page]="'projectSystemParts'" [component]="'edit'" [field]="'isReplaceable'">
            <div *ngIf="partAutogenCMTask!=null && partAutogenCMTask!='' && partAutogenCMTaskisActive">
              Auto generated CM task is 'Active' OR 'to be deleted in ST4' status. [Task ID : {{partAutogenCMTask}}]
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="data-table">
          <app-tree-table [settings]="taskPartUsageSettings" [data]="taskPartUsageTableData"></app-tree-table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-action-comment [btnLoading]="btnLoading" [okBtnName]="'Save'" [closeBtnName]="'Close'" [noChangesFound]="initialValues == currentFormValues()" 
     [saveDisable]="(spareUnitsLoading ||   failureRateUnitsLoading ||   lifeSpanUnitsLoading ||   stadlerArticleDataLoading ||   taskPartsUsagesLoading) || projectSystemSparePartEditForm.invalid == true" [mandatoryDescriptionVisible]="true" [commentVisible]="projectFreezeFunction == 'activated'" (okClick)="updateProjectSystemSpareParts($event)" (cancelClick)="bsModalRef.hide()"></app-action-comment>
  </div>
</form>
