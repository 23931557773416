import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtherRoutingModule } from './other-routing.module';
import { UsersComponent } from './users/users.component';
import { AuthorizationComponent } from './authorization/authorization.component';
import { SystemLogsComponent } from './system-logs/system-logs.component';


@NgModule({
  declarations: [
    UsersComponent,
    AuthorizationComponent,
    SystemLogsComponent
  ],
  imports: [
    CommonModule,
    OtherRoutingModule
  ]
})
export class OtherModule { }
