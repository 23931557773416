<div class="modal-header">
  <h5 class="modal-title pull-left">Add Tree Species</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form class="p-3" [formGroup]="treeSpeciesEditFrom" (ngSubmit)="updateProjectSystem(event)">
  <div class="modal-body">

    <div class="form-group row">
      <label for="speciesCode" class="col-sm-4 col-form-label col-form-label-sm">Species Code</label>
      <div class="col-sm-8">
        <input type="text" appMandatoryField maxlength="10" class="form-control " formControlName="speciesCode" placeholder="Species Code" appInfoPromptBox [page]="'tree-species'" [component]="'add'" [field]="'speciesCode'"/>
        <div *ngIf="treeSpeciesEditFrom.controls['speciesCode'].invalid && (treeSpeciesEditFrom.controls['speciesCode'].dirty || treeSpeciesEditFrom.controls['speciesCode'].touched)" class="ng-validator">
          <div *ngIf="treeSpeciesEditFrom.controls['speciesCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-4 col-form-label col-form-label-sm">Description</label>
      <div class="col-sm-8">
        <input type="text" appMandatoryField maxlength="200" class="form-control " formControlName="description" placeholder="Description" appInfoPromptBox [page]="'tree-species'" [component]="'add'" [field]="'description'"/>
        <div *ngIf="treeSpeciesEditFrom.controls['description'].invalid && (treeSpeciesEditFrom.controls['description'].dirty || treeSpeciesEditFrom.controls['description'].touched)" class="ng-validator">
          <div *ngIf="treeSpeciesEditFrom.controls['description'].errors.required">
            This field is required.
          </div>
          <div *ngIf="treeSpeciesEditFrom.controls['description'].errors.maxlength">
            Maximum length would be 200 characters.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inputPassword3" class="col-sm-4 col-form-label col-form-label-sm">Botanical Name</label>
      <div class="col-sm-8">
        <input type="text" maxlength="200" class="form-control " formControlName="botanicalName" placeholder="Botanical Name" appInfoPromptBox [page]="'tree-species'" [component]="'add'" [field]="'botanicalName'"/>
        <div *ngIf="treeSpeciesEditFrom.controls['botanicalName'].invalid && (treeSpeciesEditFrom.controls['botanicalName'].dirty || treeSpeciesEditFrom.controls['botanicalName'].touched)" class="ng-validator">
          <div *ngIf="treeSpeciesEditFrom.controls['botanicalName'].errors.maxlength">
            Maximum length would be 200 characters.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Indicative Price</label>
      <div class="col-sm-8">
        <input type="text" maxlength="9" max="999999.99" class="form-control "  [appDecimal]="2"  formControlName="price" placeholder="price" appInfoPromptBox [page]="'tree-species'" [component]="'add'" [field]="'price'"/>
        <div *ngIf="treeSpeciesEditFrom.controls['price'].invalid && (treeSpeciesEditFrom.controls['price'].dirty || treeSpeciesEditFrom.controls['price'].touched)" class="ng-validator">
          <div *ngIf="treeSpeciesEditFrom.controls['price'].errors.max">
            Maximum value would be 999999.99
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-4 col-form-label col-form-label-sm">Typical Distance between Trees</label>
      <div class="col-sm-8">
        <input type="text" maxlength="9" max="999999.99" class="form-control "  [appDecimal]="2" formControlName="distance" placeholder="distance" appInfoPromptBox [page]="'tree-species'" [component]="'add'" [field]="'distance'"/>
        <div *ngIf="treeSpeciesEditFrom.controls['distance'].invalid && (treeSpeciesEditFrom.controls['distance'].dirty || treeSpeciesEditFrom.controls['distance'].touched)" class="ng-validator">
          <div *ngIf="treeSpeciesEditFrom.controls['distance'].errors.max">
            Maximum value would be 999999.99
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="modal-footer">
    <button type="submit" appButtonLoader [isLoading]="loadingBtn" [disabled]="disableButton" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
