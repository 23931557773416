import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TreeSpeciesService } from 'src/app/services/treespecies.service';
import { validateForm } from 'src/app/shared/helper';


@Component({
  selector: 'app-tree-species-edit',
  templateUrl: './tree-species-edit.component.html',
  styleUrls: ['./tree-species-edit.component.scss']
})
export class TreeSpeciesEditComponent {

  roleData:any[] = [];
  userGroupData:any[] = [];
  initialState:any;
  param:any;

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;

  treeSpeciesEditFrom:any;

  constructor(
      public bsModalRef : BsModalRef,
      private notificationService : ToastrService,
      private treeSpeciesService: TreeSpeciesService
  ) { 
    

  }

  ngOnInit(): void {
    this.initFormValues();
  }


 initFormValues(){
    this.treeSpeciesEditFrom = new UntypedFormGroup(
        {
            speciesCode: new UntypedFormControl(this.param.speciesCode,[Validators.required, Validators.maxLength(10)]),
            description: new UntypedFormControl(this.param.description,[Validators.required, Validators.maxLength(200)]),
            botanicalName: new UntypedFormControl(this.param.botanicalName,[Validators.maxLength(200)]),
            price: new UntypedFormControl(this.param.price,[ Validators.max(999999)]),
            distance: new UntypedFormControl(this.param.distance,[ Validators.max(999999)])
        }
    );
      
    validateForm(this.treeSpeciesEditFrom);

  }

  updateProjectSystem(event) {
    if (this.treeSpeciesEditFrom.valid) {

      this.loadingBtn = true;
      let speciesCode = this.param.speciesCode;
      let submitData = this.treeSpeciesEditFrom.getRawValue();
      
      this.treeSpeciesService.update(speciesCode, submitData).subscribe(res => {
        if (res) {
          this.loadingBtn = false;
          this.notificationService.success('Tree Species has been updated', 'Success');
          this.bsModalRef.hide();
            this.event.emit();
        } else {
          this.loadingBtn = false;
          this.notificationService.error('Tree Species has not been updated', 'Error');
        }
        
      }, (error => {
        this.loadingBtn = false;
        this.notificationService.error('Tree Species has not been updated', 'Error');
        
      }), () => {
        this.loadingBtn = false;
      });
    } else {
      validateForm(this.treeSpeciesEditFrom);
    }
  }


}
