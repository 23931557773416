<div *ngIf="data" #outerDiv class="tree-outer-div">
    <div class="table-upper-div d-flex justify-content-end align-items-center">
        <button *ngIf="showResetFilters" (click)="resetFilters()" class="btn filter-reset-btn" type="button">
            Reset Filters
        </button>

        <button *ngIf="settings.type == 'tree'" (click)="expandAll()" class="btn filter-reset-btn" type="button">
            Expand All
        </button>

        <div class="column-edit-div" *ngIf="settings.columnManagerEnable">
            <button class="btn dropdown-toggle column-edit-drop-down-btn" type="button" data-toggle="dropdown">
                Manage Columns
            </button>
            <div class="dropdown-menu column-edit-dropdown-menu">
                <div *ngFor="let column of settings.columns; let i = index">
                    <div *ngIf="column.title" class="column-edit-drop-down-option" >
                        <input class="column-edit-option-checkbox" type="checkbox" [checked]="!column['isHidden']" value="option.value" (change)="columnEditChange($event.target['checked'], i)"> &nbsp;
                        <label class="form-check-label column-edit-option-label">
                            {{column.title}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-tree [settings]="settings" [data]="data" [zoomRatio]="zoomRatio" (filterChangeEvent)="changeFilter($event)"></app-tree>

    <div *ngIf="settings.pagination?.enabled && pageCount > 1" class="pagination-div d-flex justify-content-start">
        <div class="paginate-btn-div" (click)="gotoFirst()" [ngClass]="{'paginate-btn-div-disabled':currentPage == 1}">
            <span><<</span>
        </div>
        <div class="paginate-btn-div" (click)="gotoPrevious()" [ngClass]="{'paginate-btn-div-disabled':currentPage == 1}">
            <span><</span>
        </div>
        <div *ngFor="let n of getPaginationSequence(); let i = index;">
            <div class="paginate-btn-div" (click)="gotoPage(n)" [ngClass]="{'paginate-btn-div-selected':currentPage == n}">
                <span>{{n}}</span>
            </div>
        </div>
        <div class="paginate-btn-div page-next-btn" (click)="gotoNext()" [ngClass]="{'paginate-btn-div-disabled':currentPage == pageCount}">
            <span>></span>
        </div>
        <div class="paginate-btn-div" (click)="gotoLast()" [ngClass]="{'paginate-btn-div-disabled':currentPage == pageCount}">
            <span>>></span>
        </div>

        <div class="page-size-div d-flex justify-content-start">
            <input [(ngModel)]="pageSizeInputValue" class="page-size-input form-control" placeholder="Page Size">
            <button [disabled]="!pageSizeInputValue" (click)="changePageSize()" class="btn page-size-save-btn" type="button">
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="page-count-div" *ngIf="settings.pagination?.enabled && pageCount > 1">
        &nbsp;&nbsp; <span>Total Page Count:</span>&nbsp;<span class="page-count-span">{{pageCount}}</span>
    </div>

    <div *ngIf="settings.enableZoom" class="zoom-div d-flex justify-content-start">
        <label>Zoom: &nbsp;</label>
        <mat-slider min="0" max="1" [step]="0.01" [value]="1" (input)="zoomRatioChange($event)"></mat-slider>
    </div>
    <br>

    <div *ngIf="spinnerVisible" class="spinner-div d-flex justify-content-center align-items-center">
      <mat-progress-spinner [color]="'primary'" [mode]="'indeterminate'" class="spinner" [ngStyle]="{'top.px': spinnerPosition?.top, 'left.px': spinnerPosition?.left}"></mat-progress-spinner>
      <div class="spinner-text">{{spinnerText}}</div>
    </div>
</div>

