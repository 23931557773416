<div class="sidenav">
    <div class="login-main-text">
        <img class="login-rambase-logo" src="assets/img/approbus_logo_full_small.png" />
        <br />&nbsp;<br />
        <img class="login-rambase-logo" src="assets/img/ecoforest-logo-white-transparent.png" />
    </div>
</div>
<div class="main" style="background-color:white;">
    <div class="col-md-6 col-sm-12">
        <div class="row">
            <div class="login-form" style="margin-left:auto; margin-right:auto;">
                <div style="width:100%; margin-left: auto; margin-right: auto; text-align: center;">
    
                    <form [formGroup]="loginForm" (ngSubmit)="userLogin()">
    
                        <div class="welcome-text"><label>Welcome back! Please login to your account</label></div>
                        <div class="form-group">
                            <input [disabled]="mismatchedBuildBumber" style="margin-left: auto; margin-right: auto;" type="text" formControlName="username"
                                class="form-control col-6 loginFormTextBox loginFormTextBox-text user-name" value="Benedikt"
                                placeholder="Username" />
                        </div>
    
                        <div class="form-group">
                            <input [disabled]="mismatchedBuildBumber" style="margin-left: auto; margin-right: auto;" type="password" formControlName="password"
                                class="form-control col-6 loginFormTextBox loginFormTextBox-text password" value="aaa"
                                placeholder="Password" />
                        </div>
                        <button [disabled]="mismatchedBuildBumber" type="submit" class="btn col-md-2 col-sx-12 btn-primary">Login &nbsp;<i *ngIf="loading" class="fas fa-cog fa-spin spinning-cog"></i></button> &nbsp;&nbsp;
                        <div class="form-group mt-4">
                            <div *ngIf="!loginSuccess" class="col-md-6 alert alert-danger" id="loginErrorMessage">
                                {{loginMessage}}
                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <div class="col-sm-12 uiVersion-info">
                                Version : {{ versionNumber }} Build {{ buildNumber }}
                            </div>
                        </div>
                        <div *ngIf="mismatchedBuildBumber" class="col-sm-8" style="width:100%; margin-left: auto; margin-right: auto; text-align: center;">
                            <div class="">
                                <div>
                                    <div class="form-group mt-4 p-2 border border-primary" style="background-color:#e0ebf6">
                                        <p style="font-size: smaller;">
                                            <small>
                                                There is a new version available.<br>
                                            Your current version :{{versionNumber}} build {{buildNumber}} <br>
                                            <span>
                                                <strong>

                                                    New Version : {{uiVersion}} Build {{backendUiBuildNumber}}
                                                </strong>
                                            </span>
                                            </small>
        
                                        </p>
                                        <p class="col-sm-12" style="font-size: smaller; overflow-wrap: break-word;white-space: pre-wrap;">
                                            <small>
                                                Please clear cache and refresh the page by Pressing CTRL + SHIFT + R  to obtain the new version.<br/>
                                                If the message persists, Please refer the &quot;User Guide&quot; for instructions <br/>on &quot;How to clear cache and refresh&quot;
                                            </small>
                                        </p>
                                        <!-- <a style="font-size: smaller;" routerLink="/help/topics/howtorefresh">How to obtain the new version</a> -->
                                    </div>
                                
                                
                                </div>
                            </div>
                        
                        </div>
                    </form>
    
                </div>
    
            </div>
        </div>
        
    </div>

</div>

<div *ngIf="newVersionDetected" id="newVersionNotification" class="newVersionNotification">
    New version detected {{newVersionNumber}}&nbsp;&nbsp;(<span class="newVersionNotification-link"><a href="/?1234" >Click to load</a></span>)
</div>