import { Component, OnInit, Input, Output } from '@angular/core';
import { ProjectSystemTaskSparePartsComponent } from '../../project-system-task/project-system-task-spare-parts/project-system-task-spare-parts.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-system-task-spareparts-url',
  templateUrl: './project-system-task-spareparts-url.component.html',
  styleUrls: ['./project-system-task-spareparts-url.component.scss']
})
export class ProjectSystemTaskSparepartsUrlComponent implements OnInit {

  projectNumber:any;
  systemNumber:any = 0;
  taskDbId:any;
  taskName:any;
  isTaskActivated:boolean;
  taskElement:any;
  constructor( private modalService : BsModalService) { }

  @Input()
  rowData: any;
  ngOnInit(): void {
    this.projectNumber = this.rowData['projectNumber'];
    this.systemNumber = this.rowData['projectSystemId'];
    this.taskDbId = this.rowData['taskDbId'];
    this.taskName = this.rowData['taskName'];
    this.taskElement = this.rowData['taskElement'];
    this.isTaskActivated = this.rowData['isTaskActivated'] == 'A' ? true : false;
  }

  loadTaskSpareModel(){
    const initialState = {
      param : {
        projectNumber : this.projectNumber,
        systemNumber : this.systemNumber,
        taskDbId : this.taskDbId,
        taskName : this.taskName,
        taskElement : this.taskElement,
      }
    };
    this.modalService.show(ProjectSystemTaskSparePartsComponent, { class: 'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true });
  }

}
