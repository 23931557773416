import {AfterContentChecked, AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, UntypedFormBuilder, FormArray} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ProjectSystemTasksService} from 'src/app/services/project-system-tasks.service';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {
  taskNature,
  taskIsNonRecurring,
  taskMaintainanceLevel,
  taskInfluence,
  validateForm,
  currencyList,
  taskPPTypes,
  getTaskActivatedList,
  getStatusIdfromCode
} from 'src/app/shared/helper';
import {TaskTypeService} from 'src/app/services/task-type.service';
import {TaskSuffixService} from 'src/app/services/task-suffix.service';
import {EmployeeQualificationService} from 'src/app/services/employee-qualification.service';
import {EmployeeTypeService} from 'src/app/services/employee-type.service';
import {TaskSourceService} from 'src/app/services/task-source.service';
import {TaskStageService} from 'src/app/services/task-stage.service';
import {TargetManualService} from 'src/app/services/target-manual.service';
import {TaskSourceStandardService} from 'src/app/services/task-source-standard.service';
import {InfrastructureService} from 'src/app/services/infrastructure.service';
import {ProfileUnitsService} from 'src/app/services/profile-units.service';
import {TaskIntervalService} from 'src/app/services/task-interval.service';
import {ProjectSystemsService} from 'src/app/services/project-systems.service';
import {OperatingProfilesService} from 'src/app/services/operating-profiles.service';
import {LanguageSupportComponent} from "../../../../shared/components/language-support/language-support-component.service";
import {LanguageServiceService} from "../../../../services/language-service.service";
import {ProjectService} from "../../../../services/project.service";
import {ProjectSystemSparePartsService} from "../../../../services/project-system-spare-parts.service";
import { MountingLocationService } from 'src/app/services/mounting-location.service';
import { MaintenanceLocationService } from 'src/app/services/maintenance-location.service';
import {OverallSystemsAndTasksService} from "../../../../services/overall-systems-and-tasks.service";
import {FreezeFunctionCommentsDialogComponent} from "../../../../shared/components/freeze-function-comments-dialog/freeze-function-comments-dialog.component";

@Component({
  selector: 'app-project-system-task-edit',
  templateUrl: './project-system-task-edit.component.html',
  styleUrls: ['./project-system-task-edit.component.scss']
})
export class ProjectSystemTaskEditComponent extends LanguageSupportComponent implements OnInit {

  isTaskActivated:boolean = true;
  event: EventEmitter<any> = new EventEmitter();
  taskNatureData: any = taskNature();
  taskIsNonRecurringData: any = taskIsNonRecurring();
  taskMaintainanceLevelData: any = taskMaintainanceLevel();
  taskTypeData: any = [];
  qualificationData: any = [];
  projectSystemId: any;
  projectNumber: any;
  employeeTypeData: any = [];
  taskSuffixData: any = [];
  infrastructureData: any = [];
  taskSourceData: any = [];
  taskDBid: any;
  taskStagesData: any = [];
  targetManualData: any = [];
  taskStandardData: any = [];
  prePostProcessingTaskData:any = [];
  preProcessingTaskData:any = [];
  ppTypeData:any = taskPPTypes();
  selectedPPTasks:any = [];
  initialSelectedPPTasks:any=[];
  selectedPreTaskCount=0;
  currentSelectedPPTask:any = -1;
  invalidIntervals: Boolean = false;
  invalidMaintenanceLocation: Boolean = false;
  intervalMismatchA: Boolean = false;
  intervalMismatchB: Boolean = false;
  taskInfluenceData: any = taskInfluence();
  taskManualId: any;
  taskInfrastructureData: any = [];
  infrastructureArray: any = [];
  relatedCmTasks: any = [];
  currencyList: any = [];
  laddLoading = false;
  showTaskStageWarning:boolean = false;
  showMaintenanceLevelWarning:boolean = false;
  param: any;
  finalIntervalData: any = [];
  isTaskActivatedList: any = getTaskActivatedList();
  selectedCmRelatedTask: string = '';
  taskElementData:any = [];
  systemPartData:any = new Map();
  systemVariation:any;
  taskElementStr:any = "";
  taskActivity:any = "";
  showTaskActivityWarning:boolean = false;
  projectFreezeFunction = "";
  maintenanceLocationData: any = [];
  maintenanceLocationArray: any = [];
  taskTagList:any = [];
  isValidExecutionTime:boolean = false;
  isExecutionTimeEmpty:boolean = true;
  projectVehicleQuantity:any = 0;
  formValuesMap:any = new Map<string, {}>();
  taskTypeDataLoaded:boolean = false;
  taskSuffixDataLoaded:boolean = false;
  taskStagesDataLoaded:boolean = false;
  preProcessingTaskDataLoaded:boolean = false;
  qualificationDataLoaded:boolean = false;
  employeeTypeDataLoaded:boolean = false;
  infrastructureDataLoaded:boolean = false;
  taskInfrastructureDataLoaded:boolean = false;
  taskSourceDataLoaded:boolean = false;
  targetManualDataLoaded:boolean = false;
  taskTagListLoaded:boolean = false;
  relatedCmTasksLoaded:boolean = false;
  taskIntervalsLoaded:boolean = false;
  intervalsDataLoaded:boolean = false;
  selectedPPTasksLoaded:boolean = false;
  taskMaintenanceLocationsLoaded:boolean = false;
  maintenanceLocationsDataLoaded:boolean = false;
  taskElementDataLoaded:boolean = false;
  executionTimeError:any =[];
  validExecutionTime:Boolean=true;
  btnLoading:Boolean = false;
  isExecutionTimeSourceValid: boolean = false;
  taskStatusChanged: boolean = false;
  initialTaskStatus: Number;
  currentTaskStatus: Number;
  isSystemActive: Number = 0;
  isAutoGenerated: Boolean = false;
  maintenanceLevelChangingforFirstTime:Boolean = true;
  taskActivatedStatusID: Number;
  taskStatusChangedFromActivetoInactive:Boolean = false;
  isThisTaskRelatedtoOtherTasks:Boolean = false;
  initialActivationStatus:Number;
  relatedAsCMTasks:any[] = [];
  relatedTasksLoaded:Boolean = true;
  projectspecificEmployeeQualificationEnabled:Boolean = false;

  relatedAsPPTasks:any[] = [];

  projectSystemTaskEditForm = new UntypedFormGroup(
      {
        projectNumber: new UntypedFormControl({value:'', disabled:true}),
        systemNumber: new UntypedFormControl(''),
        taskName: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)]),
        duration: new UntypedFormControl(''),
        lionkedTaskNumber: new UntypedFormControl(''),
        taskNature: new UntypedFormControl({value:'', disabled:true}, [Validators.required]),
        isNonRecurring: new UntypedFormControl('', [Validators.required]),
        maintenanceLevel: new UntypedFormControl('', [Validators.required]),
        // executionTime: new UntypedFormControl('', [Validators.max(2147483647)]),
        executionTimeSource: new UntypedFormControl('', [Validators.maxLength(100)]),
        quantityWorkers: new UntypedFormControl(''),
        externalServiceDescription: new UntypedFormControl('', [Validators.maxLength(255)]),
        externalServiceCost: new UntypedFormControl('', Validators.max(999999.99)),
        externalServiceCurrency: new UntypedFormControl(''),
        taskTypeId: new UntypedFormControl('', [Validators.required]),
        tskMaintenanceLocation: new UntypedFormControl('', [Validators.required]),
        projectSystemId: new UntypedFormControl(''),
        taskId: new UntypedFormControl({value:'', disabled:true}),
        taskElement: new UntypedFormControl('systemVariationName', [Validators.required]),
        individualTaskElement: new UntypedFormControl('', ),
        taskSuffixId: new UntypedFormControl(''),
        employeeQualificationId: new UntypedFormControl('', ),
        specificEmployeeQualification: new UntypedFormControl('', [Validators.maxLength(255)]),
        employeeTypeId: new UntypedFormControl('', [Validators.required]),
        spareActualQuantity: new UntypedFormControl(''),
        operatingMaterialsId: new UntypedFormControl(''),
        operationalMaterialQuantity: new UntypedFormControl(''),
        toolsId: new UntypedFormControl(''),
        taskSourceId: new UntypedFormControl('', [Validators.required]),
        taskSourceStandard: new UntypedFormControl('', [Validators.maxLength(255)]),
        specificTaskReason: new UntypedFormControl('', [Validators.maxLength(255)]),
        taskStageId: new UntypedFormControl({value:'', disabled:true}),
        taskInfluenceId: new UntypedFormControl('', [Validators.required]),
        fmecaId: new UntypedFormControl('', [Validators.maxLength(1000)]),
        taskSracId: new UntypedFormControl('', [Validators.maxLength(1000)]),
        targetManualId: new UntypedFormControl({value:''}),
        supplierDocReference: new UntypedFormControl('', [Validators.maxLength(1000)]),
        differenceToSupplierDoc: new UntypedFormControl('', [Validators.maxLength(1000)]),
        taskDescription: new UntypedFormControl('', [Validators.maxLength(10000)]),
        commentsDocDepartment: new UntypedFormControl('', [Validators.maxLength(10000)]),
        commentsRamEngineer: new UntypedFormControl('', [Validators.maxLength(10000)]),
        ppTasksOfNonPpTask: new UntypedFormControl([]),
        ppTasksOfNonPpTaskOrder: new UntypedFormControl('[]'),
        ppType: new UntypedFormControl(''),
        ppTaskOfPpTask: new UntypedFormControl(''),
        relatedCmTask: new UntypedFormControl([]),
        relatedPpTask: new UntypedFormControl(''),
        taskLocationId: new UntypedFormControl(''),
        intervalvalue1: new UntypedFormControl('', [Validators.min(1), Validators.max(99999999)]),
        intervalvalue2: new UntypedFormControl('', [Validators.min(1), Validators.max(99999999)]),
        intervalunit1: new UntypedFormControl('',),
        intervalunit2: new UntypedFormControl('',),
        linkedTaskNumber: new UntypedFormControl(''),
        taskQuantity: new UntypedFormControl('',[Validators.required, Validators.max(2147483647)]),
        taskQuantityPerVehicle: new UntypedFormControl('', [Validators.required, Validators.max(2147483647)]),
        taskQuantityPerFleet: new UntypedFormControl('', [Validators.required, Validators.max(2147483647)]),
        isPerFleetQuantity: new UntypedFormControl(''),
        taskDbId: new UntypedFormControl(''),
        taskInfrastructure: new UntypedFormControl([]),
        taskTags: new UntypedFormControl([]),
        isTaskActivated: new UntypedFormControl(''),
        projectSystem: new UntypedFormControl({value:'', disabled:true}),
        bufferField01: new UntypedFormControl('', [Validators.maxLength(1000)]),
        bufferField02: new UntypedFormControl('', [Validators.maxLength(1000)]),
        bufferField03: new UntypedFormControl('', [Validators.maxLength(1000)]),
        bufferField04: new UntypedFormControl('', [Validators.maxLength(1000)]),
        bufferField05: new UntypedFormControl('', [Validators.maxLength(1000)]),
        executionTime: new UntypedFormArray([]),
        waitingTime: new UntypedFormControl(''),
      }
  );
  

  constructor(
      public bsModalRef: BsModalRef,
      private projectSystemTaskService: ProjectSystemTasksService,
      private notificationService: ToastrService,
      private taskTypeService: TaskTypeService,
      private taskSuffixService: TaskSuffixService,
      private employeeQualificationService: EmployeeQualificationService,
      private employeeTypeService: EmployeeTypeService,
      private infrastructureService: InfrastructureService,
      private overallSystemsAndTasksService:OverallSystemsAndTasksService,
      private maintenanceLocationService: MaintenanceLocationService,
      private mountingLocationService: MountingLocationService,
      private taskSourceService: TaskSourceService,
      private taskStageService: TaskStageService,
      private targetManualService: TargetManualService,
      private taskStandardService: TaskSourceStandardService,
      private profileUnitService: ProfileUnitsService,
      private taskIntervalService: TaskIntervalService,
      private projectSystemService: ProjectSystemsService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService,
      private projectService: ProjectService,
      private projectSystemSparePartsService: ProjectSystemSparePartsService,
      private operatingProfileService : OperatingProfilesService
  ) {
    super(languageService, modalService);
    // this.initiateExecutionTimeField();
  }


  ngOnInit(){
    this.projectSystemId = this.param.rowData.projectSystemId;
    this.projectNumber = this.param.rowData.projectNumber;

    this.initDataAsync();
    
  }

  logValues(){
    
    console.log(" AND CONDITIONS");
    console.log("taskTypeDataLoaded " + this.taskTypeDataLoaded );
    console.log("taskSuffixDataLoaded " + this.taskSuffixDataLoaded );
    console.log("taskStagesDataLoaded " + this.taskStagesDataLoaded );
    console.log("preProcessingTaskDataLoaded  " + this.preProcessingTaskDataLoaded  );
    console.log("preProcessingTaskDataLoaded  " + this.preProcessingTaskDataLoaded  );
    console.log("qualificationDataLoaded  " + this.qualificationDataLoaded  );
    console.log("employeeTypeDataLoaded " + this.employeeTypeDataLoaded );
    console.log("infrastructureDataLoaded " + this.infrastructureDataLoaded );
    console.log("taskInfrastructureDataLoaded " + this.taskInfrastructureDataLoaded );
    console.log("taskSourceDataLoaded " + this.taskSourceDataLoaded );
    console.log("targetManualDataLoaded " + this.targetManualDataLoaded );
    console.log("taskTagListLoaded " + this.taskTagListLoaded );
    console.log("taskIntervalsLoaded " + this.taskIntervalsLoaded );
    console.log("intervalsDataLoaded " + this.intervalsDataLoaded );
    console.log("taskMaintenanceLocationsLoaded " + this.taskMaintenanceLocationsLoaded );
    console.log("maintenanceLocationsDataLoaded " + this.maintenanceLocationsDataLoaded );
    console.log("taskElementDataLoaded " + this.taskElementDataLoaded);
    console.log(" OR CONDITIONS");
    console.log("projectSystemTaskEditForm.invalid == true " + this.projectSystemTaskEditForm.invalid );
    console.log("invalidIntervals " + this.invalidIntervals );
    console.log("showTaskActivityWarning " + this.showTaskActivityWarning );
    console.log("!validExecutionTime " + (!this.validExecutionTime) );
    console.log("(!isExecutionTimeEmpty && !isExecutionTimeSourceValid) " + (!this.isExecutionTimeEmpty)  + " & " + (!this.isExecutionTimeSourceValid));
    console.log(this.initialValues);
    console.log(this.currentFormValues());
    console.log(this.initialValues == this.currentFormValues());
  }

  doAfterInitializationTasks() {
    validateForm(this.projectSystemTaskEditForm);
    this.checkMaintenanceLevelAndLocation();
    // console.log("Finally Loaded!");
  }

  async executeAllMethods() {
    try {
      await Promise.all([
        this.getTaskSuffix(),
        this.getQualification(),
        this.getAllEmployeeType(),
        this.getAllInfrastructure(),
        this.getAllTaskSource(),
        this.getAllTaskStages(),
        this.getAllTargetManual(),
        this.getAllTaskSourceStandard(),
        this.getAllMaintenanceLocation(),
        this.getIntervalUnits(),
        this.getTaskIntervals(),
        this.loadTaskElementList(),
        this.getProjectSystemByProjectSystemid(),
        this.loadCurrencyList(),
        this.getTaskTagList(),
        this.getSystemParts(),
      ]);
      // All methods have completed successfully, now execute this.getAllTaskStages()
      await this.getIndividualTask(true);
      await this.executionTimeChanged();
      await this.getAllPrePostProcessingTasks();
      await this.doAfterInitializationTasks();
      console.log("Finally Loaded! - FinaLLY");
    } catch (error) {
      console.error("Error during execution:", error);
      // Handle any errors that occurred during the asynchronous operations
    }
  }

  initDataAsync(){
    
    this.executeAllMethods();
    
    // alert("Form Loaded");
  }


  public currentFormValues()
  {
    return JSON.stringify(this.projectSystemTaskEditForm.getRawValue());
  }

  /**
   *Validate Profile Units
   */
  validateIntervalUnits() {
    let values = this.projectSystemTaskEditForm.getRawValue();

    let inputA = values['intervalvalue1'];
    let selectA = values['intervalunit1'];
    let inputB = values['intervalvalue2'];
    let selectB = values['intervalunit2'];
    let taskNature = values['taskNature'];
    let valueUnitA = "";
    let valueUnitB = "";
    let projectProfileUnit = "";

    valueUnitA = (selectA != null && selectA.indexOf("-") > 0 ) ? selectA.substring(selectA.indexOf("-")+1) : "";
    valueUnitB = (selectB != null && selectB.indexOf("-") > 0 ) ? selectB.substring(selectB.indexOf("-")+1) : "";

    selectA = (selectA!=null && selectA.indexOf("-") > 0 ) ? selectA.substring(0, selectA.indexOf("-")) : selectA;
    selectB = (selectB!=null && selectB.indexOf("-") > 0 ) ? selectB.substring(0, selectB.indexOf("-")) : selectB;

    this.projectSystemTaskEditForm.controls['intervalvalue1'].clearValidators();
    this.projectSystemTaskEditForm.controls['intervalvalue2'].clearValidators();
    this.projectSystemTaskEditForm.controls['intervalunit1'].clearValidators();
    this.projectSystemTaskEditForm.controls['intervalunit2'].clearValidators();

    if ((taskNature == 'PM')) {
      this.invalidIntervals = (selectA != null && selectB != null && selectA != '' && selectB != '' && selectA == selectB);


      if(selectA != '' && selectA != null ){
        this.operatingProfileService.getOperatingProfileByID(selectA).subscribe(res => {
          projectProfileUnit = res["valueUnit"];


          if(valueUnitA != "" && projectProfileUnit && valueUnitA != projectProfileUnit){
            this.intervalMismatchA = true;
          }else{
            this.intervalMismatchA = false;
          }


        });
      }else{

      }

      if(selectB != '' && selectB != null){
        this.operatingProfileService.getOperatingProfileByID(selectB).subscribe(res => {
          projectProfileUnit = res["valueUnit"];

          if(valueUnitB != "" && projectProfileUnit && valueUnitB != projectProfileUnit){
            this.intervalMismatchB = true;
          }else{
            this.intervalMismatchB = false;
          }

        });
      }else{

      }


      if (inputA != null && inputA != '') {
        this.projectSystemTaskEditForm.controls['intervalunit1'].setValidators([Validators.required]);
        this.projectSystemTaskEditForm.controls['intervalvalue1'].setValidators([Validators.min(1),Validators.max(99999999)]);
      } else if (selectA != null && selectA != '') {
        this.projectSystemTaskEditForm.controls['intervalvalue1'].setValidators([Validators.required, Validators.min(1), Validators.max(99999999)]);
      }else{
        this.intervalMismatchA = false;
      }

      if (inputB != null && inputB != '') {
        this.projectSystemTaskEditForm.controls['intervalunit2'].setValidators([Validators.required]);
        this.projectSystemTaskEditForm.controls['intervalvalue2'].setValidators([Validators.min(1), Validators.max(99999999)]);
      } else if (selectB != null && selectB != '') {
        this.projectSystemTaskEditForm.controls['intervalvalue2'].setValidators([Validators.required, Validators.min(1), Validators.max(99999999)]);
      } else {
        this.intervalMismatchB = false;
        this.projectSystemTaskEditForm.controls['intervalvalue1'].setValidators([Validators.required, Validators.min(1), Validators.max(99999999)]);
        this.projectSystemTaskEditForm.controls['intervalunit1'].setValidators([Validators.required]);
      }

      if(selectA=='' || selectA==null) this.intervalMismatchA = false;
      if(selectB=='' || selectB==null) this.intervalMismatchB = false;

    } else {
      this.invalidIntervals = false;
      this.intervalMismatchA = false;
      this.intervalMismatchB = false;
    }

    this.projectSystemTaskEditForm.controls['intervalvalue1'].updateValueAndValidity();
    this.projectSystemTaskEditForm.controls['intervalvalue2'].updateValueAndValidity();
    this.projectSystemTaskEditForm.controls['intervalunit1'].updateValueAndValidity();
    this.projectSystemTaskEditForm.controls['intervalunit2'].updateValueAndValidity();
  }


  /**
   * Get Execution time feild
   */
  get executionTimeField(): FormArray {
    return this.projectSystemTaskEditForm.get('executionTime') as FormArray;
  }

  

  initiateExecutionTimeField(data){
   
    let executionTimeArray = data['executionTime'];
    
    if(executionTimeArray.length<1){

      this.executionTimeField.push(new UntypedFormBuilder().control(null));
    }
    this.processExistingExecutionTimeValues(executionTimeArray);
    this.validateExecutionTime(executionTimeArray);
   
    

  }

  /**
   * Add new Execution time Field to Form
   */
  addNewExecutionTimeField(): void {
    if (this.isTaskActivated){
      this.executionTimeField.push(new UntypedFormBuilder().control(null));
      let executionTimeArray = this.projectSystemTaskEditForm.getRawValue()['executionTime'];
      this.validateExecutionTime(executionTimeArray);
    }
    
  }

  /**
   * Remove Additional Execution time field
   * @param index 
   */
  removeExecutionTime(index: number): void {
    this.executionTimeField.removeAt(index);
    let executionTimeArray = this.projectSystemTaskEditForm.getRawValue()['executionTime'];
    this.validateExecutionTime(executionTimeArray);
  }

  /**
   * Check Execution time field is Empty or not
   * @param data 
   */
  validateExecutionTime(data){
    console.log("Checking Values of DATA");
    console.log(data);
    this.validExecutionTime=true;
    if(data == null || data == undefined || data == '') {
      //DO Nothing
      this.isExecutionTimeEmpty = true;
      this.projectSystemTaskEditForm.controls['quantityWorkers'].setValue(0);
    }
    else {
    if(data.length <= 1){
      if(data[0] == null || data[0] == ""){
        this.executionTimeError[0] = 0;
      }else{
        if(parseFloat(data[0]) >= 0.1){
          this.executionTimeError[0] = 0;
        }else{
          this.executionTimeError[0] = 1;
        }
      }
      
    }else{
      
      for (let i = 0; i < data.length; i++) {
        if((data[i]==null || data[i] == "") ){
          this.executionTimeError[i] = 2;
          this.validExecutionTime=false;
        }else{
          if(parseFloat(data[i]) >= 0.1){
            this.executionTimeError[i] = 0;
          }else{
            this.executionTimeError[i] = 1;
            this.validExecutionTime=false;
          }
          if(parseFloat(data[i]) > parseFloat(data[0])){
            this.executionTimeError[i] = 3;
            this.validExecutionTime=false;
          }
        }
      }
    }
    console.log("Checking ExecutionTIME Validation Errors");
    console.log(this.executionTimeError);
    console.log(this.validExecutionTime);
    
    if(data[0]!=null ){
      this.isExecutionTimeEmpty = false;
      this.validateExecutionTimeSource();
    }else{
      this.isExecutionTimeEmpty = true;
    }
    
  }
    
  if(data == null || data == undefined || data == '' || data.length == 0 || data[0] == null || data[0] == undefined || data[0] == '' || data[0] == '0' || data[0] == 0){
    this.projectSystemTaskEditForm.controls['quantityWorkers'].setValue(0);
  }else{
    this.projectSystemTaskEditForm.controls['quantityWorkers'].setValue(data?data.length:0);
  }
    
  }

  
  

    /**
   * Validate Execution Time field
   */
    validateExecutionTimeSource(){
      let executionTimeSource = this.projectSystemTaskEditForm.getRawValue()['executionTimeSource'];
      console.log('executionTimeSource',executionTimeSource);
      if(!this.isExecutionTimeEmpty){
        if(executionTimeSource==null || executionTimeSource==''){
          this.isExecutionTimeSourceValid = false;
        }else{
          this.isExecutionTimeSourceValid = true;
        }
  
        console.log('valid_time',this.isExecutionTimeSourceValid)
      }else{
        this.isExecutionTimeSourceValid = false;
      }
      
      
    }

  processExistingExecutionTimeValues(data){

    //TODO: Replace the comments line after modified executiontimes value from backend

    let executionTime = data

    // let executionTime = [2,3,4];


    for (let i = 0; i < executionTime.length; i++) {
      this.executionTimeField.push(new UntypedFormBuilder().control(executionTime[i]));
      let executionTimeArray = this.projectSystemTaskEditForm.getRawValue()['executionTime'];
      this.validateExecutionTime(executionTimeArray);
    }

  }

  /**
   * Validate Execution time field value when change the value
   * @param index 
   */
  updateExecutionTime(index){
    let executionTimeArray = this.projectSystemTaskEditForm.getRawValue()['executionTime'];
    this.validateExecutionTime(executionTimeArray);
  }


  changeQuantities(){
    let isPerFleetQuantity = this.projectSystemTaskEditForm.controls['isPerFleetQuantity'].value;
    if(isPerFleetQuantity){
      let taskQuantityPerFleet = this.projectSystemTaskEditForm.controls['taskQuantityPerFleet'].value;
      this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].patchValue((taskQuantityPerFleet / this.projectVehicleQuantity).toFixed(3));
      this.projectSystemTaskEditForm.controls['taskQuantity'].patchValue(taskQuantityPerFleet);

      if(this.isTaskActivated) this.projectSystemTaskEditForm.controls['taskQuantityPerFleet'].enable();

      this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].disable();
    }
    else{
      let taskQuantityPerVehicle = Math.round(this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].value);
      this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].patchValue(taskQuantityPerVehicle);
      this.projectSystemTaskEditForm.controls['taskQuantityPerFleet'].patchValue(taskQuantityPerVehicle * this.projectVehicleQuantity);
      this.projectSystemTaskEditForm.controls['taskQuantity'].patchValue(taskQuantityPerVehicle);

      if(this.isTaskActivated) this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].enable();

      this.projectSystemTaskEditForm.controls['taskQuantityPerFleet'].disable();
    }
    this.projectSystemTaskEditForm.controls['taskQuantity'].updateValueAndValidity();
    this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].updateValueAndValidity();
    this.projectSystemTaskEditForm.controls['taskQuantityPerFleet'].updateValueAndValidity();
    if(!this.isTaskActivated){
      this.projectSystemTaskEditForm.controls['taskQuantity']?.disable();
      this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle']?.disable();
      this.projectSystemTaskEditForm.controls['taskQuantityPerFleet']?.disable();
    }
  }

  /**
   *Get task infrastructures
   */
  getTaskInfrastructure() {
    this.infrastructureService.getTaskInfrastructure(this.taskDBid).subscribe(res => {
      if (res) {
        this.taskInfrastructureData = [];
        Object.entries(res).forEach(([key, value]) => {
          this.taskInfrastructureData.push(value['infrastructureId']);
        });
        this.projectSystemTaskEditForm.patchValue({
          taskInfrastructure: this.taskInfrastructureData
        });
        this.taskInfrastructureDataLoaded = true;
      }
      super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));
    });
  }

    /**
   *Get task Maintenance Location
   */
   getTaskMaintenanceLocation() {
    this.maintenanceLocationService.getTaskMaintenanceLocation(this.taskDBid).subscribe(res => {
      if (res) {
        this.maintenanceLocationArray = [];
        Object.entries(res).forEach(([key, value]) => {
          this.maintenanceLocationArray.push(value['maintenanceLocationId']);
        });
        this.projectSystemTaskEditForm.patchValue({
          tskMaintenanceLocation: this.maintenanceLocationArray
        });

        this.taskMaintenanceLocationsLoaded = true;
      }
      super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));
    });
  }



  /**
   * Get All Mounting Locations
   */
   getAllMaintenanceLocation() {
    this.mountingLocationService.getAll().subscribe(res => {
      this.maintenanceLocationData = res;
      this.maintenanceLocationsDataLoaded = true;
    });
  }




  languageChanged(){
    super.languageChanged(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));
  }

  /**
   * Get Interval Units for A
   */
  getIntervalUnits() {
    this.profileUnitService.getCombinedUnits(this.projectSystemId).subscribe(res => {
      this.finalIntervalData = [];
      Object.keys(res).forEach(item => {
        if(res[item] == null) return;
        if (res[item].taskUnitAddition != null && res[item].taskUnitAddition != '') {
          res[item].taskUnit = res[item].taskUnit + '-' + res[item].taskUnitAddition;
        }
        this.finalIntervalData.push(res[item]);
      });
      this.intervalsDataLoaded = true;
    });
  }

  /**
   * get project system by project system id
   */
  getProjectSystemByProjectSystemid() {
    this.projectSystemService.getProjectSystem(this.param.projectNumber, this.projectSystemId).subscribe(res => {
      this.isSystemActive = res['isActive']==null?0:res['isActive'];
      this.systemVariation = res['systemVariation'];
      let projectSystemCode = res['systemVariation'] + '-' + res['systemVariationNumber'];
      this.projectSystemTaskEditForm.patchValue({
        projectSystem: projectSystemCode
      });
      super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));

      this.taskElementData[0] = {
        name: this.systemVariation,
        id:'systemVariationName'
      }
    });
  }

  /**
   * Gt Task Intervals By taskDBId
   */
  getTaskIntervals() {
    this.taskIntervalService.getTaskIntervalsByTask(this.param.rowData.taskDbId).subscribe(res => {
      this.projectSystemTaskEditForm.patchValue({
        intervalvalue1: (typeof res[0] !== 'undefined') ? res[0]['intervalValue'].toString() : null,
        intervalvalue2: (typeof res[1] !== 'undefined') ? res[1]['intervalValue'].toString() : null,
        intervalunit1: (typeof res[0] !== 'undefined') ? (res[0]['intervalUnit']+ (res[0]['valueUnit']!=null?('-'+res[0]['valueUnit'] ):'')) : '',
        intervalunit2: (typeof res[1] !== 'undefined') ? (res[1]['intervalUnit']+ (res[1]['valueUnit']!=null?('-'+res[1]['valueUnit'] ):'')) : ''
      });
      super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));

      this.taskIntervalsLoaded = true;
    });
  }

  /**
   * Calculate task stage
   */
  calculateTaskStage(toInitForm=false) {
    setTimeout(() => {
      let formValue = this.projectSystemTaskEditForm.getRawValue();

      let intAunitStr =  formValue.intervalunit1 ? formValue.intervalunit1 : '';
      let intBunitStr = formValue.intervalunit2 ? formValue.intervalunit2 : '';

      let intAvalueUnitStr =  (intAunitStr!=null && intAunitStr.indexOf("-") > 0 ) ? intAunitStr.substring(intAunitStr.indexOf("-")+1) : '';
      let intBvalueUnitStr =  (intBunitStr!=null && intBunitStr.indexOf("-") > 0 ) ? intBunitStr.substring(intBunitStr.indexOf("-")+1) : '';

      intAunitStr = (intAunitStr != null && intAunitStr.indexOf("-") > 0 ) ? intAunitStr.substring(0, intAunitStr.indexOf("-")) : intAunitStr;
      intBunitStr = (intBunitStr != null && intBunitStr.indexOf("-") > 0 ) ? intBunitStr.substring(0, intBunitStr.indexOf("-")) : intBunitStr;


      let data = {
        projectNumber: this.param.projectNumber,
        projectSystemId: this.projectSystemId,
        taskNature: formValue.taskNature,
        isNonRecurring: formValue.isNonRecurring,
        intAunit: intAunitStr,
        intAvalue: formValue.intervalvalue1,
        intBunit: intBunitStr,
        intBvalue: formValue.intervalvalue2,
        intAvalueUnit : intAvalueUnitStr,
        intBvalueUnit : intBvalueUnitStr
      };
      this.taskStageService.calculcateTaskStage(data, this.param.projectNumber, this.projectSystemId).subscribe(res => {
        this.showTaskStageWarning = res['taskStage'] == 13;
        this.projectSystemTaskEditForm.patchValue({
          taskStageId: res['taskStage']
        });
        if(toInitForm) super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));
      });
      this.validateIntervalUnits();
    });
  }

  /**
   * Update ProjectSystem Task Record
   */
  updateProjectSystemTask() {
    this.btnLoading = true;
    if (this.projectSystemTaskEditForm.valid) {

      let ppTaskArray = [];
      let i = 1;
      let ppTaskList = Object();
      console.log("Printing Selected PP Tasks before Update");
      console.log(this.selectedPPTasks);
      for (let ppTask of this.selectedPPTasks) {
        ppTaskList[ppTask['taskDbId']] = i;
        i++;
        // ppTaskArray.push(ppTask['taskDbId'])
      }
      console.log("Printing PP Task List");
      console.log(ppTaskList);

      this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].patchValue(ppTaskList);

      let formValue = this.projectSystemTaskEditForm.getRawValue();
      let taskInfrastructureInput = formValue.taskInfrastructure;
      delete formValue.tskInfrastructure;
      if (taskInfrastructureInput.length > 0) {
        for (var x = 0; x < taskInfrastructureInput.length; x++) {
          this.infrastructureArray.push({infrastructureId: taskInfrastructureInput[x]});
        }
      }

      let taskMaintenanceLocationInput = formValue.tskMaintenanceLocation;
      // delete formValue.tskMaintenanceLocation;
      if (taskMaintenanceLocationInput.length > 0) {
        this.maintenanceLocationArray = [];
        for (var x = 0; x < taskMaintenanceLocationInput.length; x++) {
          this.maintenanceLocationArray.push({maintenanceLocationId: taskMaintenanceLocationInput[x]});
        }
      }

      let formData = this.projectSystemTaskEditForm.getRawValue();
      Object.entries(formData).forEach(([key, value]) => {
        if(value == null) formData[key] = '';
      });

      let taskTags = formData.taskTags;
      let taskTagsArray = [];
      for (let taskTagId of taskTags) {
        for(let taskTag of this.taskTagList){
          if(taskTag['tagId'] == taskTagId) taskTagsArray.push(taskTag);
        }
      }
      formData['taskTags'] = taskTagsArray;
      formData['isPerFleetQuantity'] = this.projectSystemTaskEditForm.controls['isPerFleetQuantity'].value ? 1 : 0;
      formData['taskElementStr'] = this.taskElementStr;
      formData['taskActivity'] = this.taskActivity;
      formData['isValidExecutionTime'] = (this.isExecutionTimeEmpty?0:(this.isValidExecutionTime?1:0));
      formData['executionTimeSource'] = (this.isExecutionTimeEmpty?'':formData['executionTimeSource']);


      if(formData['executionTime'][0]==null){

        formData['executionTime'] = [];
      }

      if(this.projectFreezeFunction == 'activated')
      {
        this.addFormValuesToFormValuesMapAfterAllArraysLoaded(formData, 'newValue');

        const initialState = {
          title: 'Edit Task Freeze Function Comments',
          formValuesMap: this.formValuesMap
        };
        let modalRef = this.modalService.show(FreezeFunctionCommentsDialogComponent, {class:'modal-xl', initialState, keyboard:false, ignoreBackdropClick: true});
        modalRef.content.event.subscribe(event => {
          if (event && event != "CANCELLED") {
            this.updateTask(event, formData);
          }else{
            this.btnLoading = false;
          }
        });
      }
      else {
        this.updateTask([], formData);
      }
    } else {
      validateForm(this.projectSystemTaskEditForm);
      this.btnLoading = false;
    }
  }

  private updateTask(event, formData){
    // formData['ppTasksOfNonPpTaskOrder'] = JSON.stringify(formData['ppTasksOfNonPpTask']);
    // formData['ppTasksOfNonPpTaskOrder'] = JSON.stringify(Object.keys(formData['ppTasksOfNonPpTask']).map(Number));
    

    let inputValue = formData['ppTasksOfNonPpTask'];
    let outputValue = Object.keys(inputValue)
    .sort((a, b) => inputValue[a] - inputValue[b])
    .map(Number);
    formData['ppTasksOfNonPpTaskOrder'] = JSON.stringify(outputValue);
    console.log("Printing PP Task Order : " + formData['ppTasksOfNonPpTaskOrder']);
    
    this.laddLoading = true;
    this.projectSystemTaskService.updateProjectSystemTasks(event, formData, this.param['projectNumber'], this.param['systemNumber'], this.taskDBid).subscribe(res => {
      if (res) {
        let intervalData = [
          {
            taskDbId: this.taskDBid,
            intervalUnit: formData['intervalunit1'],
            intervalValue: formData['intervalvalue1'],
          },
          {
            taskDbId: this.taskDBid,
            intervalUnit: formData['intervalunit2'],
            intervalValue: formData['intervalvalue2'],
          }
        ];
        this.taskIntervalService.updateBulkTaskInterval(intervalData, this.taskDBid).subscribe(res => {
          this.event.emit();
        });
        this.infrastructureService.createTaskInfrastructure(this.infrastructureArray, this.taskDBid).subscribe(res => {
          // this.notificationService.success('Project System task has been Updated', 'Success');
        });

        this.maintenanceLocationService.createTaskMaintenanceLocation(this.maintenanceLocationArray, this.taskDBid).subscribe(res => {
          // this.notificationService.success('Task Maintenance Locations were Updated', 'Success');
        });
        this.notificationService.success('Project System Task updated Successfully', 'Success');
        this.btnLoading = false;
      }
      else {
        this.notificationService.error('Project System task has not been Updated', 'Error');
        this.btnLoading = false;
      }
    }, (error)=>{
      if(error.status == 403) {
        this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
      }else{
        this.notificationService.error('Project System task has not been Updated', 'Error');
      }
      this.btnLoading = false;

    }, ()=>{
      this.bsModalRef.hide();
      this.btnLoading = false;
      this.laddLoading = false;
      this.event.emit();
    });
    
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.projectSystemTaskEditForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**
   * When change the task type will be trigger
   * @param data
   */
  changeTaskType(taskTypeId) {
    if ((taskTypeId == '05') || taskTypeId == '55') {
      let taskNameValue = this.languageService.getCurrentLanguage().id == 0 ?  'Replace' : 'Ersetzen';
      this.projectSystemTaskEditForm.controls['taskName'].disable();
      this.projectSystemTaskEditForm.patchValue({
        taskName: taskNameValue
      });
    }
    else {
      this.projectSystemTaskEditForm.controls['taskName'].enable();
      this.projectSystemTaskEditForm.patchValue({
        taskName: ''
      });
    }
  }

  /**
   * Manage  Employee Qualification with Maintainance Level
   * @param id
   */
  changeMaintainanceLevel(id, firstTime) {

    if(!firstTime){
      this.showMaintenanceLevelWarning = (id == 3);
      this.getProjectSpecificEmployeeActiveStatusAndVehicleQuantity(firstTime);
    }

    if(id==1){
      console.log('ON-TRAIN Selected');
      if(this.maintenanceLevelChangingforFirstTime == false || (!firstTime && !this.isAutoGenerated)){
        this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].patchValue([]);
      }

      this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].enable();
    }else{
      this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].patchValue([19]);
      this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].disable();
    }
    
    this.checkMaintenanceLevelAndLocation();
    this.maintenanceLevelChangingforFirstTime = false;
  }

  changeMaintainanceLocation(id, firstTime) {
    this.checkMaintenanceLevelAndLocation();
    // if(id == 19){
    //   //Workstation Selected
    //   let formValue = this.projectSystemTaskEditForm.getRawValue();
    //   if(formValue['taskMaintainanceLevel'] == 1){
    //     this.invalidMaintenanceLocation = true;
    //   }
    // }else{
    //   this.invalidMaintenanceLocation = false;
    // }
  }

  checkMaintenanceLevelAndLocation(){
    let formValue = this.projectSystemTaskEditForm.getRawValue();
    console.log(formValue['tskMaintenanceLocation']);
    console.log(formValue['maintenanceLevel']);
    var selectedMaintenanceLevel = formValue['maintenanceLevel'];
    var selectedMaintenanceLocations = formValue['tskMaintenanceLocation'];
    console.log("Selected Maintenance Level : " + selectedMaintenanceLevel);
    this.invalidMaintenanceLocation = (selectedMaintenanceLevel == 1) && (selectedMaintenanceLocations.includes(19));

    if(this.invalidMaintenanceLocation){
      console.log("Invalid Maintenance Location");
      this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].disable();
    }else{
      console.log("Maintenance Location is OK");
      if(selectedMaintenanceLevel == 1){
        this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].enable();
      }else{
        this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].disable();
      }

    }
  }


  /**
   * Get all task types by Nature
   */
  getTaskTypesByNature(natureId, toInitForm=false) {
    this.taskIsNonRecurringData = taskIsNonRecurring();
    if (natureId == 'PM') {
      this.projectSystemTaskEditForm.controls['intervalvalue1'].enable();
      this.projectSystemTaskEditForm.controls['intervalunit1'].enable();
      this.projectSystemTaskEditForm.controls['intervalvalue2'].enable();
      this.projectSystemTaskEditForm.controls['intervalunit2'].enable();
      this.projectSystemTaskEditForm.controls['relatedCmTask'].enable();
      this.projectSystemTaskEditForm.controls['isNonRecurring'].enable();
      this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].enable();
      this.taskIsNonRecurringData.splice(0,1);
      this.getRelatedTaskByNature('CM', toInitForm);
    } else if(natureId == 'CM') {
      this.projectSystemTaskEditForm.controls['intervalvalue1'].disable();
      this.projectSystemTaskEditForm.controls['intervalunit1'].disable();
      this.projectSystemTaskEditForm.controls['intervalvalue2'].disable();
      this.projectSystemTaskEditForm.controls['intervalunit2'].disable();
      this.projectSystemTaskEditForm.controls['relatedCmTask'].disable();
      this.projectSystemTaskEditForm.controls['isNonRecurring'].disable();
      this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].enable();
      this.relatedCmTasks = [];
      this.relatedCmTasksLoaded = true;
    }
    else if(natureId == 'PP') {
      this.projectSystemTaskEditForm.controls['intervalvalue1'].disable();
      this.projectSystemTaskEditForm.controls['intervalunit1'].disable();
      this.projectSystemTaskEditForm.controls['intervalvalue2'].disable();
      this.projectSystemTaskEditForm.controls['intervalunit2'].disable();
      this.projectSystemTaskEditForm.controls['isNonRecurring'].disable();
      this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].disable();

      this.relatedCmTasksLoaded = true;
    }
    this.projectSystemTaskEditForm.controls['ppType'].disable();

    if(this.projectSystemTaskEditForm.controls['taskNature'].value == 'PP' && this.projectSystemTaskEditForm.controls['ppType'].value == 'POST'){
      this.projectSystemTaskEditForm.controls['ppTaskOfPpTask'].enable();
    }
    else{
      this.projectSystemTaskEditForm.controls['ppTaskOfPpTask'].disable();
      this.projectSystemTaskEditForm.controls['ppTaskOfPpTask'].patchValue('');
    }

    this.taskTypeService.getByNature(natureId).subscribe(res => {
      this.taskTypeData = res;
      this.taskTypeDataLoaded = true;
      if(natureId == 'PP'){
        this.projectSystemTaskEditForm.controls['taskTypeId'].disable();
        this.projectSystemTaskEditForm.controls['taskTypeId'].patchValue("99");
      }
      if(toInitForm) super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));

      this.calculateTaskStage(toInitForm);
    });

    this.validateIntervalUnits();
  }

  changePpType(){
    if(this.projectSystemTaskEditForm.controls['taskNature'].value == 'PP' && this.projectSystemTaskEditForm.controls['ppType'].value == 'POST'){
      this.projectSystemTaskEditForm.controls['ppTaskOfPpTask'].enable();
    }
    else{
      this.projectSystemTaskEditForm.controls['ppTaskOfPpTask'].disable();
      this.projectSystemTaskEditForm.controls['ppTaskOfPpTask'].patchValue('');
    }
  }

  getRelatedTaskByNature(nature, toInitForm=false) {
    let projectNumber = this.param['projectNumber'];
    let projectSystemId = this.param['systemNumber'];

    this.taskTypeService.getRelatedTasksByNature(projectNumber, nature).subscribe(res => {
      if(res){
        Object.entries(res).forEach(([key, value]) => {
          let relatedCMTaskText = value['taskId'] + " "  + value['taskElementStr'] + "-" + value['taskName'];
          value['relatedCMTaskText'] = relatedCMTaskText;
        });

        this.relatedCmTasks = res;
        this.projectSystemTaskEditForm.patchValue({
          relatedCmTasks: this.selectedCmRelatedTask
        });
        if(toInitForm) super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));
      }
      this.relatedCmTasksLoaded = true;
    });
  }

  /**
   * Get all task suffix
   */
  getTaskSuffix() {
    this.taskSuffixService.getAllSuffix().subscribe(res => {
      this.taskSuffixData = res;
      this.taskSuffixDataLoaded = true;
    });
  }

  /**
   * Get all Employee qualification
   */
  getQualification() {
    this.employeeQualificationService.getAllQualification().subscribe(res => {
      this.qualificationData = res;
      this.qualificationDataLoaded = true;
    });
  }

  /**
   * Get all employee type
   */
  getAllEmployeeType() {
    this.employeeTypeService.getAllEmployeeType().subscribe(res => {
      this.employeeTypeData = res;
      this.employeeTypeDataLoaded = true;
    });
  }

  /**
   * Get All Infrastructures
   */
  getAllInfrastructure() {
    this.infrastructureService.getAllInfrastructure().subscribe(res => {
      this.infrastructureData = res;
      this.infrastructureDataLoaded = true;
    });
  }

  /**
   * Get all task source data
   */
  getAllTaskSource() {
    this.taskSourceService.getAllTaskSource().subscribe(res => {
      this.taskSourceData = res;
      this.taskSourceDataLoaded = true;
    });
  }

  /**
   * Get all task stages
   */
  getAllTaskStages() {
    this.taskStageService.getAllTaskStage().subscribe(res => {
      this.taskStagesData = res;
      this.taskStagesDataLoaded = true;
    });
  }

  /**
   * Get all target manuals
   */
  getAllTargetManual() {
    this.targetManualService.getAll().subscribe(res => {
      this.targetManualData = res;
      this.targetManualDataLoaded = true;
    });
  }

  /**
   * get All task standard data
   */
  getAllTaskSourceStandard() {
    this.taskStandardService.getAllTaskSourceStandard().subscribe(res => {
      this.taskStandardData = res;
    });
  }

  addFormValuesToFormValuesMapAfterAllArraysLoaded(task, valueType)
  {
    let dataLoaded = this.taskTypeDataLoaded && this.taskSuffixDataLoaded && this.taskStagesDataLoaded &&
        this.preProcessingTaskDataLoaded  && this.preProcessingTaskDataLoaded  && this.qualificationDataLoaded  &&
        this.employeeTypeDataLoaded && this.infrastructureDataLoaded && this.taskInfrastructureDataLoaded &&
        this.taskSourceDataLoaded && this.targetManualDataLoaded && this.taskTagListLoaded && this.relatedCmTasksLoaded &&
        this.taskIntervalsLoaded && this.intervalsDataLoaded && this.selectedPPTasksLoaded && this.taskMaintenanceLocationsLoaded &&
        this.maintenanceLocationsDataLoaded && this.taskElementDataLoaded;

    if(dataLoaded)
    {
      let attributeValues = null;

      // Task Element
      attributeValues = this.formValuesMap.get('taskElement');
      if(!attributeValues) {
        attributeValues = {text: 'Task Element'};
      }
      if(!task['taskTypeId'] || task['taskTypeId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskElementData){
          if(data['id'] == task['taskElement']) {
            attributeValues[valueType] = data['name'];
            break;
          }
        }
      }
      this.formValuesMap.set('taskElement', attributeValues);


      // Individual Task Element
      attributeValues = this.formValuesMap.get('individualTaskElement');
      if(!attributeValues) {
        attributeValues = {text: 'Individual Task Element'};
      }
      attributeValues[valueType] = task['individualTaskElement'] ? task['individualTaskElement'] : '';
      this.formValuesMap.set('individualTaskElement', attributeValues);


      // Task Nature
      attributeValues = this.formValuesMap.get('taskNature');
      if(!attributeValues) {
        attributeValues = {text: 'Task Nature'};
      }
      attributeValues[valueType] = task['taskNature'] ? task['taskNature'] : '';
      this.formValuesMap.set('taskNature', attributeValues);


      // Is Non Recurring
      attributeValues = this.formValuesMap.get('isNonRecurring');
      if(!attributeValues) {
        attributeValues = {text: 'Is Non Recurring'};
      }
      if(!task['isNonRecurring'] || task['isNonRecurring']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskIsNonRecurringData){
          if(data['id'] == task['isNonRecurring']) {
            attributeValues[valueType] = data['name'];
            break;
          }
        }
      }
      this.formValuesMap.set('isNonRecurring', attributeValues);


      // Task Type
      attributeValues = this.formValuesMap.get('taskTypeId');
      if(!attributeValues) {
        attributeValues = {text: 'Task Type'};
      }
      if(!task['taskTypeId'] || task['taskTypeId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskTypeData){
          if(data['taskTypeId'] == task['taskTypeId']) {
            attributeValues[valueType] = data['typeDescriptionDe'];
            break;
          }
        }
      }
      this.formValuesMap.set('taskTypeId', attributeValues);


      // Task Name
      attributeValues = this.formValuesMap.get('taskName');
      if(!attributeValues) {
        attributeValues = {text: 'Task Name'};
      }
      attributeValues[valueType] = task['taskName'] ? task['taskName'] : '';
      this.formValuesMap.set('taskName', attributeValues);


      // Task Suffix
      attributeValues = this.formValuesMap.get('taskSuffixId');
      if(!attributeValues) {
        attributeValues = {text: 'Task Suffix'};
      }
      if(!task['taskSuffixId'] || task['taskSuffixId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskSuffixData){
          if(data['taskSuffixId'] == task['taskSuffixId']) {
            attributeValues[valueType] = data['designationDe'];
            break;
          }
        }
      }
      this.formValuesMap.set('taskSuffixId', attributeValues);


      // Interval A Value
      attributeValues = this.formValuesMap.get('intervalvalue1');
      if(!attributeValues) {
        attributeValues = {text: 'Interval A Value'};
      }
      let intervalAValue = this.projectSystemTaskEditForm.controls['intervalvalue1'].value;
      attributeValues[valueType] = intervalAValue ? intervalAValue : '';
      this.formValuesMap.set('intervalvalue1', attributeValues);


      // Interval A Unit
      attributeValues = this.formValuesMap.get('intervalunit1');
      if(!attributeValues) {
        attributeValues = {text: 'Interval A Unit'};
      }
      let intervalAUnitId = this.projectSystemTaskEditForm.controls['intervalunit1'].value;
      if(!intervalAUnitId || intervalAUnitId==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let intervalData of this.finalIntervalData){
          if(intervalData['id'] == intervalAUnitId){
            attributeValues[valueType] = intervalData['taskUnit'];
          }
        }
      }
      this.formValuesMap.set('intervalunit1', attributeValues);


      // Interval B Value
      attributeValues = this.formValuesMap.get('intervalvalue2');
      if(!attributeValues) {
        attributeValues = {text: 'Interval B Value'};
      }
      let intervalBValue = this.projectSystemTaskEditForm.controls['intervalvalue2'].value;
      attributeValues[valueType] = intervalBValue ? intervalBValue : '';
      this.formValuesMap.set('intervalvalue2', attributeValues);


      // Interval B Unit
      attributeValues = this.formValuesMap.get('intervalunit2');
      if(!attributeValues) {
        attributeValues = {text: 'Interval B Unit'};
      }
      let intervalBUnitId = this.projectSystemTaskEditForm.controls['intervalunit2'].value;
      if(!intervalBUnitId || intervalBUnitId==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let intervalData of this.finalIntervalData){
          if(intervalData['id'] == intervalBUnitId){
            attributeValues[valueType] = intervalData['taskUnit'];
          }
        }
      }
      this.formValuesMap.set('intervalunit2', attributeValues);


      // Task Stages
      attributeValues = this.formValuesMap.get('taskStageId');
      if(!attributeValues) {
        attributeValues = {text: 'Task Stages'};
      }
      if(!task['taskStageId'] || task['taskStageId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskStagesData){
          if(data['taskStageId'] == task['taskStageId']) {
            attributeValues[valueType] = data['designation'];
            break;
          }
        }
      }
      this.formValuesMap.set('taskStageId', attributeValues);


      // Pre- / Postprocessing
      attributeValues = this.formValuesMap.get('ppTasksOfNonPpTask');
      if(!attributeValues) {
        attributeValues = {text: 'Pre- / Postprocessing'};
      }
      if(!this.selectedPPTasks || this.selectedPPTasks.length==0){
        attributeValues[valueType] = '';
      }
      else{
        let selectedPPTasksStr = "";
        for(let i=0; i<this.selectedPPTasks.length; i++){
          selectedPPTasksStr += this.selectedPPTasks[i]['ppTaskText'];
          if(i != this.selectedPPTasks.length-1) selectedPPTasksStr += '\n';
        }
        attributeValues[valueType] = selectedPPTasksStr;
      }
      this.formValuesMap.set('ppTasksOfNonPpTask', attributeValues);


      // PP Type
      attributeValues = this.formValuesMap.get('ppType');
      if(!attributeValues) {
        attributeValues = {text: 'PP Type'};
      }
      if(!task['ppType'] || task['ppType']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.ppTypeData){
          if(data['value'] == task['ppType']) {
            attributeValues[valueType] = data['text'];
            break;
          }
        }
      }
      this.formValuesMap.set('ppType', attributeValues);


      // Post Processing task reverse order from referred pre processing task
      attributeValues = this.formValuesMap.get('ppTaskOfPpTask');
      if(!attributeValues) {
        attributeValues = {text: 'Post Processing task reverse order from referred pre processing task'};
      }
      if(!task['ppTaskOfPpTask'] || task['ppTaskOfPpTask']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.preProcessingTaskData){
          if(data['taskDbId'] == task['ppTaskOfPpTask']) {
            attributeValues[valueType] = data['taskId'];
            break;
          }
        }
      }
      this.formValuesMap.set('ppTaskOfPpTask', attributeValues);


      // Maintenance Level
      attributeValues = this.formValuesMap.get('maintenanceLevel');
      if(!attributeValues) {
        attributeValues = {text: 'Maintenance Level'};
      }
      if(!task['maintenanceLevel'] || task['maintenanceLevel']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskMaintainanceLevelData){
          if(data['id'] == task['maintenanceLevel']) {
            attributeValues[valueType] = data['name'];
            break;
          }
        }
      }
      this.formValuesMap.set('maintenanceLevel', attributeValues);


      // Maintenance Location
      attributeValues = this.formValuesMap.get('taskMaintenanceLocation');
      if(!attributeValues) {
        attributeValues = {text: 'Maintenance Location'};
      }
      let taskMaintenanceLocations = this.projectSystemTaskEditForm.controls['tskMaintenanceLocation'].value;
      if(!taskMaintenanceLocations || taskMaintenanceLocations.length==0){
        attributeValues[valueType] = '';
      }
      else{
        let maintenanceLocationStr = "";
        for(let i=0; i<taskMaintenanceLocations.length; i++){
          for(let location of this.maintenanceLocationData){
            if(location['mountingLocationId'] == taskMaintenanceLocations[i]) {
              maintenanceLocationStr += location['designationDe'];
              if(i != taskMaintenanceLocations.length-1) maintenanceLocationStr += '\n';
            }
          }
        }
        attributeValues[valueType] = maintenanceLocationStr;
      }
      this.formValuesMap.set('taskMaintenanceLocation', attributeValues);


      // Execution time [min]
      // attributeValues = this.formValuesMap.get('executionTime');
      // if(!attributeValues) {
      //   attributeValues = {text: 'Execution time [min]'};
      // }
      // attributeValues[valueType] = task['executionTime'] ? task['executionTime'] : '';
      // this.formValuesMap.set('executionTime', attributeValues);

      //NOT FINISHED

      attributeValues = this.formValuesMap.get('executionTime');
      if(!attributeValues) {
        attributeValues = {text: 'Execution time [min]'};
      }
      let executionTimesString = "[";
      let executionTImesArray = task['executionTime'];
      let hasElements = false;
      executionTImesArray.forEach(element => {
        hasElements = true;
        executionTimesString += element + ',';
      });
      executionTimesString = hasElements? executionTimesString.substring(0, executionTimesString.length - 1): executionTimesString;
      executionTimesString += "]";
      attributeValues[valueType] = executionTimesString;
      this.formValuesMap.set('executionTime', attributeValues);


      // Execution time source
      attributeValues = this.formValuesMap.get('executionTimeSource');
      if(!attributeValues) {
        attributeValues = {text: 'Execution time source'};
      }
      attributeValues[valueType] = task['executionTimeSource'] ? task['executionTimeSource'] : '';
      this.formValuesMap.set('executionTimeSource', attributeValues);


      // Quantity Workers
      attributeValues = this.formValuesMap.get('quantityWorkers');
      if(!attributeValues) {
        attributeValues = {text: 'Quantity Workers'};
      }
      attributeValues[valueType] = task['quantityWorkers'] ? task['quantityWorkers'] : '';
      this.formValuesMap.set('quantityWorkers', attributeValues);


      // Description Of External Service
      attributeValues = this.formValuesMap.get('externalServiceDescription');
      if(!attributeValues) {
        attributeValues = {text: 'Description Of External Service'};
      }
      attributeValues[valueType] = task['externalServiceDescription'] ? task['externalServiceDescription'] : '';
      this.formValuesMap.set('externalServiceDescription', attributeValues);


      // External Service Cost Value
      attributeValues = this.formValuesMap.get('externalServiceCost');
      if(!attributeValues) {
        attributeValues = {text: 'External Service Cost Value'};
      }
      attributeValues[valueType] = task['externalServiceCost'] ? task['externalServiceCost'] : '';
      this.formValuesMap.set('externalServiceCost', attributeValues);


      // External Service Cost Currency
      attributeValues = this.formValuesMap.get('externalServiceCurrency');
      if(!attributeValues) {
        attributeValues = {text: 'External Service Cost Currency'};
      }
      if(!task['externalServiceCurrency'] || task['externalServiceCurrency']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.currencyList){
          if(data['code'] == task['externalServiceCurrency']) {
            attributeValues[valueType] = data['code'];
            break;
          }
        }
      }
      this.formValuesMap.set('externalServiceCurrency', attributeValues);


      // Employee Qualification
      attributeValues = this.formValuesMap.get('employeeQualificationId');
      if(!attributeValues) {
        attributeValues = {text: 'Employee Qualification'};
      }
      if(!task['employeeQualificationId'] || task['employeeQualificationId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.qualificationData){
          if(data['employeeQualificationId'] == task['employeeQualificationId']) {
            attributeValues[valueType] = data['designationDe'];
            break;
          }
        }
      }
      this.formValuesMap.set('employeeQualificationId', attributeValues);


      // Specific Employee Qualification
      attributeValues = this.formValuesMap.get('specificEmployeeQualification');
      if(!attributeValues) {
        attributeValues = {text: 'Specific Employee Qualification'};
      }
      attributeValues[valueType] = task['specificEmployeeQualification'] ? task['specificEmployeeQualification'] : '';
      this.formValuesMap.set('specificEmployeeQualification', attributeValues);


      // Employee Type
      attributeValues = this.formValuesMap.get('employeeTypeId');
      if(!attributeValues) {
        attributeValues = {text: 'Employee Type'};
      }
      if(!task['employeeTypeId'] || task['employeeTypeId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.employeeTypeData){
          if(data['employeeTypeId'] == task['employeeTypeId']) {
            attributeValues[valueType] = data['designationDe'];
            break;
          }
        }
      }
      this.formValuesMap.set('employeeTypeId', attributeValues);


      // Infrastructure
      attributeValues = this.formValuesMap.get('taskInfrastructure');
      if(!attributeValues) {
        attributeValues = {text: 'Infrastructure'};
      }
      let taskInfrastructureData = this.projectSystemTaskEditForm.controls['taskInfrastructure'].value;
      if(!taskInfrastructureData || taskInfrastructureData.length==0){
        attributeValues[valueType] = '';
      }
      else{
        let taskInfrastructureStr = "";
        for(let i=0; i<taskInfrastructureData.length; i++){
          for(let infrastructure of this.infrastructureData){
            if(infrastructure['infrastructureId'] == taskInfrastructureData[i]) {
              taskInfrastructureStr += infrastructure['designationDe'];
              if(i != taskInfrastructureData.length-1) taskInfrastructureStr += '\n';
            }
          }
        }
        attributeValues[valueType] = taskInfrastructureStr;
      }
      this.formValuesMap.set('taskInfrastructure', attributeValues);


      // Task Quantity
      attributeValues = this.formValuesMap.get('taskQuantity');
      if(!attributeValues) {
        attributeValues = {text: 'Task Quantity'};
      }
      attributeValues[valueType] = task['taskQuantity'] ? task['taskQuantity'] : '';
      this.formValuesMap.set('taskQuantity', attributeValues);


      // Is Per Fleet Quantity
      attributeValues = this.formValuesMap.get('isPerFleetQuantity');
      if(!attributeValues) {
        attributeValues = {text: 'Is Per Fleet Quantity'};
      }
      attributeValues[valueType] = task['isPerFleetQuantity'] != null && task['isPerFleetQuantity'] == 1;
      this.formValuesMap.set('isPerFleetQuantity', attributeValues);


      // Task Source
      attributeValues = this.formValuesMap.get('taskSourceId');
      if(!attributeValues) {
        attributeValues = {text: 'Task Source'};
      }
      if(!task['taskSourceId'] || task['taskSourceId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskSourceData){
          if(data['taskSourceId'] == task['taskSourceId']) {
            attributeValues[valueType] = data['designationDe'];
            break;
          }
        }
      }
      this.formValuesMap.set('taskSourceId', attributeValues);


      // Task Source Standard
      attributeValues = this.formValuesMap.get('taskSourceStandard');
      if(!attributeValues) {
        attributeValues = {text: 'Task Source Standard'};
      }
      attributeValues[valueType] = task['taskSourceStandard'] ? task['taskSourceStandard'] : '';
      this.formValuesMap.set('taskSourceStandard', attributeValues);


      // Specific Task Reason
      attributeValues = this.formValuesMap.get('specificTaskReason');
      if(!attributeValues) {
        attributeValues = {text: 'Specific Task Reason'};
      }
      attributeValues[valueType] = task['specificTaskReason'] ? task['specificTaskReason'] : '';
      this.formValuesMap.set('specificTaskReason', attributeValues);


      // Task Influence
      attributeValues = this.formValuesMap.get('taskInfluenceId');
      if(!attributeValues) {
        attributeValues = {text: 'Task Influence'};
      }
      if(!task['taskInfluenceId'] || task['taskInfluenceId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.taskInfluenceData){
          if(data['id'] == task['taskInfluenceId']) {
            attributeValues[valueType] = data['name'];
            break;
          }
        }
      }
      this.formValuesMap.set('taskInfluenceId', attributeValues);

      // FMECA ID
      attributeValues = this.formValuesMap.get('fmecaId');
      if(!attributeValues) {
        attributeValues = {text: 'FMECA ID'};
      }
      attributeValues[valueType] = task['fmecaId'] ? task['fmecaId'] : '';
      this.formValuesMap.set('fmecaId', attributeValues);


      // SRAC ID
      attributeValues = this.formValuesMap.get('taskSracId');
      if(!attributeValues) {
        attributeValues = {text: 'SRAC ID'};
      }
      attributeValues[valueType] = task['taskSracId'] ? task['taskSracId'] : '';
      this.formValuesMap.set('taskSracId', attributeValues);


      // Target Manual
      attributeValues = this.formValuesMap.get('targetManualId');
      if(!attributeValues) {
        attributeValues = {text: 'Target Manual'};
      }
      if(!task['targetManualId'] || task['targetManualId']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.targetManualData){
          if(data['targetManualId'] == task['targetManualId']) {
            attributeValues[valueType] = data['designationDe'];
            break;
          }
        }
      }
      this.formValuesMap.set('targetManualId', attributeValues);


      // Tag
      attributeValues = this.formValuesMap.get('taskTags');
      if(!attributeValues) {
        attributeValues = {text: 'Tag'};
      }
      let taskTagData = this.projectSystemTaskEditForm.controls['taskTags'].value;
      if(!taskTagData || taskTagData.length==0){
        attributeValues[valueType] = '';
      }
      else{
        let taskTagsStr = "";
        for(let i=0; i<taskTagData.length; i++){
          for(let tag of this.taskTagList){
            if(tag['tagId'] == taskTagData[i]) {
              taskTagsStr += tag['tagText'];
              if(i != taskTagData.length-1) taskTagsStr += '\n';
            }
          }
        }
        attributeValues[valueType] = taskTagsStr;
      }
      this.formValuesMap.set('taskTags', attributeValues);


      // Reference Documents
      attributeValues = this.formValuesMap.get('supplierDocReference');
      if(!attributeValues) {
        attributeValues = {text: 'Reference Documents'};
      }
      attributeValues[valueType] = task['supplierDocReference'] ? task['supplierDocReference'] : '';
      this.formValuesMap.set('supplierDocReference', attributeValues);


      // Difference To Supplier Doc
      attributeValues = this.formValuesMap.get('differenceToSupplierDoc');
      if(!attributeValues) {
        attributeValues = {text: 'Difference To Supplier Doc'};
      }
      attributeValues[valueType] = task['differenceToSupplierDoc'] ? task['differenceToSupplierDoc'] : '';
      this.formValuesMap.set('differenceToSupplierDoc', attributeValues);


      // Task Description
      attributeValues = this.formValuesMap.get('taskDescription');
      if(!attributeValues) {
        attributeValues = {text: 'Task Description'};
      }
      attributeValues[valueType] = task['taskDescription'] ? task['taskDescription'] : '';
      this.formValuesMap.set('taskDescription', attributeValues);


      // Comments Doc Department
      attributeValues = this.formValuesMap.get('commentsDocDepartment');
      if(!attributeValues) {
        attributeValues = {text: 'Comments Doc Department'};
      }
      attributeValues[valueType] = task['commentsDocDepartment'] ? task['commentsDocDepartment'] : '';
      this.formValuesMap.set('commentsDocDepartment', attributeValues);


      // Comments RAM Engineer
      attributeValues = this.formValuesMap.get('commentsRamEngineer');
      if(!attributeValues) {
        attributeValues = {text: 'Comments RAM Engineer'};
      }
      attributeValues[valueType] = task['commentsRamEngineer'] ? task['commentsRamEngineer'] : '';
      this.formValuesMap.set('commentsRamEngineer', attributeValues);


      // Related CM Task
      attributeValues = this.formValuesMap.get('relatedCmTask');
      if(!attributeValues) {
        attributeValues = {text: 'Related CM Task'};
      }
      let selectedCmRelatedTask = this.projectSystemTaskEditForm.controls['relatedCmTask'].value;
      if(!selectedCmRelatedTask || selectedCmRelatedTask.length==0){
        attributeValues[valueType] = '';
      }
      else{
        let relatedCmTasksStr = "";
        for(let i=0; i<selectedCmRelatedTask.length; i++){
          for(let task of this.relatedCmTasks){
            if(task['taskDbId'] == selectedCmRelatedTask[i]) {
              relatedCmTasksStr += task['relatedCMTaskText'];
              if(i != selectedCmRelatedTask.length-1) relatedCmTasksStr += '\n';
            }
          }
        }
        attributeValues[valueType] = relatedCmTasksStr;
      }
      this.formValuesMap.set('relatedCmTask', attributeValues);


      // Is Task Activated (Task Status)
      attributeValues = this.formValuesMap.get('isTaskActivated');
      if(!attributeValues) {
        attributeValues = {text: 'Task Status'};
      }
      if(!task['isTaskActivated'] || task['isTaskActivated']==''){
        attributeValues[valueType] = '';
      }
      else{
        for(let data of this.isTaskActivatedList){
          if(data['id'] == task['isTaskActivated']) {
            attributeValues[valueType] = data['value'];
            break;
          }
        }
      }
      this.formValuesMap.set('isTaskActivated', attributeValues);

      // Buffer Field 01
      attributeValues = this.formValuesMap.get('bufferField01');
      if(!attributeValues) {
        attributeValues = {text: 'Buffer Field 01'};
      }
      attributeValues[valueType] = task['bufferField01'] ? task['bufferField01'] : '';
      this.formValuesMap.set('bufferField01', attributeValues);

      // Buffer Field 02
      attributeValues = this.formValuesMap.get('bufferField02');
      if(!attributeValues) {
        attributeValues = {text: 'Buffer Field 02'};
      }
      attributeValues[valueType] = task['bufferField02'] ? task['bufferField02'] : '';
      this.formValuesMap.set('bufferField02', attributeValues);

      // Buffer Field 03
      attributeValues = this.formValuesMap.get('bufferField03');
      if(!attributeValues) {
        attributeValues = {text: 'Buffer Field 03'};
      }
      attributeValues[valueType] = task['bufferField03'] ? task['bufferField03'] : '';
      this.formValuesMap.set('bufferField03', attributeValues);

      // Buffer Field 04
      attributeValues = this.formValuesMap.get('bufferField04');
      if(!attributeValues) {
        attributeValues = {text: 'Buffer Field 04'};
      }
      attributeValues[valueType] = task['bufferField04'] ? task['bufferField04'] : '';
      this.formValuesMap.set('bufferField04', attributeValues);

      // Buffer Field 05
      attributeValues = this.formValuesMap.get('bufferField05');
      if(!attributeValues) {
        attributeValues = {text: 'Buffer Field 05'};
      }
      attributeValues[valueType] = task['bufferField05'] ? task['bufferField05'] : '';
      this.formValuesMap.set('bufferField05', attributeValues);

      // Waiting Time
      attributeValues = this.formValuesMap.get('waitingTime');
      if(!attributeValues) {
        attributeValues = {text: 'Waiting Time'};
      }
      attributeValues[valueType] = task['waitingTime'] ? task['waitingTime'] : '';
      this.formValuesMap.set('waitingTime', attributeValues);

    }
    else{
      setTimeout(()=>{
        this.addFormValuesToFormValuesMapAfterAllArraysLoaded(task, valueType);
      },1000)
    }
  }

  getIndividualTask(firstTime) {
    let projectNumber = this.param.projectNumber;
    let systemNumber = this.param.systemNumber;
    this.projectSystemTaskService.getIndividualProjectSystemTaskById(projectNumber, systemNumber, this.param.rowData.taskDbId).subscribe(res => {
      console.log(res);
      this.changeTaskType(res['taskTypeId']);
      
      if (res['taskNature'] != '') {
        this.selectedCmRelatedTask = res['relatedCmTask'];
        this.getTaskTypesByNature(res['taskNature'], true);
      }
      if(res['externalServiceCurrency'] == '') res['externalServiceCurrency'] = null;

      this.taskActivatedStatusID = getStatusIdfromCode(res['isTaskActivated']);
      this.initialTaskStatus = this.taskActivatedStatusID;
      this.isTaskActivated = res['isTaskActivated']=='A'?true:false;
      this.initialActivationStatus = this.taskActivatedStatusID;
      this.isAutoGenerated = res['isAutoGenerated']==1?true:false; 
      if(this.isAutoGenerated){
        this.projectSystemTaskEditForm.controls['taskTypeId'].disable();
      }else{
        this.projectSystemTaskEditForm.controls['taskTypeId'].enable();
      }

      let taskTagsArray = res['taskTags'];
      let taskTagsData = [];
      for(let taskTag of taskTagsArray){
        taskTagsData.push(taskTag['tagId']);
      }
      delete res['taskTags'];
      this.projectSystemTaskEditForm.patchValue({
        taskTags:taskTagsData
      })

      this.projectSystemTaskEditForm.patchValue(res);

      this.projectSystemTaskEditForm.controls['isTaskActivated'].patchValue(this.taskActivatedStatusID);

      if ((res['taskTypeId'] == '05') || res['taskTypeId'] == '55') {
        let taskNameValue = this.languageService.getCurrentLanguage().id == 0 ?  'Replace' : 'Ersetzen';
        this.projectSystemTaskEditForm.controls['taskName'].disable();
        this.projectSystemTaskEditForm.controls['taskName'].patchValue(taskNameValue);
      }

      this.projectSystemTaskEditForm.controls['isPerFleetQuantity'].patchValue(res['isPerFleetQuantity']==1);
      if(res['isPerFleetQuantity']==1){
        this.projectSystemTaskEditForm.controls['taskQuantityPerFleet'].patchValue(res['taskQuantity']);
      }
      else{
        this.projectSystemTaskEditForm.controls['taskQuantityPerVehicle'].patchValue(res['taskQuantity']);
      }

      this.taskManualId = res['taskId'];
      this.taskDBid = res['taskDbId'];
      this.getTaskInfrastructure();
      this.getTaskMaintenanceLocation();
      if(res['taskSourceId']) this.changeTaskSource(res['taskSourceId']);

      this.getProjectSpecificEmployeeActiveStatusAndVehicleQuantity(firstTime);
      
      this.taskElementChange(res['taskElement']);
      this.isValidExecutionTime = res["isValidExecutionTime"]?(res["isValidExecutionTime"]==1?true:false):false;
      this.isExecutionTimeEmpty = res["executionTime"]?(res["executionTime"].length > 0?false:true):true;

      if(this.isExecutionTimeEmpty){
        this.projectSystemTaskEditForm.controls['executionTimeSource'].disable();
        this.projectSystemTaskEditForm.controls['executionTimeSource'].clearValidators();
      }else{
        this.projectSystemTaskEditForm.controls['executionTimeSource'].enable();
        this.projectSystemTaskEditForm.controls['executionTimeSource'].clearValidators();
        this.projectSystemTaskEditForm.controls['executionTimeSource'].setValidators([Validators.required, Validators.maxLength(100)]);
        this.projectSystemTaskEditForm.controls['executionTimeSource'].markAsTouched();
        this.projectSystemTaskEditForm.controls['executionTimeSource'].markAsDirty();
      }

      this.addFormValuesToFormValuesMapAfterAllArraysLoaded(res, 'oldValue');

      super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));

      this.checkMaintenanceLevelAndLocation();
      this.initiateExecutionTimeField(res);
      
      this.updateStatusList(this.taskActivatedStatusID);
      validateForm(this.projectSystemTaskEditForm);
    });

    // this.checkMaintenanceLevelAndLocation();
  }

  getAllPrePostProcessingTasks(){
    this.projectSystemTaskService.getAllPrePostProcessingTasks(this.param.projectNumber, this.projectSystemId).subscribe(res => {
      if(res){
        this.preProcessingTaskData = [];
        this.prePostProcessingTaskData = [];
        let ppTaskOrderNotFound = false;
        this.selectedPPTasks = JSON.parse(this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTaskOrder'].value);
        this.selectedPPTasks = this.selectedPPTasks?this.selectedPPTasks:[];

        if(this.selectedPPTasks.length == 0) {
          ppTaskOrderNotFound = true;
          this.selectedPPTasks = this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].value;
        }

        Object.entries(res).forEach(([key, value]) => {
          let ppTaskText = value['taskId'] + " "  + value['taskElementStr'] + "-" + value['taskName'];
          value['ppTaskText'] = ppTaskText;
          if (value['taskNature'] == 'CM') value['ppType'] = 'PRE';

          if(value['ppType'] == 'PRE') this.preProcessingTaskData.push(value);
          // console.log("Selected PP Tasks : ");
          // console.log(this.selectedPPTasks);
          // console.log("DB Task ID : " + value['taskDbId']);
          let ppTaskIndex = this.selectedPPTasks?.indexOf(value['taskDbId']);
          // console.log("Index : " + ppTaskIndex);
          if(ppTaskIndex>=0) {
            this.selectedPPTasks[ppTaskIndex] = value;
            if(value['ppType'] == 'PRE') this.selectedPreTaskCount++;
            if(ppTaskOrderNotFound){
              this.selectedPPTasks.splice(ppTaskIndex, 1);
              if(value['ppType'] == 'PRE') this.selectedPPTasks.unshift(value);
              else this.selectedPPTasks.push(value);
            }
          }
          else{
            this.prePostProcessingTaskData.push(value);
          }
          this.initialSelectedPPTasks = JSON.stringify(this.selectedPPTasks);

        });
        this.selectedPPTasksLoaded = true;
        this.preProcessingTaskDataLoaded = true;

        this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].patchValue(null);
        super.saveInitialValues(JSON.stringify(this.projectSystemTaskEditForm.getRawValue()));
      }
    });
  }

  getSelectedPPTasksStr():string
  {
    return JSON.stringify(this.selectedPPTasks);
  }

  addToPPTasks(){
    let selectedTask:any = this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].value;

    for(let index = 0; index < this.prePostProcessingTaskData.length; index++){
      let task = this.prePostProcessingTaskData[index];
      if(selectedTask == task['taskDbId']) {
        if(task['ppType'] == 'PRE') {
          this.selectedPPTasks.unshift(task);
          this.selectedPreTaskCount++;
        }
        else this.selectedPPTasks.push(task);
        this.prePostProcessingTaskData.splice(index, 1);
        this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask'].patchValue(null);

        return;
      }
    }
  }

  removeFromPPTasks(task){
    let index = this.selectedPPTasks?.indexOf(task);
    if(!index || index < 0) return;

    this.prePostProcessingTaskData.push(task);
    this.selectedPPTasks.splice(index, 1);

    if(task['ppType'] == 'PRE') {
      this.selectedPreTaskCount--;
    }
  }

  movePPTaskToUp(){
    if(this.currentSelectedPPTask <=0 || this.currentSelectedPPTask >= this.selectedPPTasks.length) return;

    let temp = this.selectedPPTasks[this.currentSelectedPPTask-1];
    this.selectedPPTasks[this.currentSelectedPPTask-1] = this.selectedPPTasks[this.currentSelectedPPTask];
    this.selectedPPTasks[this.currentSelectedPPTask] = temp;
    this.currentSelectedPPTask--;
  }

  movePPTaskToDown(){
    if(this.currentSelectedPPTask <0 || this.currentSelectedPPTask >= this.selectedPPTasks.length-1) return;

    let temp = this.selectedPPTasks[this.currentSelectedPPTask+1];
    this.selectedPPTasks[this.currentSelectedPPTask+1] = this.selectedPPTasks[this.currentSelectedPPTask];
    this.selectedPPTasks[this.currentSelectedPPTask] = temp;
    this.currentSelectedPPTask++;
  }

  public taskActiveStateChanged(isTaskActivated:boolean){
    this.isTaskActivated = isTaskActivated;
    this.isThisTaskRelatedtoOtherTasks = false;

    this.taskStatusChangedFromActivetoInactive = false;
    if(this.initialActivationStatus == 1 && this.isTaskActivated == false){
      this.taskStatusChangedFromActivetoInactive = true;

      this.relatedTasksLoaded = false;
      //Get the Related Task Details.
      this.projectSystemTaskService.getRelatedTaskDetails(this.taskDBid).subscribe(res => {
        if(res){
          this.relatedAsCMTasks = res['relatedAsCMTasks'];
          this.relatedAsPPTasks = res['relatedAsPPTasks'];

          if(this.relatedAsCMTasks.length > 0 || this.relatedAsPPTasks.length > 0) this.isThisTaskRelatedtoOtherTasks = true;

        }else{
          this.relatedAsCMTasks = [];
          this.relatedAsPPTasks = [];
          // this.relatedTasksLoaded = false;
        }
      }, error => {
        this.relatedAsCMTasks = [];
        this.relatedAsPPTasks = [];
        // this.relatedTasksLoaded = false;
      }, () => {
        this.relatedTasksLoaded = true;
      });

    }

    const chk = document.getElementById('validExecutionTime') as HTMLInputElement | null;
    if(isTaskActivated){
      this.projectSystemTaskEditForm.controls['waitingTime']?.enable();
      this.projectSystemTaskEditForm.controls['taskElement']?.enable();
      if(this.projectSystemTaskEditForm.controls['taskElement']?.value == 'individual')
        this.projectSystemTaskEditForm.controls['individualTaskElement']?.enable();

      if (this.projectSystemTaskEditForm.controls['taskNature']?.value == 'PM') {
        this.projectSystemTaskEditForm.controls['intervalvalue1']?.enable();
        this.projectSystemTaskEditForm.controls['intervalunit1']?.enable();
        this.projectSystemTaskEditForm.controls['intervalvalue2']?.enable();
        this.projectSystemTaskEditForm.controls['intervalunit2']?.enable();
        this.projectSystemTaskEditForm.controls['relatedCmTask']?.enable();
        this.projectSystemTaskEditForm.controls['isNonRecurring']?.enable();
      }
      if (this.projectSystemTaskEditForm.controls['taskNature']?.value == 'PM' || this.projectSystemTaskEditForm.controls['taskNature']?.value == 'CM'){
        this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask']?.enable();
      }
      if(this.projectSystemTaskEditForm.controls['taskNature']?.value == 'PP' && this.projectSystemTaskEditForm.controls['ppType']?.value == 'POST'){
        this.projectSystemTaskEditForm.controls['ppTaskOfPpTask']?.enable();
      }
      if(this.isAutoGenerated){
        this.projectSystemTaskEditForm.controls['taskTypeId']?.disable();
      }else{
        this.projectSystemTaskEditForm.controls['taskTypeId']?.enable();
      }
      

      let taskTypeId = this.projectSystemTaskEditForm.controls['taskTypeId']?.value;
      if (taskTypeId != '05' && taskTypeId != '55') {
        this.projectSystemTaskEditForm.controls['taskName']?.enable();
      }

      this.projectSystemTaskEditForm.controls['taskSuffixId']?.enable();
      this.projectSystemTaskEditForm.controls['maintenanceLevel']?.enable();

      this.projectSystemTaskEditForm.controls['employeeQualificationId']?.disable();
      if (this.projectSystemTaskEditForm.controls['maintenanceLevel']?.value != 3 && !this.projectspecificEmployeeQualificationEnabled) {
        this.projectSystemTaskEditForm.controls['employeeQualificationId']?.enable();
      }

      this.projectSystemTaskEditForm.controls['employeeTypeId']?.enable();
      this.projectSystemTaskEditForm.controls['taskInfrastructure']?.enable();
      this.projectSystemTaskEditForm.controls['taskQuantity']?.enable();
      this.projectSystemTaskEditForm.controls['taskSourceId']?.enable();

      if (this.projectSystemTaskEditForm.controls['taskSourceId']?.value == 4) {
        this.projectSystemTaskEditForm.controls['taskSourceStandard']?.enable();
      }

      if (this.projectSystemTaskEditForm.controls['taskSourceId']?.value == 5) {
        this.projectSystemTaskEditForm.controls['specificTaskReason']?.enable();
      }

      this.projectSystemTaskEditForm.controls['executionTime']?.enable();
      this.projectSystemTaskEditForm.controls['quantityWorkers']?.enable();
      this.projectSystemTaskEditForm.controls['externalServiceDescription']?.enable();
      this.projectSystemTaskEditForm.controls['externalServiceCost']?.enable();
      this.projectSystemTaskEditForm.controls['externalServiceCurrency']?.enable();
      this.projectSystemTaskEditForm.controls['taskInfluenceId']?.enable();
      this.projectSystemTaskEditForm.controls['fmecaId']?.enable();
      this.projectSystemTaskEditForm.controls['targetManualId']?.enable();
      this.projectSystemTaskEditForm.controls['supplierDocReference']?.enable();
      this.projectSystemTaskEditForm.controls['differenceToSupplierDoc']?.enable();
      this.projectSystemTaskEditForm.controls['taskDescription']?.enable();
      this.projectSystemTaskEditForm.controls['commentsDocDepartment']?.enable();
      this.projectSystemTaskEditForm.controls['commentsRamEngineer']?.enable();

      this.projectSystemTaskEditForm.controls['taskTags']?.enable();
      this.projectSystemTaskEditForm.controls['taskSracId']?.enable();
      this.projectSystemTaskEditForm.controls['isPerFleetQuantity']?.enable();
      // this.projectSystemTaskEditForm.controls['isValidExecutionTime']?.enable();
      // document.getElementById("isValidExecutionTime").disabled = false;

      this.projectSystemTaskEditForm.controls['bufferField01']?.enable();
      this.projectSystemTaskEditForm.controls['bufferField02']?.enable();
      this.projectSystemTaskEditForm.controls['bufferField03']?.enable();
      this.projectSystemTaskEditForm.controls['bufferField04']?.enable();
      this.projectSystemTaskEditForm.controls['bufferField05']?.enable();

      if (chk != null) {
        chk.disabled = false;
      }

      if(this.projectspecificEmployeeQualificationEnabled){
        this.projectSystemTaskEditForm.controls['specificEmployeeQualification']?.enable();
      }else{
        this.projectSystemTaskEditForm.controls['specificEmployeeQualification']?.disable();
      }
      
      this.projectSystemTaskEditForm.controls['executionTimeSource']?.enable();

      //TODO : Need to check teh Task Maintenance Level
      // this.projectSystemTaskEditForm.controls['tskMaintenanceLocation']?.enable();
      this.checkMaintenanceLevelAndLocation();

    }
    else{
      this.projectSystemTaskEditForm.controls['taskElement']?.disable();
      this.projectSystemTaskEditForm.controls['individualTaskElement']?.disable();
      this.projectSystemTaskEditForm.controls['isNonRecurring']?.disable();
      this.projectSystemTaskEditForm.controls['taskTypeId']?.disable();
      this.projectSystemTaskEditForm.controls['taskName']?.disable();
      this.projectSystemTaskEditForm.controls['taskSuffixId']?.disable();
      this.projectSystemTaskEditForm.controls['intervalunit1']?.disable();
      this.projectSystemTaskEditForm.controls['intervalvalue1']?.disable();
      this.projectSystemTaskEditForm.controls['intervalunit2']?.disable();
      this.projectSystemTaskEditForm.controls['intervalvalue2']?.disable();
      this.projectSystemTaskEditForm.controls['ppTasksOfNonPpTask']?.disable();
      this.projectSystemTaskEditForm.controls['ppTaskOfPpTask']?.disable();
      this.projectSystemTaskEditForm.controls['maintenanceLevel']?.disable();
      this.projectSystemTaskEditForm.controls['executionTime']?.disable();
      this.projectSystemTaskEditForm.controls['quantityWorkers']?.disable();
      this.projectSystemTaskEditForm.controls['externalServiceDescription']?.disable();
      this.projectSystemTaskEditForm.controls['externalServiceCost']?.disable();
      this.projectSystemTaskEditForm.controls['externalServiceCurrency']?.disable();
      this.projectSystemTaskEditForm.controls['employeeQualificationId']?.disable();
      this.projectSystemTaskEditForm.controls['employeeTypeId']?.disable();
      this.projectSystemTaskEditForm.controls['taskInfrastructure']?.disable();
      this.projectSystemTaskEditForm.controls['taskQuantity']?.disable();
      this.projectSystemTaskEditForm.controls['taskSourceId']?.disable();
      this.projectSystemTaskEditForm.controls['taskSourceStandard']?.disable();
      this.projectSystemTaskEditForm.controls['specificTaskReason']?.disable();
      this.projectSystemTaskEditForm.controls['taskInfluenceId']?.disable();
      this.projectSystemTaskEditForm.controls['fmecaId']?.disable();
      this.projectSystemTaskEditForm.controls['targetManualId']?.disable();
      this.projectSystemTaskEditForm.controls['supplierDocReference']?.disable();
      this.projectSystemTaskEditForm.controls['differenceToSupplierDoc']?.disable();
      this.projectSystemTaskEditForm.controls['taskDescription']?.disable();
      this.projectSystemTaskEditForm.controls['commentsDocDepartment']?.disable();
      this.projectSystemTaskEditForm.controls['commentsRamEngineer']?.disable();
      this.projectSystemTaskEditForm.controls['relatedCmTask']?.disable();
      this.projectSystemTaskEditForm.controls['waitingTime']?.disable();


      this.projectSystemTaskEditForm.controls['taskTags']?.disable();
      this.projectSystemTaskEditForm.controls['taskSracId']?.disable();
      this.projectSystemTaskEditForm.controls['isPerFleetQuantity']?.disable();
      // this.projectSystemTaskEditForm.controls['isValidExecutionTime']?.disable();
      // document.getElementById("isValidExecutionTime").disabled = false;

      this.projectSystemTaskEditForm.controls['bufferField01']?.disable();
      this.projectSystemTaskEditForm.controls['bufferField02']?.disable();
      this.projectSystemTaskEditForm.controls['bufferField03']?.disable();
      this.projectSystemTaskEditForm.controls['bufferField04']?.disable();
      this.projectSystemTaskEditForm.controls['bufferField05']?.disable();

      if (chk != null) {
        chk.disabled = true;
      }

      this.projectSystemTaskEditForm.controls['specificEmployeeQualification']?.disable();
      this.projectSystemTaskEditForm.controls['executionTimeSource']?.disable();
      this.projectSystemTaskEditForm.controls['tskMaintenanceLocation']?.disable();


    }
    this.changeQuantities();
    this.currentTaskStatus = parseInt(this.projectSystemTaskEditForm.controls['isTaskActivated'].value);
    this.projectSystemTaskEditForm.controls['isTaskActivated'].markAsDirty();
    this.projectSystemTaskEditForm.controls['isTaskActivated'].markAsTouched();
    this.taskStatusChanged = this.currentTaskStatus != this.initialTaskStatus;
    console.log("Current Task Status : " + this.currentTaskStatus);
      console.log("Initial Task Status : " + this.initialTaskStatus);

  }

  taskElementChange(event){
    if(event == "individual") {
      this.projectSystemTaskEditForm.controls['individualTaskElement'].enable();
    }
    else {
      this.projectSystemTaskEditForm.controls['individualTaskElement'].patchValue('');
      this.projectSystemTaskEditForm.controls['individualTaskElement'].disable();
    }
    this.checkTaskElementAndActivity();
  }

  /**
   * Change Task Event
   * @param id
   */
  changeTaskSource(id) {
    if(id == 5){
      this.projectSystemTaskEditForm.controls['specificTaskReason'].enable();
    }
    else {
      this.projectSystemTaskEditForm.controls['specificTaskReason'].disable();
      this.projectSystemTaskEditForm.controls['specificTaskReason'].patchValue('');
    }

    if(id == 4){
      this.projectSystemTaskEditForm.controls['taskSourceStandard'].enable();
    }
    else {
      this.projectSystemTaskEditForm.controls['taskSourceStandard'].disable();
      this.projectSystemTaskEditForm.controls['taskSourceStandard'].patchValue('');
    }
  }

  loadTaskElementList(){
    this.taskElementData = [
      {
        name: "",
        id:'systemVariationName'
      },
      {
        name:"Individual",
        id:'individual'
      }
    ]
  }

  async getSystemParts(){
    this.projectSystemSparePartsService.getProjectSystemSpareParts(this.projectNumber, this.projectSystemId).subscribe((res)=>{
      console.log(res);
      this.taskElementDataLoaded = false;
      
      Object.entries(res).forEach(([key, value]) => {
        value['text'] = value['spareDesignation'] + " - ";
        value['text'] += value['additionalDesignation'] ? value['additionalDesignation'] + ' - ' : "";
        value['text'] += value['spareStadlerArticleId'];
        this.systemPartData.set(value['spareNumber'].toString(), value);

        let option = {
          name : value['text'],
          id : value['spareNumber'].toString()
        }
        this.taskElementData.push(option);
      });
      
    }, (error => {}),()=>{
      this.taskElementDataLoaded = true;
    });
  }

  loadCurrencyList() {
    this.currencyList = currencyList();
  }

  getProjectSpecificEmployeeActiveStatusAndVehicleQuantity(firstTime){
    this.projectspecificEmployeeQualificationEnabled = false;
    this.projectService.getProjectById(this.param.projectNumber).subscribe(res=>{
      if(res && res['specificEmployeeQualification'] && res['specificEmployeeQualification'] == 1){
        this.projectspecificEmployeeQualificationEnabled = true;
        var mntLevel = this.projectSystemTaskEditForm.value['maintenanceLevel'];
        if (mntLevel == 3) {
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].enable();
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].reset();
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].patchValue(5);

          this.projectSystemTaskEditForm.controls['taskInfrastructure'].patchValue('');
          this.projectSystemTaskEditForm.controls['taskInfrastructure'].disable();
        }else{
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].enable();
          if (!firstTime) this.projectSystemTaskEditForm.controls['employeeQualificationId'].patchValue('');
          this.projectSystemTaskEditForm.controls['taskInfrastructure'].enable();
        }

        this.projectSystemTaskEditForm.controls['employeeQualificationId'].removeValidators([Validators.required]);
        this.projectSystemTaskEditForm.controls['employeeQualificationId'].disable();
        this.projectSystemTaskEditForm.controls['specificEmployeeQualification'].enable();
        this.projectSystemTaskEditForm.controls['specificEmployeeQualification'].setValidators([Validators.required, Validators.maxLength(255)]);
        this.projectSystemTaskEditForm.controls['specificEmployeeQualification'].updateValueAndValidity();
        if(!this.isTaskActivated){
          this.projectSystemTaskEditForm.controls['specificEmployeeQualification'].disable();
          this.projectSystemTaskEditForm.controls['taskInfrastructure'].disable();
        }
      }else{
        var mntLevel = this.projectSystemTaskEditForm.value['maintenanceLevel'];
        if (mntLevel == 3) {
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].enable();
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].reset();
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].patchValue(5);
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].disable();

          this.projectSystemTaskEditForm.controls['taskInfrastructure'].patchValue('');
          this.projectSystemTaskEditForm.controls['taskInfrastructure'].disable();
        }else{
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].enable();
          if (!firstTime) this.projectSystemTaskEditForm.controls['employeeQualificationId'].patchValue('');
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].setValidators([Validators.required]);
          this.projectSystemTaskEditForm.controls['employeeQualificationId'].updateValueAndValidity();
          this.projectSystemTaskEditForm.controls['taskInfrastructure'].enable();
          if(!this.isTaskActivated){
            this.projectSystemTaskEditForm.controls['employeeQualificationId'].disable();
            this.projectSystemTaskEditForm.controls['taskInfrastructure'].disable();
          }
        }

        this.projectSystemTaskEditForm.controls['specificEmployeeQualification'].removeValidators([Validators.required, Validators.maxLength(255)]);
        this.projectSystemTaskEditForm.controls['specificEmployeeQualification'].disable();
      }

      if(this.isSystemActive==0){
        if(this.projectSystemTaskEditForm.controls['isTaskActivated'].value == 0){
          this.taskActiveStateChanged(false);
        }else{
          this.taskActiveStateChanged(true);
        }
      }else{
        this.taskActiveStateChanged(false);
      }
      

      if(res && res['vehicleQuantity']){
        this.projectVehicleQuantity = res['vehicleQuantity'];
      }
      this.changeQuantities();

    });
  }

  checkTaskElementAndActivity(){
    let task = this.projectSystemTaskEditForm.getRawValue();
    task['ppTasksOfNonPpTask'] = Array.isArray(task['ppTasksOfNonPpTask']) ? task['ppTasksOfNonPpTask'] : [];
    task['isPerFleetQuantity'] = task['isPerFleetQuantity'] ? 1 : 0;

    this.projectSystemTaskService.setTaskElementAndActivity(task).subscribe((res)=>{
      if(res && res['taskElementStr'] && res['taskActivity']){
        this.showTaskActivityWarning = (res['taskElementStr'].length + res['taskActivity'].length) > 140;
      }
    }, (error => {
      console.log(error);
    }))
  }

  callAfterLanguageChanged() {
    this.initDataAsync();
  }

  validExecutionTimeClicked(event:any){
    console.log(event.target.checked);
    if (event.target.checked) {
      this.isValidExecutionTime = true;
    }else{
      this.isValidExecutionTime = false;
    }

    this.executionTimeChanged();
  }

  getTaskTagList(){
    this.overallSystemsAndTasksService.getAllTagsByType("project_system_task").subscribe((res)=>{
      this.taskTagList = res;
      this.taskTagListLoaded = true;
    })
  }

  executionTimeChanged(){
    let m = this.projectSystemTaskEditForm.controls['executionTime'].value;
    let n = this.isValidExecutionTime;
    console.log("Is Valued Checked : " + n);
    if(m && (m.length > 0 )){
      this.isExecutionTimeEmpty = false;
      this.projectSystemTaskEditForm.controls['executionTimeSource'].enable();
      this.projectSystemTaskEditForm.controls['executionTimeSource'].clearValidators();
      if(n){
        console.log("Execution Time Source Validator Set");
        this.projectSystemTaskEditForm.controls['executionTimeSource'].setValidators([Validators.required, Validators.maxLength(100)]);
      }
      this.projectSystemTaskEditForm.controls['executionTimeSource'].markAsTouched();
      validateForm(this.projectSystemTaskEditForm);
      console.log("Form Validated");
    }else{
      // this.isValidExecutionTime = false;
      this.isExecutionTimeEmpty = true;
      this.isValidExecutionTime = false;
      this.projectSystemTaskEditForm.controls['executionTimeSource'].disable();
      this.projectSystemTaskEditForm.controls['executionTimeSource'].clearValidators();
      this.projectSystemTaskEditForm.controls['executionTimeSource'].setValue('');
      validateForm(this.projectSystemTaskEditForm);
    }

  }

  updateStatusList(currentStatus:Number){
    console.log("Current Status : " + currentStatus);
    this.isTaskActivatedList = getTaskActivatedList();
    
    if(!this.isAutoGenerated){
      this.isTaskActivatedList = this.isTaskActivatedList.filter((status) => status.id == 0 || status.id == 1);
    }else{
      if (currentStatus == 0) { //If the status is "to delete in ST4" then
        // I need to keep only the IDs 0, 1 and 4 in the list
        this.isTaskActivatedList = this.isTaskActivatedList.filter((status) => status.id == 0 || status.id == 1 || status.id == 4);
      }else if (currentStatus == 1) { //If the status is "Active" then
        // I need to keep only the IDs 0 and 1 in the list
        this.isTaskActivatedList = this.isTaskActivatedList.filter((status) => status.id == 0 || status.id == 1);
      }else if (currentStatus == 3) { //If the status is "auto generated initially inactive" then
        // I need to keep only the IDs 1 and 3 in the list
        this.isTaskActivatedList = this.isTaskActivatedList.filter((status) => status.id == 1 || status.id == 3);
      }else if (currentStatus == 4) { //If the status is "successfully deleted in ST4" then
        // I need to keep only the IDs 4 in the list
        this.isTaskActivatedList = this.isTaskActivatedList.filter((status) => status.id == 4);
      }
    }
    
    console.log(this.isTaskActivatedList);

  }

}
