import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { UserService } from 'src/app/services/user.service';
import { BreadcrumbServiceService } from '../../../services/breadcrumb-service.service';
import { BUILD_NUMBER, VERSION_NUMBER } from '../../../config';
import { VersionService } from '../../../services/version.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  buildNumber: string = BUILD_NUMBER;
  versionNumber: string = VERSION_NUMBER;
  newVersionDetected: Boolean = false;
  newVersionNumber: string = '';
  backendUiBuildNumber: number;
  loginSuccess:Boolean=true;
  currentTimestamp:number = Date.now();
  mismatchedBuildBumber:Boolean = false;
  uiVersion:string = '';
  apiVersion:string = '';
  
  loginMessage:any;
  loginForm = new UntypedFormGroup({
    username : new UntypedFormControl(''),
    password : new UntypedFormControl(''),
  })
  loading: Boolean = false;
  constructor(
      private router: Router,
      private authService : AuthServiceService,
      private userService : UserService,
      private breadcrumbService: BreadcrumbServiceService,
      private renderer: Renderer2,
      private versionService: VersionService
  ) { }

  ngOnInit(): void {

    // this.versionService.apiVersion$.subscribe(apiVersion => {
    //   const uiVersion = this.buildNumber; // Replace with your actual UI version from config.ts
    //  console.log('api_version', apiVersion);
      
    //   if (apiVersion !== uiVersion) {
    //     console.warn('Version mismatch detected. Reloading...');
    //     this.newVersionDetected = true;
    //     this.newVersionNumber = apiVersion;
    //     // const newUrl = `/?${apiVersion}`;
    //     // window.location.href = newUrl;
    //   }

    //   this.newVersionDetected = false; //For now - always false
    // });

    this.getSystemApiVersion();

  }

  getSystemApiVersion(){
    this.versionService.getApiVersion(this.currentTimestamp).subscribe(res=>{
      this.uiVersion = res['uiVersion'];
      this.apiVersion = res['apiVersion'];
      this.backendUiBuildNumber = res['uiBuildNumber'];

      if(res['uiBuildNumber']> parseInt(this.buildNumber)){
        this.mismatchedBuildBumber = true;
      }
    });
    // this.versionService.getApiVersion(this.currentTimestamp).subscribe(res=>{
      // let res = {"uiVersion":"2.0.1","uiBuildNumber":1305,"apiVersion":"2.1.0","apiBuildNumber":1330};
      
      // this.uiVersion = res['uiVersion'];
      // this.apiVersion = res['apiVersion'];
      // this.backendUiBuildNumber = res['uiBuildNumber'];

      // if(res['uiBuildNumber']> parseInt(this.buildNumber)){
      //   this.mismatchedBuildBumber = true;
      // }
    // });
  }

  userLogin(){
    this.loading = true;
    let value = this.loginForm.getRawValue();
    let userName = value['username'].toLowerCase();
    value['username'] = userName;
    let authenticate = this.authService.authenticateUser(value).subscribe(res=>{
      if(res){
        this.loading = false;
        let UserData = res;

        localStorage.setItem('user',JSON.stringify(UserData));
        this.getUserAuthrization(res['userId']);

        this.breadcrumbService.resetStack();
        this.breadcrumbService.navigate(0, "Projects", '/projects');
      }
    },error=>{
      let errStatus = error.error;
      if(error.status==404){
        this.loginSuccess = false;
        this.loginMessage = 'Invalid Username or Password';
      }else if(error.status==403){
        this.loginSuccess = false;
        this.loginMessage = 'Invalid Username or Password';
      }else if(error.status==500){
        this.loginSuccess = false;
        this.loginMessage = 'System Error';
      }else{
        this.loginSuccess = false;
        this.loginMessage = errStatus.details?errStatus.details:'An Error occurred while logging you in';
      }
      this.loading = false;
      const errorMessage = document.getElementById('loginErrorMessage');
      if (errorMessage) {
        this.renderer.addClass(errorMessage, 'shake');
        console.log("This should be Shaken");

        // After a certain duration, remove the "shake" class to stop the animation
        setTimeout(() => {
          this.renderer.removeClass(errorMessage, 'shake');
        }, 1000); // 800ms (same duration as the CSS animation)
      }

    });

    // if(atuhenticate['success']){
    //   this.router.navigate(['/projects']);
    // }else{
    //   this.router.navigate(['/']);
    // }
  }

  getUserAuthrization(userId){
    this.userService.getUserAuthbyId(userId).subscribe(res=>{
      localStorage.setItem('uAuth',JSON.stringify(res));
    });
  }

}
