import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MountingLocationService} from 'src/app/services/mounting-location.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProjectSystemsService} from 'src/app/services/project-systems.service';
import {ToastrService} from 'ngx-toastr';
import {SuppliersService} from 'src/app/services/suppliers.service';
import {StakeHolderService} from 'src/app/services/stake-holder.service';
import {StadlerArticleService} from 'src/app/services/stadler-article.service';
import {validateForm,getProjectSystemActivatedList} from 'src/app/shared/helper';
import {
  LanguageSupportComponent
} from '../../../../../shared/components/language-support/language-support-component.service';
import {LanguageServiceService} from '../../../../../services/language-service.service';
import {UserService} from 'src/app/services/user.service';
import {StandardSystemsService} from 'src/app/services/standard-systems.service';
import {ProjectSystemSparePartsService} from "src/app/services/project-system-spare-parts.service";
import {OverallSystemsAndTasksService} from "../../../../../services/overall-systems-and-tasks.service";

@Component({
  selector: 'app-edit-project-systems',
  templateUrl: './edit-project-systems.component.html',
  styleUrls: ['./edit-project-systems.component.scss']
})
export class EditProjectSystemsComponent extends LanguageSupportComponent implements OnInit {

  param: any;
  ladda: Boolean = false;
  mountingLocations: any = [];
  ramEngineerData:any=[];
  sbsCodeData:any = [];
  projectSystemEditForm: any;
  suppliers: any = [];
  laddLoading: Boolean = false;
  isprojectSystemActivatedList: any = getProjectSystemActivatedList();
  systemEngineerList: any = [];
  machanicalEngineerList: any = [];
  systemMountingLocationArray: any = [];
  electricalEngineerList: any = [];
  projectSystemMountingLocations: any = [];
  ramEngineerList: any = [];
  systemTagList: any = [];
  stadlerArticleData: any = [];
  eventEmitter:EventEmitter<string> = new EventEmitter();
  initialStadlerArticleId:any;
  isProjectSystemActivated:Number=0;
  projectFreezeFunction = "";
  stadlerArticleChangedWarning:boolean = false;

  mountingLocationsDataLoading:boolean = true;
  suppliersDataLoading:boolean = true;
  projectSystemsDataLoading:boolean = true;
  systemEngineersDataLoading:boolean = true;
  mechanicalEngineersDataLoading:boolean = true;
  electricalEngineersDataLoading:boolean = true;
  sbsCodesDataLoading:boolean = true;
  ramEngineersDataLoading:boolean = true;
  systemTagsDataLoading:boolean = true;
  stadlerArticleDataLoading:boolean = true;
  stalderArticleItemsListGenerated:boolean = false;
  stadlerArticleLoopProcessing:boolean = true;
  projectSystemFinallyLoaded:boolean = false;
  projectSystemSparepartsLoading:boolean = true;
  sparepartsLoading:boolean = true;
  childPartStadlerIdsLoading:boolean = true;
  stalderArticleItemsListLoading:boolean = true;
  ifBaseSystem:boolean = false;

  spinnerPosition:any = {'top':0, 'left':0};

  @ViewChild('outerDiv', { static: false }) outerDivElement: ElementRef;

  
  constructor(
      public bsModalRef: BsModalRef,
      private mountingLocationService: MountingLocationService,
      private projectSystemService: ProjectSystemsService,
      private projectSystemSpareService: ProjectSystemSparePartsService,
      private notificationService: ToastrService,
      private supplierService: SuppliersService,
      private stakeHolderService: StakeHolderService,
      private overallSystemsAndTasksService:OverallSystemsAndTasksService,
      private stadlerArticleService: StadlerArticleService,
      public languageService: LanguageServiceService,
      public modalService: BsModalService,
      public userService: UserService,
      private standardSystemService: StandardSystemsService
  ) {
    super(languageService, modalService);
  }

  ngOnInit(): void {
    this.initFormValues();

    

  }

  async initFormValues(){
    this.projectSystemEditForm = new UntypedFormGroup(
        {
          systemNumber: new UntypedFormControl(this.param.systemNumber),
          projectNumber: new UntypedFormControl(this.param.projectNumber),
          mountingLocationId: new UntypedFormControl([]),
          systemQuantity: new UntypedFormControl(this.param.systemQuantity, [Validators.max(999)]),
          systemDesignation: new UntypedFormControl(this.selectedLanguage == 0 ? this.param.systemDesignationEn : this.param.systemDesignationDe),
          systemEngineeringContact: new UntypedFormControl(),
          mechanicalEngineeringContact: new UntypedFormControl(),
          electricalEngineeringContact: new UntypedFormControl(),
          ramEngineeringContact: new UntypedFormControl(),
          specificDesignation: new UntypedFormControl(),
          stadlerArticleId: new UntypedFormControl(),
          sbsCode: new UntypedFormControl(),
          ramengineers: new UntypedFormControl([]),
          systemTags: new UntypedFormControl([]),
          systemSupplier: new UntypedFormControl({value: '', disabled: true}),
          systemVariation: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
          systemVariationNumber: new UntypedFormControl(),
          systemComment: new UntypedFormControl('', [Validators.maxLength(1000)]),
          isActive: new UntypedFormControl(1),
        }
    );

    
    await this.initDataAsync();    
    validateForm(this.projectSystemEditForm);

    
  }

  positionSpinner(){
    let divHeight = document.documentElement.clientHeight;
    let divWidth =document.documentElement.clientWidth;
    let divTop = 0;
    let divLeft = 0;

    this.spinnerPosition.top = divHeight/2 + divTop - 50;
    this.spinnerPosition.left = divWidth/2 + divLeft - 50;

    console.log("Spinner Position : ");
    console.log(this.spinnerPosition);
  }

  initDataAsync(){
    this.getAllMountingLocations();
    this.getAllSuppliers();
    this.getProjectSystem();
    this.getSystemEngineerList();
    this.getMachanicalEngineerList();
    this.getElectricalEngineerList();
    this.getSBSCodes();

  }

  public currentFormValues()
  {
    return JSON.stringify(this.projectSystemEditForm.getRawValue());
  }


  getSBSCodes(){
    this.sbsCodesDataLoading = true;
    this.standardSystemService.getSBSCCodesAccordingToLanguage().subscribe(res=>{
      this.sbsCodeData = res;
      
    },(error)=>{},()=>{
      this.sbsCodesDataLoading = false;
    });
  }


  /**
   * Get Stadler article id data
   */
  getStadlerArticleDataByType() {
    let projectNumber = this.param.projectNumber;
    let systemNumber = this.param.projectSystemId;
    this.stadlerArticleDataLoading = true;
    let type: number = 0; // should get Stadler Article data for Parts only
    this.stadlerArticleService.getAllByType(type).subscribe(res => {
      let childPartStadlerIds = [];
      this.projectSystemSparepartsLoading = true;
      this.projectSystemSpareService.getProjectSystemSpareParts(projectNumber, systemNumber).subscribe((parts:any[])=>{

        // Object.entries(parts).forEach(([key, value]) => {
        //   if(value['spareStadlerArticleId'] != this.initialStadlerArticleId) childPartStadlerIds.push(value['spareStadlerArticleId'])
        //   this.sparepartsLoading = true;
        // });

        this.childPartStadlerIdsLoading = true;
        Promise.all(Object.keys(parts).map(part => {
          if(part['spareStadlerArticleId'] != this.initialStadlerArticleId) childPartStadlerIds.push(part['spareStadlerArticleId'])
        })).then(() => {
            this.childPartStadlerIdsLoading = false;
        });

        this.stadlerArticleData = [];
        // this.stadlerArticleDataLoading = true;

        // Object.keys(res).forEach(item => {
        //   this.stadlerArticleLoopProcessing = true;
        //   if(childPartStadlerIds.indexOf(res[item].stadlerArticleId) < 0){
        //     res[item].designation = (this.selectedLanguage==0?res[item].designationEn:res[item].designationDe) + '-' + res[item].stadlerArticleId;
        //     this.stadlerArticleData.push(res[item]);
        //   }
        //   this.sparepartsLoading = true;
        // });

        this.stalderArticleItemsListLoading = true;
        Promise.all(Object.keys(res).map(item => {
          if(childPartStadlerIds.indexOf(res[item].stadlerArticleId) < 0){
            res[item].designation = (this.selectedLanguage==0?res[item].designationEn:res[item].designationDe) + '-' + res[item].stadlerArticleId;
            this.stadlerArticleData.push(res[item]);
          }
        })).then(() => {
          this.stalderArticleItemsListLoading = false;
        });


        // this.stadlerArticleLoopProcessing = false;
        // this.stadlerArticleDataLoading = false;
        // this.sparepartsLoading = false;
        
      }, (error) => {}, () => {
        this.projectSystemSparepartsLoading = false;
      });
    }, (error) => {}, () => {
      this.stadlerArticleDataLoading = false;
    });
  }

  /**
   * Get Stadler article data by id
   */
  getStadlerArticleById() {
    this.stadlerArticleDataLoading = true;
    let articleId = this.projectSystemEditForm.value.stadlerArticleId;
    this.stadlerArticleService.getArticleById(articleId).subscribe(res => {
      this.projectSystemEditForm.patchValue({
        specificDesignation: res['spareDesignationDe'],
        systemSupplier: res['supplierId']
      });
    }, (error) => {}, () => {
      this.stadlerArticleDataLoading = false;
    
    });

    if(this.initialStadlerArticleId && this.initialStadlerArticleId != "" && this.initialStadlerArticleId != articleId){
      this.stadlerArticleChangedWarning = true;
    }else{
      this.stadlerArticleChangedWarning = false;
    }
  }

  /**
   * Get all Suppliers
   */
  getAllSuppliers() {
    this.suppliersDataLoading = true;
    this.supplierService.getAllSuppliers().subscribe(res => {
      if(res && res['page']){
        this.suppliers = res['page']['content'];
      }
      

    }, (error) => {}, () => {
      this.suppliersDataLoading = false;
    });
  }

  /**
   * Get project system mounting location
   */
  getProjectSystemMountingLocations() {
    this.mountingLocationsDataLoading = true;
    this.projectSystemMountingLocations = [];
    this.mountingLocationService.getProjectSystemMountingLocations(this.param.projectSystemId).subscribe(res => {
      Object.entries(res).forEach(([key, value]) => {
        this.projectSystemMountingLocations.push(value['mountingLocationId']);
      });
      this.projectSystemEditForm.patchValue({
        mountingLocationId: this.projectSystemMountingLocations
      });
      super.saveInitialValues(JSON.stringify(this.projectSystemEditForm.getRawValue()));
      
    }, (error) => {}, () => {
      this.mountingLocationsDataLoading = false;
    });
  }

  /**
   * Get all Active Mounting locations (Except ID 19 DE "Werkstatt" EN "Workshop")
   */
  getAllMountingLocations() {
    this.mountingLocationsDataLoading = true;
    this.mountingLocationService.getAllActive().subscribe(res => {
      this.mountingLocations = res;
      this.getProjectSystemMountingLocations();
    }, (error) => {}, () => {
      this.mountingLocationsDataLoading = false;
    });
  }

  /**
   * Submit Project System Edit form
   */
  updateProjectSystem(event) {
    if (this.projectSystemEditForm.valid) {
      let formValue = this.projectSystemEditForm.getRawValue();

      let mountingLocationInput = formValue.mountingLocationId;
      let ramEngineers = formValue.ramengineers;
      delete formValue.tskInfrastructure;
      let ramEngineerArray = [];

      if(ramEngineers){
        if (ramEngineers.length > 0) {
          for (let ramEngId of ramEngineers) {
            for(let ramEng of this.ramEngineerList){
              if(ramEng['stakeholderId'] == ramEngId) ramEngineerArray.push(ramEng);
            }
          }
          formValue.ramengineers = ramEngineerArray;
        }else{
          formValue.ramengineers = {};
        }
      }

      let systemTags = formValue.systemTags;
      let systemTagsArray = [];
      for (let systemTagId of systemTags) {
        for(let systemTag of this.systemTagList){
          if(systemTag['tagId'] == systemTagId) systemTagsArray.push(systemTag);
        }
      }

      if(mountingLocationInput){
        if (mountingLocationInput.length > 0) {
          for (var x = 0; x < mountingLocationInput.length; x++) {
            this.systemMountingLocationArray.push(
                {
                  mountingLocationId: mountingLocationInput[x],
                  projectNumber: this.param.projectNumber,
                  systemNumber: this.param.systemNumber,
                }
            );
          }
        }
      }

      this.laddLoading = true;
      let projectNumber = this.param.projectNumber;
      let submitData = this.projectSystemEditForm.getRawValue();
      let data = {
        systemQuantity: parseInt(submitData['systemQuantity']),
        systemEngineeringContact: parseInt(submitData['systemEngineeringContact']),
        mechanicalEngineeringContact: parseInt(submitData['mechanicalEngineeringContact']),
        electricalEngineeringContact: parseInt(submitData['electricalEngineeringContact']),
        ramEngineeringContact: parseInt(submitData['ramEngineeringContact']),
        ramEngineers: ramEngineerArray,
        specificDesignation: submitData['specificDesignation'],
        systemVariation: submitData['systemVariation'],
        sbsCode: submitData['sbsCode'],
        stadlerArticleId: submitData['stadlerArticleId'],
        systemComment: submitData['systemComment'],
        systemVariationNumber: submitData['systemVariationNumber'],
        systemNumber: this.param.systemNumber,
        systemSupplier: parseInt(submitData['systemSupplier']),
        systemTags: systemTagsArray,
        isActive: submitData['isActive'],
      };
      this.projectSystemService.updateProjectSystems(event, projectNumber, this.param.projectSystemId, data).subscribe(res => {
        if (res) {
          this.mountingLocationService.createProjectSystemMountingLocations(this.systemMountingLocationArray, this.param.projectSystemId).subscribe(res => {
            this.notificationService.success('Project System has been updated', 'Success');
            this.bsModalRef.hide();
            this.eventEmitter.emit();
          });
        } else {
          this.notificationService.error('Project System has not been updated', 'Error');
        }
        
      }, (error => {
        this.notificationService.error('Project System has not been updated', 'Error');
        
      }), () => {
        this.laddLoading = false;
      });
    } else {
      validateForm(this.projectSystemEditForm);
    }
  }

  /**
   * Get Individual Project System
   */
  getProjectSystem() {
    let projectNumber = this.param.projectNumber;
    let systemNumber = this.param.projectSystemId;
    this.projectSystemsDataLoading = true;
      this.ramEngineersDataLoading = true;
    this.projectSystemFinallyLoaded = false;
    this.projectSystemService.getProjectSystem(projectNumber, systemNumber).subscribe(res => {
      if (res) {
        if (res['specificDesignation'] == null) {
          res['specificDesignation'] = this.param.systemDesignation;
        }

        //Set Base system true if the system variation number is AA00-1
        this.ifBaseSystem = res['systemVariationNumberAndCode'] == "AA00-1" ? true : false;
        res['isActive'] = (res['isActive']==null || res['isActive']=='') ? 0 : res['isActive'];

        let systemTagsArray = res['systemTags'];
        let systemTagsData = [];
        for(let systemTag of systemTagsArray){
          systemTagsData.push(systemTag['tagId']);
        }
        delete res['systemTags'];
        this.projectSystemEditForm.patchValue({
          systemTags:systemTagsData
        })

        let ramEngineerArray = res['ramEngineers'];
        let engineerData = [];
        if (ramEngineerArray?.length > 0)
        {
          for (var x = 0; x < ramEngineerArray.length; x++)
          {
            if (engineerData.indexOf(ramEngineerArray[x]['stakeholderId']) < 0)
            {
              engineerData.push(ramEngineerArray[x]['stakeholderId']);
            }
          }
          delete res['ramEngineers'];
          this.projectSystemEditForm.patchValue({
            ramengineers: engineerData
          });
        }
        else
        {
          let systemNumber = res['systemNumber'];
          this.ramEngineersDataLoading = true;
          this.standardSystemService.getSystemById(systemNumber).subscribe(res =>
          {
            if (res['ramEngineer']?.length > 0)
            {
              this.projectSystemEditForm.patchValue({
                ramengineers: res['ramEngineer']
              });
            }
          }, (error) => {}, () => {
            this.ramEngineersDataLoading = false;
          });
        }

        res['systemQuantity'] = res['systemQuantity'] != null ? res['systemQuantity'].toString() : null;

        this.initialStadlerArticleId = res['stadlerArticleId'];
        this.getRamEngineerList();
        this.getSystemTagList();

        this.systemActiveStateChanged(res['isActive']);

        
        super.saveInitialValues(JSON.stringify(this.projectSystemEditForm.getRawValue()));

        this.getStadlerArticleDataByType();
        this.projectSystemEditForm.patchValue(res);
        this.projectSystemFinallyLoaded = true;

      }
      
      
    }, (error) => {
      
    }, () => {
      this.projectSystemsDataLoading = false;
      this.ramEngineersDataLoading = false;
      this.positionSpinner();
    });
  }

  languageChanged(){
    super.languageChanged(JSON.stringify(this.projectSystemEditForm.getRawValue()));
  }

  /**
   * Get System engineer list
   */
  getSystemEngineerList() {
    this.systemEngineersDataLoading = true;
    this.stakeHolderService.getStakeHoldersByRole(7).subscribe(res => {
      this.systemEngineerList = res;
      
    }, (error) => {}, () => {
      this.systemEngineersDataLoading = false;
    });
  }

  /**
   * Get Machanical engineer list
   */
  getMachanicalEngineerList() {
    this.mechanicalEngineersDataLoading = true;
    this.stakeHolderService.getStakeHoldersByRole(5).subscribe(res => {
      this.machanicalEngineerList = res;

    }, (error) => {}, () => {
      this.mechanicalEngineersDataLoading = false;
    });
  }

  /**
   * Get Electrical engineer list
   */
  getElectricalEngineerList() {
    this.electricalEngineersDataLoading = true;
    this.stakeHolderService.getStakeHoldersByRole(6).subscribe(res => {
      this.electricalEngineerList = res;
      
    }, (error) => {}, () => {
      this.electricalEngineersDataLoading = false;
    });
  }

  /**
   * Get Ram engineer list
   */
  getRamEngineerList() {
    this.ramEngineersDataLoading = true;
    this.stakeHolderService.getRamEngineers().subscribe(res => {
      this.ramEngineerList = res;
      
    }, (error) => {}, () => {
      this.ramEngineersDataLoading = false;
    });
  }

  getSystemTagList(){
    this.systemTagsDataLoading = true;
    this.overallSystemsAndTasksService.getAllTagsByType("project_system").subscribe((res)=>{
      this.systemTagList = res;
      
    },(error)=>{},()=>{
      this.systemTagsDataLoading = false;
    });
  }

  callAfterLanguageChanged() {
    this.initFormValues();
  }

  systemActiveStateChanged(isprojectSystemActivated:Number){
    this.isProjectSystemActivated = isprojectSystemActivated;

    if(this.isProjectSystemActivated==0){
      this.projectSystemEditForm.controls['sbsCode']?.enable()
      this.projectSystemEditForm.controls['stadlerArticleId']?.enable()
      this.projectSystemEditForm.controls['systemVariation']?.enable()
      this.projectSystemEditForm.controls['mountingLocationId']?.enable()
      this.projectSystemEditForm.controls['systemQuantity']?.enable()
      this.projectSystemEditForm.controls['ramengineers']?.enable()
      this.projectSystemEditForm.controls['systemTags']?.enable()
      this.projectSystemEditForm.controls['systemComment']?.enable()
      this.projectSystemEditForm.controls['ramEngineers']?.enable()
    }else{
      this.projectSystemEditForm.controls['sbsCode']?.disable()
      this.projectSystemEditForm.controls['stadlerArticleId']?.disable()
      this.projectSystemEditForm.controls['systemVariation']?.disable()
      this.projectSystemEditForm.controls['mountingLocationId']?.disable()
      this.projectSystemEditForm.controls['systemQuantity']?.disable()
      this.projectSystemEditForm.controls['systemTags']?.disable()
      this.projectSystemEditForm.controls['systemComment']?.disable()
      this.projectSystemEditForm.controls['ramEngineers']?.disable()
    }
   
    // super.saveInitialValues(JSON.stringify(this.projectSystemEditForm.getRawValue()));
     console.log('form_data',this.projectSystemEditForm.getRawValue());
    
  }
}
