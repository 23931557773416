<div class="container-fluid">

  <div class="row">
    <div class="pageTitle col-sm-6">
      <span class="breadcrumbText" (click)="breadcrumbService.gotoPage('Projects')">Projects</span>&nbsp;
      <span class="pageSubTitle"><fa-icon [icon]="faAngleRight"></fa-icon></span>&nbsp;
      <span class="breadcrumbText" (click)="breadcrumbService.gotoPage('Systems')">Systems</span>&nbsp;
      <span class="pageSubTitle"><fa-icon [icon]="faAngleRight"></fa-icon></span>&nbsp;
      <span>Task</span>&nbsp;
      <button id="btnAddProject" [ladda]="laddaLoading" type="button" (click)="loadAddModal()" class="btn btn-primary btn-sm" appInfoPromptBox [page]="'projectSystemTask'" [component]="'grid'" [field]="'addButton'"><fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add</button>
    </div>
    <div class=" pageTitle col-sm-6 text-right">
      <button type="button" (click)="breadcrumbService.goBack()"  class="btn btn-primary btn-sm task-back-btn"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
    </div>
  </div>


  <!-- <div class="row">
    &nbsp;
  </div> -->
  <!-- <div class="row">
    <div class="searchForm col-sm-12">
      <form class="form-inline" [formGroup] = "projectSystemTaskSearchForm" (ngSubmit)="searchProjectSystemTaskData()">
        Project Number
        <div class="form-group mx-sm-3 mb-2">
          <input type="text"  class="form-control form-control-sm" formControlName="projectNumber" placeholder="Project Number">
        </div>
        System Name
        <div class="form-group mx-sm-3 mb-2">
          <input type="text"  class="form-control form-control-sm" formControlName="systemName" placeholder="System Name">
        </div>
        <button type="submit" [ladda] = "laddaSearchLoading" class="btn btn-primary btn-sm mb-2 col-sm-1"><fa-icon [icon]="faSearch"></fa-icon>&nbsp;&nbsp;Search</button>&nbsp;

      </form>
    </div>
  </div> -->

  <div *ngIf="infoStatus" class="row">
    <div class="" style="padding-left: 15px; padding-right: 15px; ">
      <table class="table table-bordered table-striped">
        <tr >
          <th style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> Project </th>
          <td style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> {{projectNumber}} {{projectDesignation}} </td>
        </tr>
      
        <tr>
          <th style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> System </th>
          <td style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> {{systemCode}} {{systemName}} <span class="{{systemStatusClass}}">({{systemStatusText}})</span></td>
        </tr>
      </table>
    </div>
  </div>


  <div class="data-table">
    <app-tree-table [settings]="settings" [data]="tableData" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>



</div> <!-- wrapper -->
