import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectSystemTasksService {

    constructor(
        private http: HttpClient
    )
    { }


    /**
     * Get All ProjectSystem Tasks
     * @param projectNUmber
     * @param SystemNumber
     */
    getAllProjectSystemTasks(projectNUmber, SystemNumber, apiType, data, params)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNUmber + "/systems/" + SystemNumber + "/tasks/" + apiType, data, {params: params});
    }

    /**
     * Update Project System Task
     * @param data
     */
    updateProjectSystemTasks(reason, data, projectNumber, systemNumber, taskDbId)
    {
        const httpOptions = {
            params: {
                reason: JSON.stringify(reason)
            }
        };
        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskDbId, data, httpOptions);
    }

    /**
     * Create New Project System Task
     * @param data
     */
    createProjectSystemTasks(reason, data, projectNumber, systemNumber)
    {
        const httpOptions = {
            params: {
                comment: reason['comment'],
                justificationCategory: reason['justificationCategory'] != null ? reason['justificationCategory'] : -1
            }
        };
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/", data, httpOptions);
    }


    setTaskElementAndActivity(task: any)
    {
        if (task['executionTime'][0]==null) task['executionTime'] = [];
        return this.http.post(environment.apiUrl + "/projects/systems/task-element-and-activity/", task);
    }

    /**
     * Get All PP Tasks
     * @param projectNUmber
     * @param SystemNumber
     */
    getAllPrePostProcessingTasks(projectNumber, SystemNumber)
    {
        // return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + SystemNumber + "/ppTasks/");
        
        //Included CM tasks also inthe list of PP Tasks  - S05-ID26
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + SystemNumber + "/ppcmTasks/");
    }

    /**
     * Get All RelatedTasks
     * @param taskDbID
     */
    getRelatedTaskDetails(taskDBId: string){
        return this.http.get(environment.apiUrl + "/projects/relatedtaskdetails/"+taskDBId);
    }

    /**
     * Get Task Manual ID
     */
    getTaskManualId(projectNumber, systemNumber, nature)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + nature + "/taskmanualid/", {responseType: 'text'})
    }

    /**
     * Update Project System Task
     * @param data
     */
    updateProjectSystemTask(data, projectNumber, systemNumber)
    {
        return this.http.put(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/", data);
    }

    exportTask(data, projectNumber)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/tasks/export/excel", data, {responseType: 'json'});
    }

    exportOverallTasks(data)
    {
        // var dataSent = JSON.stringify(data);
        return this.http.post(environment.apiUrl + "/projects/overallTasks/export/excel", data, {responseType: 'json'});
    }

    getTaskOfficialExportLogs(projectNumber, params)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/tasks/export-logs", {params: params});
    }

    exportOfficialTask(id, projectNumber)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/tasks/export/excel/" + id, null, {responseType: 'blob'});
    }

    importValueWithMarginForTaskAndParts(formData, projectNumber)
    {
        return this.http.post(environment.apiUrl + "/projects/" + projectNumber + "/tasks-and-parts/import-value-with-margin/excel/", formData);
    }

    /**
     * Get Project System Task Data
     * @param projectNumber
     * @param systemName
     */
    searchProjectSystemTaskData(projectNumber, systemName)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systemname/" + systemName + "/tasks/")
    }

    getIndividualProjectSystemTaskById(projectNumber, systemNumber, taskNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber + "/");
    }

    checkNewTaskDbIdStatus(projectNumber, projectSystemId, newTaskDBId){
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + projectSystemId + "/tasks/checkstatus/" + newTaskDBId + "/");
    }

    findAllPPTasksOfNonPPTask(projectNumber, systemNumber, taskNumber)
    {
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/ppTasks/" + taskNumber + "/");
    }


    /**
     * Delete task
     * @param taskDbId
     */
    delete(res, projectNumber, systemNumber, taskNumber)
    {
        const httpOptions = {
            params: {
                comment: res['comment'],
                justificationCategory: res['justificationCategory'] ? res['justificationCategory'] : -1
            }
        };
        return this.http.delete(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + systemNumber + "/tasks/" + taskNumber, httpOptions)
    }

    /**
     * Change Task DB IDs
     */
    changeTaskDbId(projectNumber, projectSystemId, taskDbId, newTaskDBId, comment, justificationCategory)
    {

        const data = {
            projectNumber : projectNumber,
            projectSystemId : projectSystemId,
            taskDbId : taskDbId,
            newTaskDBId: newTaskDBId
        };
        
        return this.http.get(environment.apiUrl + "/projects/" + projectNumber + "/systems/" + projectSystemId + "/tasks/change/" + taskDbId + "/" + newTaskDBId + "?comment=" + comment + "&justificationCategory=" + justificationCategory);
    }

}
