import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPlus, faSync, faSearch,faEdit,faAngleRight, faPrint, faBackward } from '@fortawesome/free-solid-svg-icons';
import { ProjectSystemTasksService } from 'src/app/services/project-system-tasks.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RouterService } from 'src/app/services/router.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectSystemTaskToolsUrlComponent } from './components/project-system-task-tools-url/project-system-task-tools-url.component';
import { environment } from 'src/environments/environment';
import { ProjectSystemTaskSparepartsUrlComponent } from '../components/project-system-task-spareparts-url/project-system-task-spareparts-url.component';
import { ProjectSystemTaskEditComponent } from '../components/project-system-task-edit/project-system-task-edit.component';
import { ProjectSystemTaskAddComponent } from '../components/project-system-task-add/project-system-task-add.component';
import { ToastrService } from 'ngx-toastr';
import {ConfirmationBoxComponent} from '../../../shared/components/confirmation-box/confirmation-box.component';
import {ProjectSystemsService} from '../../../services/project-systems.service';
import { BreadcrumbServiceService } from '../../../services/breadcrumb-service.service';
import {TaskOperatingMaterialUrlComponent} from "./components/task-operating-material-url/task-operating-material-url.component";
import {LanguageServiceService} from "../../../services/language-service.service";
import {ProjectSystemsLogUrlComponent} from "../../projects/project-systems/components/project-systems-log-url/project-systems-log-url.component";
import {ProjectSystemsTaskLogUrlComponent} from "./components/project-systems-task-log-url/project-systems-task-log-url.component";
import { CustomStatusComponent } from './components/custom-status/custom-status.component';

@Component({
  selector: 'app-project-system-task',
  templateUrl: './project-system-task.component.html',
  styleUrls: ['./project-system-task.component.scss']
})
export class ProjectSystemTaskComponent implements OnInit {

  faAngleRight = faAngleRight;
  faPlus = faPlus;
  tableData:any = [];
  infoStatus = false;
  projectNumber:any;
  projectDesignation:any;
  systemName:any;
  systemCode:String;
  exportUrl:String;
  previousUrl;
  faSearch = faSearch;
  projectData:any;
  systemData:any;
  faBackward = faBackward;
  systemNumber:any;
  laddaLoading = false;
  loading = false;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];
  projectFreezeFunction = "";
  systemStatus:Number = 0;
  systemStatusText:String = "Active";
  systemStatusClass:String = "active-system-status";

  fetchChildren = (row)=>{
    let taskDbId = row['taskDbId'];
    return this.projectSystemTaskService.findAllPPTasksOfNonPPTask(this.projectNumber,this.systemNumber, taskDbId);
  };

  settings = {
    type:'tree',
    id:'taskDbId',
    childrenFetchFunc:this.fetchChildren,
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    filterOptionFunction:(params, filterData)=>{return this.projectSystemTaskService.getAllProjectSystemTasks(this.projectNumber, this.systemNumber,"distinct-values", filterData, params)},
    enableZoom:true,
    rowClassFunction: (row) => {return (this.systemStatus==1?'inactive-task': ((row && row.isTaskActivated == 'A') ? '' : ((row && row.isTaskActivated == 'AI')|| (row && row.isTaskActivated == 'AD')) ? 'blue-task' : 'inactive-task'))},
    columns:[
      {
        title:'Task Manual ID',
        attribute:'taskId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        title:'Element',
        attribute:'taskElementStr',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:300,
      },
      {
        title:'Task Activity',
        attribute:'taskActivity',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:300,
      },
      {
        title:'Interval A',
        attribute:'intervalA',
        format:"'###",
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:125,
      },
      {
        title:'Interval B',
        attribute:'intervalB',
        format:"'###",
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:125,
      },
      {
        title:'Execution Count',
        attribute:'executionCount',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:125,
      },
      {
        title:'Status',
        attribute:'isTaskActivated',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:125,
      },
      // {
      //   title:'Status',
      //   renderComponent : CustomStatusComponent,
      //   type:'custom',
      //   onComponentInitFunction : (instance) => {
      //     instance.statusCode = "isTaskActivated";
      //   },
      //   width:125
      // },
      {
        renderComponent: ProjectSystemTaskSparepartsUrlComponent,
        type:'custom',
        hideFunction: (row)=>{return !row || !row['isParent'] || (row.isTaskActivated!='A') || row.maintenanceLevel==3 || this.systemStatus==1},
        width:30
      },
      {
        renderComponent: TaskOperatingMaterialUrlComponent,
        hideFunction: (row)=>{return !row || !row['isParent'] || (row.isTaskActivated!='A') || row.maintenanceLevel==3 || this.systemStatus==1},
        type:'custom',
        width:30
      },
      {
        renderComponent: ProjectSystemTaskToolsUrlComponent,
        hideFunction: (row)=>{return !row || !row['isParent'] || (row.isTaskActivated!='A') || row.maintenanceLevel==3 || this.systemStatus==1},
        type:'custom',
        width:30
      },
      {
        renderComponent: ProjectSystemsTaskLogUrlComponent,
        type:'custom',
        hideFunction: (row)=>{return !row['isParent']},
        width:30
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url edit-btn"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.updateData(row)},
        hideFunction: (row)=>{return !row['isParent']},
        width:30
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteTask(row)},
        hideFunction: (row)=>{return this.makeDeleteButtonHidden(row)},
        width:50
      }
    ]
  };


  constructor(
      private activatedRoute : ActivatedRoute,
      private projectSystemTaskService : ProjectSystemTasksService,
      private modalService : BsModalService,
      private routerService : RouterService,
      private projectService : ProjectService,
      private projectSystemsService : ProjectSystemsService,
      private notificationService : ToastrService,
      public breadcrumbService: BreadcrumbServiceService,
      public languageServiceService:LanguageServiceService)
  {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {

    this.filterData = [{"column":"is_task_activated","operation":"IN","values":["A","I"]}];

    this.projectNumber = this.activatedRoute.snapshot.paramMap.get("projectNumber");
    this.systemNumber = this.activatedRoute.snapshot.paramMap.get("systemNumber");
    this.exportUrl = environment.apiUrl+"/projects/"+this.projectNumber+"/tasks/export/excel";

    if(this.breadcrumbService.isStackEmpty()){
      this.breadcrumbService.pushPage(2, "Task", "projects/"+this.projectNumber+"/systems/"+this.systemNumber+"/tasks");
    }
    this.getProjectByProjectNumber();
  }

  makeDeleteButtonHidden(row){
    // console.log(row);
    if(!row || !row['isParent']) return true;
    if(row['isAutoGenerated']==1 ) {
      return true;
    }else{
      if(row['isTaskActivated']!="I"){
        return true;
      }
    }
    return false;
    
  }

  getProjectByProjectNumber() {
    this.projectService.getProjectById(this.projectNumber).subscribe(res => {

      if(res){
        this.projectDesignation = res['projectDesignation'];
        this.projectFreezeFunction = res['freezeFunction'];

        let lan  = {
          id: res['workLangId'],
          str: res['workLangId'] == 1 ? "German" : "English"
        }
        this.languageServiceService.setWorkingLanguage(lan);

        this.projectData = res;
        if(res) this.projectDesignation = res['projectDesignation'];

        this.getSystemBySystemNumber(this.projectNumber,this.systemNumber);

        this.refreshTable();
      }
      
    });
  }

  refreshTable(){
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

    // this.filterData = [{"column":"isTaskActivated","operation":"IN","values":["'A','I'"]}];

    this.projectSystemTaskService.getAllProjectSystemTasks(this.projectNumber, this.systemNumber, "filtered-data", this.filterData, params).subscribe(res => {
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.tableData = res['page']['content'];
        for(let dataObj of this.tableData){
          dataObj['isParent'] = true;
        }
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }


  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.tableData = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.refreshTable();
  }

  /**
   * Delete task
   * @param event
   */
  deleteTask(rowData) {
    const initialState = {
      title: 'Are you sure?',
      text: 'only delete if task was not send to documentation department so far OR if task in task status "to delete in ST4" was already send to documentation department',
      okBtnName: 'Yes',
      closeBtnName: 'No'
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        let taskDbId = rowData.taskDbId;
        this.projectSystemTaskService.delete(res?.result,this.projectNumber,this.systemNumber,taskDbId).subscribe(res => {}, error =>
        {
          if(error.status == 500) {
            this.notificationService.error('The Task you tried to delete is in use, hence cannot be deleted','Error');
          }else if(error.status == 403) {
            this.notificationService.error('You need to be in the Project Admins Group to perform the current Action','Error');
          }else if(error.status == 200 || error.status == 201 || error.status == 202 ) {
            this.notificationService.success('The Task has been deleted','Success');
          }else {
            this.notificationService.error('The Task has not been deleted','Error');
          }
          this.refreshTable();
        });
      }
      else ; // do nothing
    });
  }

  /**
   * Update Record
   * @param row
   */
  updateData(rowData){
    const initialState = {
      param : {
        projectNumber : this.projectNumber,
        systemNumber : this.systemNumber,
        rowData : rowData,
      },
      projectFreezeFunction : this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(ProjectSystemTaskEditComponent, {class:'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res =>
    {
      this.refreshTable();
    });
  }

  /**
   * Load Create Task Modal
   * @param row
   */
  loadAddModal(){
    const initialState = {
      param : {
        projectNumber : this.projectNumber,
        systemNumber : this.systemNumber,
      },
      projectFreezeFunction : this.projectFreezeFunction
    };
    let modalRef = this.modalService.show(ProjectSystemTaskAddComponent, {class:'modal-xl',initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res =>
    {
      this.refreshTable();
    });
  }

  getProjectDataByProjectID(projectNumber, systemNumber){

    this.projectService.getProjectById(projectNumber).subscribe(res=>{
      this.projectData = res;
      if(res) this.projectDesignation = res['projectDesignation'];
      this.getSystemBySystemNumber(projectNumber, systemNumber);
    });

  }

  /**
   *
   * @param systemNumber
   */
  getSystemBySystemNumber(projectNumber, systemNumber){
    this.projectSystemsService.getProjectSystem(projectNumber, systemNumber).subscribe(res=>{
      this.systemData = res;
      if(res) {
        let sysVariation = res['systemVariation'];
        let sysDesignation = this.languageServiceService.getCurrentLanguage().id == 0 ? res['systemDesignationEn'] : res['systemDesignationDe'];
        this.systemName = (sysVariation != null && sysVariation != sysDesignation) ? sysDesignation + " - " + sysVariation : sysDesignation ;
        this.systemCode = '' + res['systemCode'] + '-' + res['systemVariationNumber'];
        this.systemStatus = res['isActive']==null?0:res['isActive'];
        this.systemStatusText = this.systemStatus==0?'Active':'to delete in ST4';
        this.systemStatusClass = this.systemStatus==0?'active-system-status':'inactive-system-status';
      }
      this.infoStatus = true;
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    // this.breadcrumbService.replacePage("Projects", 'projects?page='+ this.currentPage +'&pageSize=' + this.pageSize);
    this.refreshTable();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.refreshTable();
  }
}





