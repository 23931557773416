import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-parent-company',
  templateUrl: './parent-company.component.html',
  styleUrls: ['./parent-company.component.scss']
})
export class ParentCompanyComponent {
initialValues: any;

systemEditForm = new UntypedFormGroup({
  systemDesignationDe : new UntypedFormControl(''),
  stadlerArticleId : new UntypedFormControl('')
});

currentFormValues() {
throw new Error('Method not implemented.');
}
  
  languages: any[]= [];
  public selectedLanguage: any = "";
  isLoading: boolean;
  systemService: any;
  notification: any;
  router: any;

  languageChanged(){}

  onSubmit() {
    this.isLoading = true;
    this.systemService.updateSystem(this.systemEditForm.getRawValue(), this.systemID).subscribe(res => {
      if (res['systemNumber']) {
        
        this.notification.success('Systems has been Updated', 'Success');
        setTimeout(() => {
          this.isLoading = false;
          this.router.navigate(['/systems']);
        }, 2000);
      } else {
        this.isLoading = false;
        this.notification.error('Systems has not been Updated', 'Error');
      }
    });
  }
  systemID(arg0: any, systemID: any) {
    throw new Error('Method not implemented.');
  }

}
